/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import MenuItem from '@material-ui/core/MenuItem';

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector';
import { noValuesConstant, allValuesConstant } from 'constans';

export default function ZonesSelector(props) {
  const {
    className, zones, zoneTypes, currentFloorId, value,
    onChange, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);

  // eslint-disable-next-line radix
  const zonesArray = Object.keys(zones).filter((zoneId) => zones[zoneId].floor === parseInt(currentFloorId));
  const zoneTypesLength = Object.keys(zoneTypes).length;

  const checkCurrentValue = () => {
    if (zonesArray.length > 0) {
      if (value) {
        return value;
      }
      return allValuesConstant;
    }
    return noValuesConstant;
  };
  const currentValue = checkCurrentValue();

  const fieldValidation = () => currentValue !== noValuesConstant;

  const createNoValuesLabel = () => {
    if (fieldValidation()) {
      return null;
    }
    if (zoneTypesLength > 0) {
      return t('noZones');
    }
    return t('noZoneTypes');
  };
  return (
    <div>
      <div style={{ background: '#f8f8f8' }}>{t('zonesSelectorLabel')}</div>
      <OutlinedSelector
        id="select-zone"
        className={className}
        novalueslabel={createNoValuesLabel()}
        allowNoValues={!fieldValidation()}
        allowAllValues={fieldValidation() && true}
        // allowSelectNoValues={fieldValidation() && true}
        value={currentValue}
        onChange={onChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {zonesArray.map((zoneId) => (
          <MenuItem key={zoneId} value={zoneId}>
            {zones[zoneId].title}
          </MenuItem>
        ))}
      </OutlinedSelector>
    </div>
  );
}
