import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
// import Drawer from '@material-ui/core/Drawer';
import useStyles from './left.menu.styles';

import MainMenu from '../menu/MainMenu';

const LeftMenuComponent = (props) => {
  const { options, openMenu, dispatch } = props;
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  return (
    <MainMenu openMenu={openMenu} dispatch={dispatch}>

      <div className={classes.leftSelector}>
        {options.map((menuElement, index) => {
          const key = index + 1;
          const iconStyle = menuElement.icon ? {
            background: `url(${menuElement.icon.url}) no-repeat`,
            width: menuElement.icon.width,
            height: menuElement.icon.height,
          } : {};
          return (
            <Link key={key} className={classes.text} to={menuElement.path}>
              <div className={classes.LeftLink}>
                {menuElement.icon && menuElement.icon.url
                  ? <div style={iconStyle} className={classes.icon} />
                  : null}
                <div className={classes.textDiv}>
                  <p className={clsx(classes.text, path.indexOf(menuElement.path) === 0 ? classes.listLinkActive : '')}>
                    {' '}
                    {menuElement.name}
                    {' '}
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </MainMenu>
  );
};

export default LeftMenuComponent;
