import Image from 'assets/images/welcomeLogo/prod/logo.svg';

import LoginIcon from 'assets/images/login/login.svg';
import PasswordIcon from 'assets/images/login/password.svg';

import CircleRegular from 'assets/fonts/font-Circe/Circe-Regular-webfont.woff';
import CircleLight from 'assets/fonts/font-Circe/Circe-Light-webfont.woff';
import CircleBold from 'assets/fonts/font-Circe/Circe-Bold-webfont.woff';

const circleRegular = {
  fontFamily: 'Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${CircleRegular}') format('woff')`,
  ].join(','),
};

const light = {
  fontFamily: 'Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${CircleLight}') format('woff')`,
  ].join(','),
};
const bold = {
  fontFamily: 'Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${CircleBold}') format('woff')`,
  ].join(','),
};

const theme = {
  typography: {
    fontFamily: 'Regular',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [circleRegular, light, bold],
      },
    },
  },
  fontRegular: 'Regular, sans-serif',
  fontBold: 'Bold, sans-serif',
  fontLight: 'Light, sans-serif',
  palette: {
    primary: {
      main: '#41afd7',
    },
    secondary: {
      main: '#266ac9',
    },
    error: {
      main: '#f44335',
    },
    warning: {
      main: '#f47e17',
    },
    info: {
      main: '#335df4',
    },
    success: {
      main: '#00f412',
    },
  },
  CustomStyles: {
    MainTopMenu: {
      background: '#ffffff',
    },
    Login: {
      formItem: {
        height: '55px',
        width: '290px',
      },
      textField: {
        width: '175px',
      },
      textFieldLogin: {
        width: '195px',
      },
      loginIconBackground: `url(${LoginIcon}) center no-repeat`,
      passwordIconBackground: `url(${PasswordIcon}) center no-repeat`,
    },
    MainBackgroundColor: '#f8f8f8',
    LogoImageBackground: `url(${Image}) center no-repeat`,
    GlobalTextColor: '#001424',
    Logo: {
      containerWidth: '200px',
      containerHeight: '50px',
      imageWidth: '200px',
      imageHeight: '50px',
    },
    LeftBarWidth: '18vw',
    MainLeftMargin: '15px',
  },
};

export default theme;
