import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import { format } from 'date-fns';
import BuildButton from 'components/reports/BuildButton.component';
import {
  fetchAlertReports,
  setCurrentObject,
  setAlertPage,
} from 'actions/reports';
import { objectsByAppFetch } from 'actions/objects';
import DataPickers from 'components/reports/datePickers/datePickers.component';

import { allValuesConstant, selectNoOneConstant } from 'constans';
import FormControl from '@material-ui/core/FormControl';
import { setSelectedGroup } from 'actions/groups';
import { setLocation } from 'actions/locations';
import { setFloor } from 'actions/floors';
import { setSelectedZone } from 'actions/zones';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import GroupSelector from '../ReportsGroupSelector';
import LocationSelector from '../ReportsLocationSelector';
import FloorSelector from '../ReportsFloorSelector';
import AutoComplete from '../ReportsAutoComplete';
import containerStyles from '../../shared/Container/styles';
import useStyles from './alertReport.component.style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const AlertReport = (props) => {
  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t } = useTranslation(['report', 'notifications', 'monitoring']);
  const {
    alertReportsArray,
    dispatch,
    currentObject, currentApp,
    objectsByAppArray,
    alertReportLimit,
    currentPage,
    locationsObject,
    currentLocation,
    isLoadingReport,
    floorsObject,
    currentFloor,
    groupsObject,
    selectedGroup,
    // zonesObject,
    openMenu,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [btnExcelState, setBtnExcelState] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchReport = async () => {
    setPage(0);
    await dispatch(fetchAlertReports());
    setBtnExcelState(true);
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const [objectsFilter, setObjectsFilter] = useState('');

  const handleChangeGroup = (event) => {
    event.preventDefault();
    const groupId = event.target.value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }

    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  };
  const filteredObjectsArray = objectsByAppArray.filter((object) => {
    const groupFilter = selectedGroup.id === allValuesConstant
        || parseInt(object.relationships['tracked-group'].data.id, 10)
          === parseInt(selectedGroup.id, 10);
    return groupFilter;
  });

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, currentPage, alertReportLimit, currentApp]);

  useEffect(() => {
    dispatch(setAlertPage(0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, alertReportLimit, currentApp]);

  const onAutocompliteInput = async (event) => {
    event.preventDefault();
    setObjectsFilter(event);
  };
  // const handleChangePage = async (event, newPage) => {
  //   dispatch(setAlertPage(newPage));
  //   await dispatch(fetchAlertReports());
  // };

  // const handleChangeRowsPerPage = async (event) => {
  //   dispatch(setAlertReportLimit(+event.target.value));
  //   dispatch(setAlertPage(0));
  //   await dispatch(fetchAlertReports());
  // };
  const notificationTypes = {
    standing: 'STANDING',
    standingOut: 'STANDING_OUT',
    exit: 'EXIT',
    entry: 'ENTRY',
    sos: 'SOS',
    fall: 'Падение',
    temperature: 'Высокая температура',
  };
  const formatCondition = (condition) => {
    let result = '';
    if (!condition) {
      return result;
    }
    switch (condition) {
      case notificationTypes.standing:
        result = t('notifications:STANDING');
        break;
      case notificationTypes.standingOut:
        result = t('notifications:STANDING_OUT');
        break;
      case notificationTypes.exit:
        result = t('notifications:EXIT_FULL');
        break;
      case notificationTypes.entry:
        result = t('notifications:ENTRY_FULL');
        break;
      case notificationTypes.sos:
        result = 'SOS';
        break;
      case notificationTypes.fall:
        result = t('notifications:FALL');
        break;
      case notificationTypes.temperature:
        result = t('heat');
        break;
      default:
        result = condition;
        break;
    }
    return result;
  };
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleDownloadReport = () => {
    const formatReportToCsv = (str) => {
      const alertDate = new Date(str.created_at);
      const objectTitle = t('objectTitle') || 'Object';
      const zoneTitle = t('zoneTitle') || 'Zone';
      const alertType = t('alertType') || 'Type';
      const percent = t('alertTime') || 'Time';
      const result = {};
      result[objectTitle] = str.object_title || `${t('monitoring:objectGroup')} ${str.group_title}` || '';
      result[zoneTitle] = str.zone_title || '';
      result[alertType] = formatCondition(str.notification_type) || 'Wront Type';
      result[percent] = format(alertDate, 'dd LLL yyyy HH:mm:ss') || 'Wrong Time';
      return result;
    };

    const csvData = alertReportsArray.map((element) => formatReportToCsv(element));

    const fileName = 'Alert report';

    exportToCSV(csvData, fileName);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl>
                <LocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FloorSelector
                floors={floorsObject}
                className={classes.selector}
                onChange={handleChangeFloor}
                value={currentFloor.id}
                currentLocationId={currentLocation.id}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className={classes.controlWrapper}>
              <GroupSelector
                onChange={handleChangeGroup}
                groups={groupsObject}
                value={selectedGroup.id}
                className={classes.selector}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          </form>
          <form className={classes.formControls} noValidate>
            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={filteredObjectsArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
              />
            </div>
            <DataPickers />
          </form>
          <BuildButton
            className={classes.btn}
            variant="outlined"
            color="primary"
            disableRipple
            onClick={fetchReport}
            disabled={isLoadingReport}
          >
            {t('buildReport')}
          </BuildButton>
        </div>
        <div
          className={classes.downloadControlContainer}
          onClick={handleDownloadReport}
          role="button"
          onKeyUp={handleDownloadReport}
          tabIndex="0"
          style={btnExcelState && alertReportsArray.length > 0 ? { display: 'inline-flex' } : { display: 'none' }}
        >
          <div className={classes.downloadIcon} />
          <div className={classes.downLoadPhraseContainer}>
            <p className={classes.downLoadPhrase}>
              {' '}
              {t('downloadXls')}
              {' '}
            </p>
          </div>
        </div>
      </div>
      {isLoadingReport
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.table}>
              <TableContainer component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">{t('objectTitle')}</TableCell>
                      <TableCell align="right">{t('zoneTitle')}</TableCell>
                      <TableCell align="right">{t('alertType')}</TableCell>
                      <TableCell align="right">{t('alertTime')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alertReportsArray
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((alertReport, index) => {
                        const key = index + 1;
                        const alertDate = alertReport.created_at
                      && new Date(alertReport.created_at);
                        return (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              {alertReport.object_title
                            || `${t('monitoring:objectGroup')} ${alertReport.group_title}` // translate it
                            || ''}
                            </TableCell>
                            <TableCell align="right">
                              {alertReport.zone_title || ''}
                            </TableCell>
                            <TableCell align="right">
                              {formatCondition(alertReport.notification_type)}
                            </TableCell>
                            <TableCell align="right">
                              {alertDate && format(alertDate, 'dd LLL yyyy HH:mm:ss')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={alertReportsArray.length}
                rowsPerPage={rowsPerPage}
                page={page}
                nextIconButtonProps={{
                  disabled: alertReportsArray.length < rowsPerPage,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${
                  count !== -1 ? count : `${t('moreThen')} ${to}`
                }`}
              />
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    alertReportsArray,
    isFetchingAlertReport: isLoadingReport,
    dateFrom,
    dateTo,
    currentObject,
    alertReportLimit,
    currentPage,
  } = state.reports;
  const { objectsByAppArray, objectsInMonitoringArray } = state.objects;
  const { currentApp, openMenu } = state.app;
  const { zonesObject } = state.zones;
  const {
    locationsObject,
    currentLocation,
    isFetching: isFetchingLocations,
  } = state.location;
  const {
    floorsObject,
    currentFloor,
    isFetching: isFetchingFloors,
  } = state.floor;
  const { groupsObject, selectedGroup } = state.groups;
  return {
    locationsObject,
    currentLocation,
    isFetchingLocations,

    floorsObject,
    currentFloor,
    isFetchingFloors,

    groupsObject,
    selectedGroup,

    objectsInMonitoringArray,
    alertReportsArray,
    isLoadingReport,
    dateFrom,
    dateTo,
    currentObject,
    objectsByAppArray,
    currentApp,
    zonesObject,
    alertReportLimit,
    currentPage,
    openMenu,
  };
}

export default connect(mapStateToProps)(AlertReport);
