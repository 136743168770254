import { formatDuration } from 'date-fns';

export const commonFormatDuration = (time, locale) => {
  if (time !== 0) {
    return formatDuration({
      days: Math.trunc(time / 86400),
      hours: Math.trunc(time / 3600) % 24,
      minutes: Math.trunc(time / 60) % 60,
      seconds: parseFloat((time % 60).toFixed(2)),
    }, {
      locale,
    });
  }
  return `< ${formatDuration({ seconds: 0.1 }, { locale })}`;
};

export const dateTableFormat = 'dd-LLL-yyyy HH:mm:ss';
export const dateExelFormat = 'dd-MM-yyyy HH:mm:ss';
