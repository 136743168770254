/* eslint-disable global-require */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  textLoading: {
    color: materialTheme.palette.primary.main,
  },
  '@global': {
    '@keyframes fadeOut': { '0%': { opacity: 1 }, '100%': { opacity: 0 } },
    '@-moz-keyframes fadeOut': { '0%': { opacity: 1 }, '100%': { opacity: 0 } },
    '@-webkit-keyframes fadeOut': {
      '0%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
    '@-o-keyframes fadeOut': { '0%': { opacity: 1 }, '100%': { opacity: 0 } },
    '@-ms-keyframes fadeOut': { '0%': { opacity: 1 }, '100%': { opacity: 0 } },
    '.fadeOut': {
      width: materialTheme.CustomStyles.Logo.imageWidth,
      height: materialTheme.CustomStyles.Logo.imageHeight,
      background: materialTheme.CustomStyles.LogoImageBackground,
      animation: 'fadeOut ease infinite 2s',
      animationDirection: 'alternate-reverse',
      WebkitAnimation: 'fadeOut infinite 2s',
      MozAnimation: 'fadeOut infinite 2s',
      OAnimation: 'fadeOut infinite 2s',
      msAnimation: 'fadeOut infinite 2s',
    },
    '.container': {
      margin: '0 auto',
      marginTop: '30vh',
      fontSize: 'xx-large',
    },
  },
}
));

const Loading = () => {
  const classes = useStyles();
  return (
    <div className="container">
      <div className={classes.textLoading}>Loading ...</div>
      <div className="fadeOut" alt="logo" />
    </div>
  );
};

export default Loading;
