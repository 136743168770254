import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const CustomButtonTitle = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    root: {
      fontFamily: fontRegular,
      margin: '0 2% 2% 0',
      color: 'white',
      fontWeight: '600',
      borderRadius: '2px',
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  };
})(Button);
export default CustomButtonTitle;
