import request, { endPointsEnum } from 'services/http';

import { noArrow, arrowDown, arrowUp } from 'constans';
import { createAlert } from './alert';

export const SET_CONTACT_REPORTS_LIST = 'SET_CONTACT_REPORTS_LIST';
export const SET_CONTACT_ZONE_REPORTS_LIST = 'SET_CONTACT_ZONE_REPORTS_LIST';
export const SET_DATE_FROM = 'SET_DATE_FROM';
export const SET_DATE_TO_NO_ZONES = 'SET_DATE_TO';
export const SET_DATE_FROM_NO_ZONES = 'SET_DATE_FROM';
export const SET_DATE_TO = 'SET_DATE_TO';
export const SET_CURRENT_OBJECT = 'SET_CURRENT_OBJECT';
export const SET_CURRENT_DURATION = 'SET_CURRENT_DURATION';
export const FETCH_CONTACT_REPORTS_IN_PROGRESS = 'FETCH_CONTACT_REPORTS_IN_PROGRESS';
export const FETCHED_CONTACT_REPORTS = 'FETCHED_CONTACT_REPORTS';
export const SET_OBJECT_REPORT_LIST = 'SET_OBJECT_REPORT_LIST';
export const SET_FINAL_OBJECT_REPORT_LIST = 'SET_FINAL_OBJECT_REPORT_LIST';
export const SET_TIME_OUT_OF_ZONES = 'SET_TIME_OUT_OF_ZONES';
export const EXPORT_TO_EXEL = 'EXPORT_TO_EXEL';
export const SET_ALERT_REPORT_LIST = 'SET_ALERT_REPORT_LIST';

export const RESET_REPORTS = 'RESET_REPORTS';
export const FETCH_ALERT_REPORT_END = 'FETCH_ALERT_REPORT_END';
export const FETCH_ALERT_REPORT_IN_PROGRESS = 'FETCH_ALERT_REPORT_IN_PROGRESS';
export const SET_DEVICE_MONITORING_LIST = 'SET_DEVICE_MONITORING_LIST';

export const resetReports = () => ({
  type: RESET_REPORTS,
});

export const setDateFrom = (date) => ({
  type: SET_DATE_FROM,
  date,
});
export const setDateTo = (date) => ({
  type: SET_DATE_TO,
  date,
});
export const setDateFromNoZones = (date) => ({
  type: SET_DATE_FROM_NO_ZONES,
  date,
});
export const setDateToNoZones = (date) => ({
  type: SET_DATE_TO_NO_ZONES,
  date,
});

export const setDuration = (duration) => ({
  type: SET_CURRENT_DURATION,
  duration,
});

export const setCurrentObject = (object) => ({
  type: SET_CURRENT_OBJECT,
  object,
});

export const setContactReports = (list) => ({
  type: SET_CONTACT_REPORTS_LIST,
  contactReportsArray: list,
});
export const setContactZoneReports = (list) => ({
  type: SET_CONTACT_ZONE_REPORTS_LIST,
  contactZoneReportsArray: list,
});

export const setDeviceMonitoring = (list) => ({
  type: SET_DEVICE_MONITORING_LIST,
  list,
});

export const fetchContactReportsInProgress = () => ({
  type: FETCH_CONTACT_REPORTS_IN_PROGRESS,
});

export const fetchedContactReports = () => ({
  type: FETCHED_CONTACT_REPORTS,
});

export const setObjectReport = (list, dateFrom, dateTo) => ({
  type: SET_OBJECT_REPORT_LIST,
  list,
  dateFrom,
  dateTo,
});
export const setFinalObjectReport = (list, dateFrom, dateTo) => ({
  type: SET_FINAL_OBJECT_REPORT_LIST,
  list,
  dateFrom,
  dateTo,
});
export const setTimeOutOfZones = (list) => ({
  type: SET_TIME_OUT_OF_ZONES,
  list,
});
export const setExportReportObjectsToXls = (list) => ({
  type: EXPORT_TO_EXEL,
  list,
});

export const FETCH_INVENTORY_REPORT_END = 'FETCH_INVENTORY_REPORT_END';
export const FETCH_INVENTORY_REPORT_IN_PROGRESS = 'FETCH_INVENTORY_REPORT_IN_PROGRESS';
export const SET_INVENTORY_REPORT = 'SET_INVENTORY_REPORT';
export const SET_DISTANCE_REPORT = 'SET_DISTANCE_REPORT';
export const FETCH_DISTANCE_REPORT_IN_PROGRESS = 'FETCH_DISTANCE_REPORT_IN_PROGRESS';
export const FETCH_DISTANCE_REPORT_END = 'FETCH_DISTANCE_REPORT_END';

export const fetchInventoryReportEnd = () => ({
  type: FETCH_INVENTORY_REPORT_END,
});

export const fetchInventoryInProgress = () => ({
  type: FETCH_INVENTORY_REPORT_IN_PROGRESS,
});

export const setInventoryReport = ({ report, lastCoordinates }) => ({
  type: SET_INVENTORY_REPORT,
  report,
  lastCoordinates,
});

export const setDistanceTraveledReport = (report) => ({
  type: SET_DISTANCE_REPORT,
  report,
});
export const fetchDistanceTraveledReportEnd = () => ({
  type: FETCH_DISTANCE_REPORT_END,
});
export const fetchDistanceTraveledInProgress = () => ({
  type: FETCH_DISTANCE_REPORT_IN_PROGRESS,
});

export const fetchAlertReportInProgress = () => ({
  type: FETCH_ALERT_REPORT_IN_PROGRESS,
});

export const fetchAlertReportEnd = () => ({
  type: FETCH_ALERT_REPORT_END,
});

export const setAlertReportList = (list) => ({
  type: SET_ALERT_REPORT_LIST,
  list,
});

export const SET_ALERT_REPORT_LIMIT = 'SET_ALERT_REPORT_LIMIT';

export const setAlertReportLimit = (payload) => ({
  type: SET_ALERT_REPORT_LIMIT,
  payload,
});

export const SET_ALERT_PAGE = 'SET_ALERT_PAGE';

export const setAlertPage = (payload) => ({
  type: SET_ALERT_PAGE,
  payload,
});

export const SORT_BY_NAME = 'SORT_BY_NAME';

export const sortByNames = (str) => ({
  type: SORT_BY_NAME,
  str,
});

export const SET_SORT_DATE = 'SET_SORT_DATE';

export const SET_SORT_STATUS = 'SET_SORT_STATUS';

export const setSortDate = (str) => ({
  type: SET_SORT_DATE,
  str,
});
export const setSortStatus = (str) => ({
  type: SET_SORT_STATUS,
  str,
});

export const SET_SORT_MAC_ADDRESS = 'SET_SORT_MAC_ADDRESS';

export const setSortMACaddress = (str) => ({
  type: SET_SORT_MAC_ADDRESS,
  str,
});

export const SET_SORT_NAME = 'SET_SORT_NAME';

export const setSortName = (str) => ({
  type: SET_SORT_NAME,
  str,
});

export const SET_ARROW_DATE = 'SET_ARROW_DATE';
export const setArrowDate = (str) => ({
  type: SET_ARROW_DATE,
  str,
});
export const SET_ARROW_STATUS = 'SET_ARROW_DATE';
export const setArrowStatus = (str) => ({
  type: SET_ARROW_STATUS,
  str,
});
export const SET_ARROW_NAME = 'SET_ARROW_NAME';
export const setArrowName = (str) => ({
  type: SET_ARROW_NAME,
  str,
});
export const SET_ARROW_MAC = 'SET_ARROW_MAC';
export const setArrowMac = (str) => ({
  type: SET_ARROW_MAC,
  str,
});
export const FILTER_DEVICE_ARRAY = 'FILTER_DEVICE_ARRAY';
export const filterDeviceArray = (str) => ({
  type: FILTER_DEVICE_ARRAY,
  str,
});

export const GET_REPORT_DATE = 'GET_REPORT_DATE';
export const setReportDate = (str) => ({
  type: GET_REPORT_DATE,
  str,
});

export const fetchInventoryReport = () => async (dispatch, getState) => {
  try {
    dispatch(fetchInventoryInProgress());
    const state = getState();
    const {
      dateFrom,
      dateTo,
      currentObject,
    } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    const { selectedZone } = state.zones;

    // todo: fix sublocation selectors to have possibility to select all sublocations
    const querryParrams = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      api_key: state.app.currentApp.api_key,
      location_id: currentLocation.id,
      sublocation_id: currentFloor.id || undefined,
      zone_guid: selectedZone?.id > 0 ? selectedZone.id : undefined,
      object_id: currentObject && currentObject.id
        ? currentObject.id
        : undefined,
    };

    const response = await request.reports.get(
      endPointsEnum.inventoryReport,
      { params: querryParrams },
    );

    if (!response.report || !response.lastCoordinates) {
      throw new Error('Error in response');
    }

    dispatch(setInventoryReport(response));
    dispatch(fetchInventoryReportEnd());
  } catch (error) {
    dispatch(fetchInventoryReportEnd());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchDistanceTraveledReport = () => async (dispatch, getState) => {
  try {
    dispatch(fetchDistanceTraveledInProgress());
    const state = getState();
    const {
      dateFrom,
      dateTo,
      currentObject,
    } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;

    // todo: fix sublocation selectors to have possibility to select all sublocations
    const querryParrams = {
      location_id: currentLocation.id,
      sublocation_id: currentFloor.id || undefined,
      object_id: currentObject && currentObject.id
        ? currentObject.id
        : undefined,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      api_key: state.app.currentApp.api_key,
    };
    const response = await request.reports.get(
      endPointsEnum.distanceReport,
      { params: querryParrams },
    );

    if (!response) {
      throw new Error('Error in response');
    }
    dispatch(setDistanceTraveledReport(response));
    dispatch(fetchDistanceTraveledReportEnd());
  } catch (error) {
    dispatch(fetchInventoryReportEnd());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchAlertReports = (isExport = false) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom,
      dateTo,
      currentObject,
      // alertReportLimit,
      // currentPage,
    } = state.reports;
    const { currentFloor } = state.floor;
    const { currentLocation } = state.location;
    const { selectedGroup } = state.groups;

    const querryParrams = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      location_id: currentLocation.id || undefined,
      sublocation_id: currentFloor.id || undefined,
      group_id: selectedGroup && selectedGroup.id > 0 ? selectedGroup.id : undefined,
      object_id: currentObject && currentObject.id
        ? currentObject.id
        : undefined,
      api_key: state.app.currentApp.api_key,
      destination: 'report',
    };
    if (isExport) {
      querryParrams.export = 'xls';
    }
    dispatch(fetchAlertReportInProgress());
    const response = await request.tracking.get(
      endPointsEnum.trackedNotifications,
      { params: querryParrams },
    );
    if (isExport) {
      const filename = 'report-alerts.xlsx';
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      dispatch(fetchAlertReportEnd());
      return;
    }
    if (!response) {
      throw new Error('Error in response');
    }

    dispatch(setAlertReportList(response));
    dispatch(fetchAlertReportEnd());
  } catch (error) {
    dispatch(fetchAlertReportEnd());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchObjectReports = (isExport) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom, dateTo, currentObject,
    } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
    };
    if (isExport) {
      params.export = 'xls';
    }
    dispatch(fetchContactReportsInProgress());
    const path = `${endPointsEnum.reportsObject}/${currentObject.id}`;

    const response = await request.reports.get(path, { params });
    dispatch(fetchedContactReports());

    if (isExport) {
      return;
    }
    if (!response.data) {
      throw new Error('Error in response');
    }
    dispatch(setObjectReport(response.data, dateFrom, dateTo));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchFinalObjectReports = (isExport) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom, dateTo, currentObject,
    } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
    };
    if (isExport) {
      params.export = 'xls';
    }
    dispatch(fetchContactReportsInProgress());
    const path = `${endPointsEnum.groupedObject}/${currentObject.id}`;

    const response = await request.reports.get(path, { params });
    dispatch(fetchedContactReports());
    if (isExport) {
      return;
    }
    if (!response.groupReport) {
      throw new Error('Error in response');
    }
    dispatch(setFinalObjectReport(response.groupReport, dateFrom, dateTo));
    dispatch(setTimeOutOfZones(response.outsideOfZones, dateFrom, dateTo));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchContactReports = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo, currentObject } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      api_key: state.app.currentApp.api_key,
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      objectId: currentObject.id,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.socialContacts, {
      params,
    });
    if (!response.data) {
      throw new Error('Error in response');
    }

    dispatch(setContactReports(response.data || []));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchZoneContactReports = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom, dateTo, currentObject, duration,
    } = state.reports;
    if (!currentObject || !currentObject.id) {
      throw new Error('please select object');
    }
    const params = {
      from: Math.round(dateFrom.getTime() / 1000),
      to: Math.round(dateTo.getTime() / 1000),
      id: currentObject.id,
      duration,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(endPointsEnum.zonesContacts, {
      params,
    });
    if (!response.data) {
      throw new Error('Error in response');
    }
    dispatch(setContactZoneReports(response.data));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchDeviceMonitoring = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    const params = {
      api_key: apiKey,
    };

    dispatch(fetchContactReportsInProgress());

    const response = await request.reports.get(
      endPointsEnum.deviceMonitoring,
      { params },
    );
    if (!response.data) {
      throw new Error('Error in response');
    }

    dispatch(setDeviceMonitoring(response.data));
    dispatch(filterDeviceArray(response.data));
    dispatch(setArrowDate(noArrow));
    dispatch(setArrowName(noArrow));
    dispatch(setArrowMac(noArrow));
    dispatch(fetchedContactReports());
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};
export const sortByNameDeviceMonitoring = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const { sortByName, filterDevicesMonitoringArray } = state.reports;
    if (sortByName === 'asc') {
      filterDevicesMonitoringArray.sort((a, b) => a.name.localeCompare(b.name));
      dispatch(setSortName('desc'));
      dispatch(setArrowName(arrowDown));
      dispatch(setArrowStatus(noArrow));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowDate(noArrow));
    } else {
      filterDevicesMonitoringArray.sort((a, b) => b.name.localeCompare(a.name));
      dispatch(setSortName('asc'));
      dispatch(setArrowName(arrowUp));
      dispatch(setArrowStatus(noArrow));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowDate(noArrow));
    }
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};
export const sortByMACaddressDeviceMonitoring = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { sortByMACaddress, filterDevicesMonitoringArray } = state.reports;

    if (sortByMACaddress === 'asc') {
      filterDevicesMonitoringArray.sort((a, b) => (`${b.uuid}`).localeCompare(a.uuid));
      dispatch(setSortMACaddress('desc'));
      dispatch(setArrowMac(arrowDown));
      dispatch(setArrowStatus(noArrow));
      dispatch(setArrowDate(noArrow));
      dispatch(setArrowName(noArrow));
    } else {
      filterDevicesMonitoringArray.sort((a, b) => (`${a.uuid}`).localeCompare(b.uuid));
      dispatch(setSortMACaddress('asc'));
      dispatch(setArrowMac(arrowUp));
      dispatch(setArrowStatus(noArrow));
      dispatch(setArrowDate(noArrow));
      dispatch(setArrowName(noArrow));
    }
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};
export const sortByDateDeviceMonitoring = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { sortByDate, filterDevicesMonitoringArray } = state.reports;
    if (sortByDate === 'asc') {
      filterDevicesMonitoringArray.sort((a, b) => b.updated_at - a.updated_at);
      dispatch(setSortDate('desc'));
      dispatch(setArrowDate(arrowDown));
      // dispatch(setArrowStatus(noArrow));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowName(noArrow));
    } else {
      filterDevicesMonitoringArray.sort((a, b) => a.updated_at - b.updated_at);
      dispatch(setSortDate('asc'));
      dispatch(setArrowDate(arrowUp));
      // dispatch(setArrowStatus(noArrow));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowName(noArrow));
    }
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const sortByStatusDeviceMonitoring = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { sortByStatus, filterDevicesMonitoringArray } = state.reports;
    if (sortByStatus === 'asc') {
      filterDevicesMonitoringArray.sort((a, b) => b.updated_at - a.updated_at);
      dispatch(setSortStatus('desc'));
      dispatch(setArrowStatus(arrowDown));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowDate(noArrow));
      dispatch(setArrowName(noArrow));
    } else {
      filterDevicesMonitoringArray.sort((a, b) => a.updated_at - b.updated_at);
      dispatch(setSortStatus('asc'));
      dispatch(setArrowStatus(arrowUp));
      dispatch(setArrowMac(noArrow));
      dispatch(setArrowDate(noArrow));
      dispatch(setArrowName(noArrow));
    }
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const filterByNameDeviceMonitoring = (value) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { devicesMonitoringArray } = state.reports;
    const arr = devicesMonitoringArray.filter(
      (a) => a.name.includes(value) || a.uuid.includes(value),
    );

    dispatch(filterDeviceArray(arr));
    dispatch(setSortDate('desc'));
    dispatch(setArrowDate(arrowDown));
    dispatch(setArrowMac(noArrow));
    dispatch(setArrowName(noArrow));
  } catch (error) {
    dispatch(fetchedContactReports());
    dispatch(createAlert('error', error.message));
  }
};

export const FETCH_HISTORY_LIST_BY_OBJECT = 'FETCH_HISTORY_LIST_BY_OBJECT';
export const fetchHistoryListByObjectInProgress = () => ({
  type: FETCH_HISTORY_LIST_BY_OBJECT,
});

export const FETCH_HISTORY_LIST_BY_OBJECT_END = 'FETCH_HISTORY_LIST_BY_OBJECT_END';
export const fetchHistoryListByObjectEnd = () => ({
  type: FETCH_HISTORY_LIST_BY_OBJECT_END,
});

export const getHistoryListByObj = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      dateFrom, dateTo,
    } = state.reports;
    const { currentApp } = state.app;
    const { currentFloor } = state.floor;
    const { currentObject } = state.reports;
    const dateFromTs = Math.round(dateFrom.getTime());
    const querryParams = {
      api_key: currentApp.api_key,
      sublocation_id: currentFloor.id,
      from: dateFromTs,
      to: Math.round(dateTo.getTime()),
    };
    if (currentObject) {
      querryParams.object_id = currentObject.id;
    }

    dispatch(fetchHistoryListByObjectInProgress());

    const response = await request.reports.get(
      endPointsEnum.workDaypicture, { params: querryParams },
    );
    const { historyLines, legend } = response;

    if (!historyLines || !legend) {
      throw new Error('Wrong response format');
    }

    dispatch(fetchHistoryListByObjectEnd());
    return { historyLines, legend };
  } catch (error) {
    dispatch(fetchHistoryListByObjectEnd());
    dispatch(createAlert('error', error.message));
    return null;
  }
};
