import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export const CustomTextFieled = withStyles(() => ({
  root: {
    margin: '-1% 0 0 0',
    color: 'white',
    fontWeight: '600',
    borderRadius: '2px',
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
}))(TextField);
export default CustomTextFieled;
