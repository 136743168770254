import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { logout } from 'actions/user';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './style';

const ProfileContainer = ({
  name, email, company, hash, dispatch,
}) => {
  const { t } = useTranslation(['profile']);
  const [lang, setLang] = React.useState(i18next.language);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const onClickLogout = () => {
    dispatch(logout());
  };

  const onChangeLang = (event) => {
    const language = event.target.value;
    i18next.changeLanguage(language, () => {
      setLang(language);
    });
  };

  return (
    <div className={classes.profoleContainer} style={{ background: 'none' }}>
      <h2 className={classes.title}>{name}</h2>
      <Button
        style={{
          color: 'white', fontWeight: '500', textTransform: 'capitalize', fontSize: '22px', padding: '0 20px',
        }}
        onClick={handleClickOpen}
        color="primary"
        variant="contained"
      >
        {t('logout')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('logoutModalTitle')}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={onClickLogout} color="primary" autoFocus>
            {t('logout')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.wrapp}>
        <div className={classes.leftBlock}>
          <div className={classes.textWrapper}>
            <p className={classes.textItem}>
              {' '}
              {t('company')}
            </p>
          </div>
          <div className={classes.textWrapper}>
            {' '}
            <p className={classes.textItem}>
              {' '}
              {t('email')}
              {' '}
            </p>
            {' '}
          </div>
          <div className={classes.textWrapper}>
            <p className={classes.textItem}>
              {' '}
              {t('hash')}
              {' '}
            </p>
          </div>
          <p className={classes.textItem} style={{ lineHeight: '38px' }}>
            {' '}
            {t('language')}
            {' '}
          </p>
        </div>
        <div className={classes.rightBlock}>
          <div className={classes.textWrapper && classes.infoWrapper}>
            <p className={classes.textItem}>
              {' '}
              {company || '-'}
              {' '}
            </p>
          </div>
          <div className={classes.textWrapper && classes.infoWrapper}>
            <p className={classes.textItem}>
              {' '}
              {email || '-'}
              {' '}
            </p>
          </div>
          <div className={classes.textWrapper && classes.infoWrapper}>
            {' '}
            <p className={classes.textItem}>
              {' '}
              {hash || '-'}
              {' '}
            </p>
            {' '}
          </div>
          <div className={classes.textWrapper && classes.infoWrapper}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={lang}
              onChange={onChangeLang}
            >
              <MenuItem value="ru">Русский</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="et">Eestlane</MenuItem>
              <MenuItem value="ch">中文</MenuItem>
              <MenuItem value="jp">日本語</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    name,
    email,
    hash,
    company,
  } = state.user;

  return {
    name,
    email,
    hash,
    company,
  };
}

export default connect(mapStateToProps)(ProfileContainer);
