import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import { format } from 'date-fns';

import { fetchInventoryReport, setCurrentObject } from 'actions/reports';
import { objectsByAppFetch } from 'actions/objects';
import { setLocation } from 'actions/locations';
import { setFloor } from 'actions/floors';
import { setSelectedZone } from 'actions/zones';
import { setSelectedGroup } from 'actions/groups';
import { allValuesConstant, selectNoOneConstant } from 'constans';

import BuildButton from 'components/reports/BuildButton.component';
import DatePickers from 'components/reports/datePickers/datePickers.component';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { dateTableFormat, commonFormatDuration } from 'helpers/formatters';
import AutoComplete from 'components/reports/ReportsAutoComplete';
import FormControl from '@material-ui/core/FormControl';
import ZoneSelector from '../ReportsZoneSelector';
import LocationSelector from '../ReportsLocationSelector';
import FloorSelector from '../ReportsFloorSelector';
import containerStyles from '../../shared/Container/styles';
import useStyles from './inventoryReport.component.style';

import { formatCoordinates, downloadXls } from './xls.export';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const InventoryReport = (props) => {
  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t, i18n } = useTranslation(['report', 'monitoring', 'translation']);
  const dateLocale = datePickerLocales[i18n.language];

  const {
    dispatch,
    report,
    lastCoordinates,
    currentObject,
    currentApp,
    dateFrom,
    dateTo,
    objectsByAppArray,
    locationsObject,
    currentLocation,
    isFetching,
    floorsObject,
    currentFloor,
    zonesObject,
    selectedZone,
    zoneTypes,
    openMenu,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');

  const fetchReport = async () => {
    setPage(0);
    await dispatch(fetchInventoryReport());
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;

    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));
      return;
    }
    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesObject[zoneId]));
    }
  };

  const handleDownload = () => {
    downloadXls({
      report,
      lastCoordinates,
      currentObject,
      translate: t,
      locale: dateLocale,
    });
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 1000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, page, rowsPerPage, currentApp]);

  useEffect(() => {
    setPage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, rowsPerPage, currentApp, dateFrom, dateTo]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    // todo: add selectors and fix translate
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.controlWrapper}>
              <FormControl>
                <LocationSelector
                  locations={locationsObject}
                  className={classes.selector}
                  value={currentLocation.id}
                  onChange={handleChangeLocation}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FormControl>
                <FloorSelector
                  floors={floorsObject}
                  className={classes.selector}
                  onChange={handleChangeFloor}
                  value={currentFloor.id}
                  currentLocationId={currentLocation.id}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.controlWrapper}>
              <FormControl>
                <ZoneSelector
                  className={classes.selector}
                  onChange={handleChangeZone}
                  zones={zonesObject}
                  value={selectedZone.id}
                  zoneTypes={zoneTypes}
                  currentFloorId={currentFloor.id}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </FormControl>
            </div>
          </form>
          <form className={classes.formControls} noValidate>

            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
              />
            </div>
            <DatePickers />
          </form>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <div className={classes.btnWrapper}>
              <BuildButton
                style={{ margin: '5px' }}
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchReport}
                disabled={isFetching}
              >
                {t('buildReport')}
              </BuildButton>
              {report.length > 0 ? (
                <div
                  className={classes.downloadControlContainer}
                  onClick={handleDownload}
                  onKeyUp={() => handleDownload()}
                  tabIndex="0"
                  role="button"
                >
                  <div className={classes.downloadIcon} />
                  <div className={classes.downLoadPhraseContainer}>
                    <p className={classes.downLoadPhrase}>
                      {' '}
                      {t('downloadXls')}
                      {' '}
                    </p>
                  </div>
                </div>
              ) : null}

            </div>
            <div className={classes.table}>
              <Typography variant="h6">{t('translation:inventoryReports')}</Typography>
              <TableContainer component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableTitle}>{t('objectTitle')}</TableCell>
                      <TableCell className={classes.tableTitle}>{t('monitoring:group')}</TableCell>
                      <TableCell className={classes.tableTitle}>{t('zone')}</TableCell>
                      <TableCell className={classes.tableTitle}>{t('monitoring:coordinates')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('Enter')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('Exit')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('duration')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.slice(
                      page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                    ).map((
                      objectReport, index,
                    ) => {
                      const entryDate = new Date(objectReport.inside_at * 1000);
                      // eslint-disable-next-line max-len
                      const exitDate = objectReport.outside_at === null ? null : new Date(objectReport.outside_at * 1000);
                      const key = index + 1;
                      return (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {objectReport?.tracked_object?.title || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {objectReport?.tracked_object?.trackedGroups?.title || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {objectReport?.zone?.name || t('deletedZone')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {formatCoordinates(objectReport?.coordinates)}
                          </TableCell>
                          <TableCell align="right">
                            {format(entryDate, dateTableFormat, { locale: dateLocale })}
                          </TableCell>
                          <TableCell align="right">
                            {exitDate ? format(exitDate, dateTableFormat, { locale: dateLocale }) : t('stillZone')}
                          </TableCell>
                          <TableCell align="right">
                            {commonFormatDuration(objectReport.total_time, datePickerLocales[i18n.language]) || 'Wrong time'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={report.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t('labelRowsPerPage')}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
              />
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    inventoryReport,
    inventoryRepLastCoords,
    isFetchingInventoryReport,
    dateFrom,
    dateTo,
    currentObject,
  } = state.reports;
  const { objectsByAppArray } = state.objects;
  const { currentApp, openMenu } = state.app;

  const {
    locationsObject,
    currentLocation,
    isFetching: isFetchingLocations,
  } = state.location;
  const {
    floorsObject,
    currentFloor,
    isFetching: isFetchingFloors,
  } = state.floor;
  const { zonesObject, selectedZone, zoneTypes } = state.zones;
  return {
    locationsObject,
    currentLocation,
    isFetchingLocations,

    floorsObject,
    currentFloor,
    isFetchingFloors,

    zonesObject,
    selectedZone,
    zoneTypes,
    openMenu,

    lastCoordinates: inventoryRepLastCoords,
    report: inventoryReport,
    currentObject,
    currentApp,
    dateFrom,
    dateTo,
    objectsByAppArray,
    isFetching: isFetchingInventoryReport,
  };
}

export default connect(mapStateToProps)(InventoryReport);
