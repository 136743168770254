import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CustomButton from 'components/settings/Button.component';
import useStyles from '../../containers/monitoring/style';

const DialogMonitoring = ({
  objectInfo,
  objectInfoArray,
  handleCloseObjectModal,
  openObjectModal,
  Transition,
  setOpenObjectModal,
  floor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['monitoring']);
  useEffect(() => {
    if (objectInfo) {
      setOpenObjectModal(true);
    }
  }, [objectInfo, setOpenObjectModal, objectInfoArray]);

  if (!objectInfo) {
    return null;
  }
  // const getZ = (props) => {
  //   if (!props) {
  //     return false;
  //   }

  //   const height = props.filter((el) => el.type === 'height')[0];

  //   return height?.data?.height;
  // };
  const coordinates = {
    x: (objectInfo.kx * floor.w).toFixed(2),
    y: (objectInfo.ky * floor.h).toFixed(2),
    // z: getZ(objectInfo.properties),
    z: (objectInfo?.z)?.toFixed(2) || '',
  };
  const notDisplayPropTypes = new Set(['color', 'kx', 'ky', 'file_url', 'imageSourceName', 'objectType', 'objectColor']);

  const propTableDataUnsort = [];
  if (objectInfo.properties) {
    objectInfo.properties.forEach((prop) => {
      const {
        id, key, value, updated_at: updated,
      } = prop;
      if (!notDisplayPropTypes.has(key)) {
        propTableDataUnsort.push({
          key,
          value,
          typeId: id,
          updated,
        });
      }
    });
  }

  const propTableData = [...propTableDataUnsort].sort(
    (a, b) => a.typeId - b.typeId,
  );

  return (
    <div>
      <Dialog
        open={openObjectModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseObjectModal}
        maxWidth="md"
      >
        {objectInfo ? (
          <DialogContent
            className={
          objectInfo.videostream_url
            ? classes.dialogContent
            : classes.noneDialogContent
        }
          >
            <div>
              {objectInfo.file_url ? (
                <div>
                  <img
                    className={classes.itemImage}
                    src={objectInfo.file_url}
                    alt="file_url"
                  />
                </div>
              ) : null}
              {objectInfo.title ? (
                <div>
                  <h3>{objectInfo ? t('objectTitle') : null}</h3>
                  <p>{objectInfo.title}</p>
                </div>
              ) : null}
              {objectInfo.beacon_title ? (
                <div>
                  <h3>{objectInfo ? t('objectDevcie') : null}</h3>
                  <p>{objectInfo.beacon_title}</p>
                </div>
              ) : null}
              {objectInfo.group_title ? (
                <div>
                  <h3>{objectInfo ? t('objectGroup') : null}</h3>
                  <p>{objectInfo.group_title}</p>
                </div>
              ) : null}
              <div>
                <h3>{t('coordinates')}</h3>
                <p>
                  {`
                X: ${coordinates.x} \u00A0 
                Y: ${coordinates.y}
                ${coordinates.z ? ` \u00A0 Z: ${coordinates.z}` : ''}`}
                </p>
              </div>
              {propTableData.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('keys')}</TableCell>
                        <TableCell>{t('properties')}</TableCell>
                        {/* <TableCell>{t('update')}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {propTableData.map((prop, index) => (
                        <TableRow
                          // eslint-disable-next-line react/no-array-index-key
                          key={index.toString()}
                          className={`${
                            index % 2 === 0
                              ? classes.tableElementEven
                              : classes.tableElementOdd
                          }`}
                        >
                          <TableCell>{prop.key}</TableCell>
                          <TableCell>{prop.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            {objectInfo.videostream_url ? (
              <div>
                <div>
                  <img
                    className={classes.videoBlock}
                    src={objectInfo.videostream_url}
                    alt={objectInfo.videostream_url}
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>
        ) : null}
        <DialogActions>
          <CustomButton onClick={handleCloseObjectModal} color="primary">
            {t('closeBtn')}
          </CustomButton>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default DialogMonitoring;
