/* eslint-disable no-underscore-dangle */
const checkObjectInWindowConfig = (key) => {
  if (!window || !window._configuration_env) {
    return false;
  }

  return window._configuration_env[key];
};

export default checkObjectInWindowConfig;
