import {
  SET_TIMER_ID,
  SET_TIMER_PERIOD,
  STOP_TIMER_UPDATE,
  SET_OBJECT_TTL,
} from 'actions/worker';

const initialState = {
  timerID: null,
  updatePeriod: 1000,
  objectTTL: 60,
};

const workerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMER_ID:
      return { ...state, timerID: action.timerID };
    case SET_TIMER_PERIOD:
      return { ...state, updatePeriod: action.updatePeriod };
    case STOP_TIMER_UPDATE:
      return { ...state, timerID: [] };
    case SET_OBJECT_TTL:
      return { ...state, objectTTL: action.objectTTL };
    default:
      return state;
  }
};

export default workerReducer;
