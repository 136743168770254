import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import bgImage from 'assets/images/bg.png';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: `url(${bgImage}) no-repeat`,
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
  },
  bodyObject: {
    width: '25px',
    height: '25px',
    background: 'white',
    borderRadius: '50%',
    border: 'black 3px solid',
    opacity: 1,
    margin: '0',
    animationName: '$object',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationPlayState: 'alternate',
    zIndex: 2,
  },
  bodyObjectImg: {
    width: '25px',
    height: '25px',
    background: 'white',
    borderRadius: '50%',
    opacity: 1,
    margin: '0',
    animationName: '$object',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationPlayState: 'alternate',
    zIndex: 2,
  },
  line: {
  },
  svg: {
    marginTop: '-131px',
    marginLeft: '78px',
  },
  '@keyframes object': {
    '0%': {
    },
    '20%': {
      margin: '40px -45px 0 0',
    },
    '40%': {
      margin: '90px -100px 0 0',
    },
    '60%': {
    },
    '80%': {
      margin: '84px 20px 0 0',
    },
  },
}));

const ViewObject = (props) => {
  const classes = useStyles();
  const {
    color, image, iconImageUrl, colorObject,
  } = props;
  return (
    <>
      <div className={classes.wrapper}>
        {
      image || iconImageUrl
        ? (
          <img
            src={iconImageUrl || image}
            height="50px"
            alt="noImg"
            className={classes.bodyObjectImg}
          />
        )
        : (<div className={classes.bodyObject} style={{ background: colorObject || 'white' }} />
        )

      }
      </div>
      <div>
        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" width="130" height="150" viewBox="0 0 960 560" enableBackground="new 0 0 960 560" xmlSpace="preserve">
          <polygon className={classes.line} fill="none" stroke={color} strokeWidth="16" strokeMiterlimit="10" points="350,60 300,700 800,700" />
        </svg>
      </div>
    </>
  );
};

export default ViewObject;
