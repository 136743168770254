export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_GROUPS_LIST = 'SET_GROUPS_LIST';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_MODAL_FORM = 'SET_MODAL_FORM';
export const ADD_CUSTOM_PROPERTY = 'ADD_CUSTOM_PROPERTY';
export const REMOVE_CUSTOM_PROPERTY = 'REMOVE_CUSTOM_PROPERTY';
export const EDIT_CUSTOM_PROPERTY = 'EDIT_CUSTOM_PROPERTY';
export const DELETE_CUSTOM_PROPERTY = 'DELETE_CUSTOM_PROPERTY';
export const ADD_COLOR_PROPERTY_TRACK = 'ADD_COLOR_PROPERTY_TRACK';
export const ADD_COLOR_PROPERTY_OBJECT = 'ADD_COLOR_PROPERTY_OBJECT';
export const CLOSE_CUSTOM_PROPERTY = 'CLOSE_CUSTOM_PROPERTY';

export const OPEN_DELETE_MODAL = 'OPEN_DELETE_MODAL';
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL';
export const SET_OBJECT_IMAGE_TO_STORE = 'SET_OBJECT_IMAGE_TO_STORE';
export const OPEN_DELETE_DEVICE_MODAL = 'OPEN_DELETE_DEVICE_MODAL';
export const CLOSE_DELETE_DEVICE_MODAL = 'CLOSE_DELETE_DEVICE_MODAL';

export const openDeleteDeviceModalAction = () => ({
  type: OPEN_DELETE_DEVICE_MODAL,
});
export const closeDeleteDeviceModalAction = () => ({
  type: CLOSE_DELETE_DEVICE_MODAL,
});

export const openDeleteModalAction = () => ({
  type: OPEN_DELETE_MODAL,

});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_MODAL,

});
export const addColorPropertyTrack = (prop) => ({
  type: ADD_COLOR_PROPERTY_TRACK,
  prop,
});
export const addColorPropertyObject = (prop) => ({
  type: ADD_COLOR_PROPERTY_OBJECT,
  prop,
});
export const addCustomProperty = (prop) => ({
  type: ADD_CUSTOM_PROPERTY,
  prop,
});
export const editCustomProperty = (prop) => ({
  type: EDIT_CUSTOM_PROPERTY,
  prop,
});
export const removeCustomProperty = (prop) => ({
  type: REMOVE_CUSTOM_PROPERTY,
  prop,
});
export const closeCustomProperty = (prop) => ({
  type: CLOSE_CUSTOM_PROPERTY,
  prop,
});
export const deleteCustomProperty = (arr) => ({
  type: DELETE_CUSTOM_PROPERTY,
  arr,
});
export const setModalForm = (modalForm) => ({
  type: SET_MODAL_FORM,
  modalForm,
});
export const setObjectImageToStore = (fileInfo, image) => ({
  type: SET_MODAL_FORM,
  fileInfo,
  image,
});
export const openModalAction = (formType) => ({
  type: OPEN_MODAL,
  formType: {
    formType,
  },
});

export const closeModalAction = () => ({
  type: CLOSE_MODAL,
});

export const setGroupsList = (list) => ({
  type: SET_GROUPS_LIST,
  list,
});

export const setDevices = (list) => ({
  type: SET_DEVICES,
  list,
});
