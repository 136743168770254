import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CustomSelector = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    root: {
      width: '256px',
      margin: '7px 0',
      '& label': {
        fontSize: '19px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        fontFamily: fontRegular,
        marginLeft: '0',
      },
      '& .MuiInputBase-root': {
        '& .MuiSvgIcon-root .MuiSelect-icon': {
          marginRight: '18px',
        },
      },
    },

  };
})(TextField);

export default CustomSelector;
