import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

import request, { endPointsEnum } from 'services/http';

import { cookieTokenName, cookOpts } from 'constans';
import { createAlert } from './alert';

export const GET_USER_IN_PROGRESS = 'GET_USER_IN_PROGRESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FINISHED = 'FETCH_USER_FINISHED';
export const FETCH_USER_IN_PROGRESS = 'FETCH_USER_IN_PROGRESS';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';
export const RESET_STORE_TO_DEFAULT = 'RESET_STORE_TO_DEFAULT';

export const fetchFinished = () => ({
  type: FETCH_USER_FINISHED,
});

export const fetchUserInProgress = () => ({
  type: FETCH_USER_IN_PROGRESS,
});

export const setUser = (user) => ({
  type: SET_USER,
  ...user,
});

export const setUserToDefault = () => ({
  type: LOGOUT,
});

export const destroyStore = () => ({
  type: RESET_STORE_TO_DEFAULT,
});

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('hash');
  Cookies.remove(cookieTokenName, cookOpts);

  dispatch(destroyStore());
};

// eslint-disable-next-line consistent-return
export const userFetchIfNeeded = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      name, email, hash, company, /* avatar_url */
    } = state.user;
    // avatar_url is temporary optional
    if (name && email && hash && company) {
      return false;
    }

    const storedToken = Cookies.get(cookieTokenName);
    const tokenPayload = jwt.decode(storedToken);
    const {
      hash: storedHash, id: storedId,
    } = tokenPayload.userData;

    // const storedToken = localStorage.getItem('token');
    // const storedHash = localStorage.getItem('hash');
    // const storedId = localStorage.getItem('id');

    if (!storedToken || !storedHash || !storedId) {
      dispatch(logout());
    }

    dispatch(fetchUserInProgress());

    const result = await request.client.get(endPointsEnum.userGet, {
      params: {
        id: storedId,
        userHash: storedHash,
      },
    });

    dispatch(fetchFinished());
    dispatch(setUser(result));
    return true;
  } catch (error) {
    dispatch(fetchFinished());
    dispatch(createAlert('error', error.message));
    dispatch(logout());
  }
};
