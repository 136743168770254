/* eslint-disable jsx-a11y/media-has-caption */
// import react and redux and etc
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import OpenLayersMap from '@navigine/openlayers-navigine';

import { setCurrentObject } from 'actions/reports';
import { setLocation } from 'actions/locations';
import { setFloor } from 'actions/floors';
import { setSelectedZone, fetchZoneTypesIfNeeded } from 'actions/zones';
import { setSelectedGroup } from 'actions/groups';
import { setJson } from 'actions/objects';

// import constans
import {
  allValuesConstant, selectNoOneConstant, token, styleUrl,
} from 'constans';

// import components
import LocationSelector from 'components/shared/LocationSelector/LocationSelector';
import FloorSelector from 'components/shared/FloorSelector/FloorSelector';
import ZoneSelector from 'components/shared/ZonesSelector/ZonesSelector';
import GroupSelector from 'components/shared/GroupSelector/GroupSelector';
import AutoComplete from 'components/shared/AutoComplete/autoComplete';

// import styles
import MainMenu from 'components/menu/MainMenu';
import DialogMonitoring from '../../components/dialog/DialogMonitorng';
import DialogArray from '../../components/dialog/DailogArrayObjects';
import useStyles from './style';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Monitoring = (props) => {
  const {
    dispatch,
    locationsObject,
    currentLocation,
    floorsObject,
    currentFloor,
    objectsInMonitoringArray,
    selectedZone,
    selectedGroup,
    groupsObject,
    zonesObject,
    zoneTypes,
    currentObject,
    currentApp,
    openMenu,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(['monitoring']);
  const [openObjectModal, setOpenObjectModal] = React.useState(false);
  const [map, setMap] = useState({});
  const [objectInfo, setObjectInfo] = useState(null);
  const [objectInfoArray, setObjectInfoArray] = useState([]);
  const [showTrackLines, setShowTrackLines] = useState(false);
  // const [showGlobalMap, setShowGlobalMap] = useState(false);
  const mapElement = useRef();
  const zonesArray = Object.keys(zonesObject)
    .filter((zoneId) => parseInt(zonesObject[zoneId].floor, 10)
      === parseInt(currentFloor.id, 10));

  const filteredObjectsArray = objectsInMonitoringArray.filter((object) => {
    const floorFilter = parseInt(object.attributes.sublocation_id, 10)
      === parseInt(currentFloor.id, 10);
    const groupFilter = selectedGroup.id === allValuesConstant
      || parseInt(object.attributes.tracked_group_id, 10)
      === parseInt(selectedGroup.id, 10);
    return floorFilter && groupFilter;
  });

  // Click on map event callback
  const mapOnClickCallback = (data) => {
    if (!data.feature) {
      return;
    }
    const { feature, featuresArray } = data;
    if (featuresArray.length < 2) {
      const trackedObjectData = feature.trackedObject
        ? feature.trackedObject.attributes
        : null;
      if (trackedObjectData) {
        setObjectInfo(trackedObjectData);
      }
    } else {
      setObjectInfoArray(featuresArray.map((att) => att.trackedObject.attributes));
    }
  };

  const filterTrackedObjects = (trackedObjectsArray, groupsToFilterArray) => {
    if (!trackedObjectsArray || trackedObjectsArray.length < 1) {
      return [];
    }
    const result = trackedObjectsArray.filter((trackedObject) => {
      const {
        tracked_group_id: trackedGroupId,
        sublocation_id: sublocationId,
      } = trackedObject.attributes;
      const sameFloor = sublocationId === parseInt(currentFloor.id, 10);
      let sameGroup = true;
      if (groupsToFilterArray && groupsToFilterArray.id > 0) {
        sameGroup = trackedGroupId === parseInt(groupsToFilterArray.id, 10);
      }

      return sameFloor && sameGroup;
    });

    return result || [];
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;
    map.deleteAllFeaturesFromSourse(map.enum.zones);

    if (zoneId === allValuesConstant) {
      const zones = zonesArray.map((zoneID) => zonesObject[zoneID]);
      dispatch(setSelectedZone({ id: allValuesConstant }));
      map.addZones(zones);
      return;
    }

    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }

    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesObject[zoneId]));
      map.addZone(zonesObject[zoneId]);
      map.zoomToFeature(map.getZoneFeature(zoneId));
    }
  };

  const handleChangeGroup = (event) => {
    event.preventDefault();
    const groupId = event.target.value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
    const trackedObjectType = map.enum.trackedObjects;

    if (map.sources[trackedObjectType]) {
      const filteredObjects = filterTrackedObjects(
        objectsInMonitoringArray,
        newSelectedGroup,
      );

      map.removeAllTrackedObjects();
      map.addTrackedObjects(filteredObjects);
    }
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const selectObject = (event, object) => {
    if (currentObject) {
      map.unHighlightTrackedObject(currentObject);
    }
    if (object) {
      map.highlightTrackedObject(object, theme.palette.primary.main);
      map.zoomToTrackedObjectByID(object.id);
    }
    dispatch(setCurrentObject(object));
  };

  const handleCloseObjectModal = () => {
    setOpenObjectModal(false);
    setObjectInfo(null);
    setObjectInfoArray([]);
    dispatch(setJson(''));
  };

  const handleShowTrackLines = (event) => {
    const { checked } = event.target;
    setShowTrackLines(checked);
  };

  // const swichGlobalMap = (event) => {
  //   const { checked } = event.target;
  //   setShowGlobalMap(checked);
  // };

  // Zone types hook
  useEffect(() => {
    const fetchZoneTypes = async () => {
      await dispatch(fetchZoneTypesIfNeeded());
    };
    fetchZoneTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor.id]);

  // Zones hook
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    if (zoneTypes) {
      map.setZoneTypes(zoneTypes);
    }

    if (selectedZone && selectedZone.id) {
      if (selectedZone.id === allValuesConstant) {
        const zones = zonesArray.map((zoneID) => zonesObject[zoneID]);
        map.addZones(zones);
      } else if (selectedZone.id > 0) {
        map.addZone(zonesObject[selectedZone.id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoneTypes]);

  // Tracked objects hook - update, add new, delete old
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    const objectsFilteredByFloor = filterTrackedObjects(
      objectsInMonitoringArray,
      selectedGroup,
    );
    const trackedObjectsOnMap = map.getAllTrackedObjects();

    if (!objectsFilteredByFloor.length) {
      if (trackedObjectsOnMap) {
        trackedObjectsOnMap.forEach((featureOnMap) => {
          map.deleteTrackedObject(featureOnMap);
        });
      }
      return;
    }

    if (trackedObjectsOnMap.length > 0) {
      const updatedObjects = {};
      if (document.visibilityState !== 'visible') {
        return;
      }
      objectsFilteredByFloor.forEach((currentObjectElement) => {
        const objectOnMap = map.getFeaturePayload(
          map.getTrackedObjectFeature(currentObjectElement.id),
        );
        if (!objectOnMap) {
          map.addTrackedObject(currentObjectElement);
          return;
        }

        objectOnMap.updateTrackedObject(currentObjectElement);
        updatedObjects[currentObjectElement.id] = true;
      });

      trackedObjectsOnMap.forEach((featureOnMap) => {
        const currentObjectId = featureOnMap.getId();
        if (!updatedObjects[currentObjectId]) {
          map.deleteTrackedObject(featureOnMap);
        }
      });
    } else {
      map.addTrackedObjects(objectsFilteredByFloor);
      const { properties } = currentApp;

      const clusterDistProp = properties.filter((prop) => prop.type === 'cluster_distance')[0];
      let clusterDist = 50;
      if (clusterDistProp) {
        clusterDist = Math.abs(parseFloat(clusterDistProp.value));
      }
      map.addClusterizedFeatures(clusterDist, map.enum.trackedObjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, objectsInMonitoringArray, currentFloor, selectedGroup]);

  // Tracklines hook - add, remove
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }

    if (showTrackLines) {
      map.addTrackHistory();
    } else {
      map.removeTrackHistory();
    }
  }, [showTrackLines, map]);

  // Global Map hook - swich on, switch off
  // useEffect(() => {
  //   if (!(map instanceof OpenLayersMap)) {
  //     return;
  //   }

  //   map.globalMapSwitch(showGlobalMap, token, styleUrl);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showGlobalMap]);

  // Map OnClick event subscription hook
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    map.on('mapClicked', mapOnClickCallback.bind(this));
  }, [map]);

  // Destroy map hook
  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp.id]);
  // Map hook - initialize, destroy
  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }

    try {
      const translate = {
        global: t('global'), local: t('local'), street: t('street'), satellite: t('satellite'), img: t('img'), noImg: t('noImg'),
      };
      const {
        lat, lon, hor, w, h,
      } = currentFloor;
      const newMap = new OpenLayersMap({
        target: mapElement.current,
        imageURL: currentFloor.image_url,
        imageWidth: currentFloor.pw,
        imageHeight: currentFloor.ph,
        imageBounds: currentFloor.image_bounds,
        lat,
        lon,
        w,
        h,
        hor,
        isGlobalMap: false,
        styleUrl,
        token,
        translate,
        isAnimate: true,
      });
      // ResizeObserver is needed to check if a map item is missing in the viewport
      // otherwise, the map will be created with a blank canvas
      const { clientWidth, clientHeight } = mapElement.current;
      if (!clientWidth || !clientHeight) {
        const resize = (entires, observer) => {
          newMap.map.updateSize();
          observer.unobserve(mapElement.current);
        };
        new ResizeObserver(resize).observe(mapElement.current);
      }
      setMap(newMap);
    } catch (error) {
      setMap({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor.id, currentFloor.pw, currentFloor.ph, currentFloor.image_url]);
  return (
    <div className={!openMenu ? classes.container : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.control_wrapper}>
            <LocationSelector
              locations={locationsObject}
              value={currentLocation.id}
              onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FloorSelector
              floors={floorsObject}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </div>

          <div className={classes.control_wrapper}>
            <ZoneSelector
              onChange={handleChangeZone}
              zones={zonesObject}
              value={selectedZone.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>

          <div className={classes.control_wrapper}>
            <GroupSelector
              onChange={handleChangeGroup}
              groups={groupsObject}
              value={selectedGroup.id}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showTrackLines}
                  onChange={handleShowTrackLines}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('showTrackLines')}
            />
          </div>
          {/* <div className={classes.control_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showGlobalMap}
                  onChange={swichGlobalMap}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('globalMap')}
            />
          </div> */}
          <div className={classes.control_wrapper}>
            <FormControl>
              <div className={classes.objectsCount}>
                {' '}
                <p>
                  {' '}
                  {t('countOfObjects')}
                  {' '}
                  <b>{filteredObjectsArray.length}</b>
                </p>
                {' '}
              </div>
              <AutoComplete
                filteredObjectsArray={filteredObjectsArray}
                currentObject={currentObject}
                selectObject={selectObject}
              />
            </FormControl>
          </div>
        </div>
      </MainMenu>
      <div ref={mapElement} className={classes.map_container} />
      <DialogMonitoring
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        setOpenObjectModal={setOpenObjectModal}
        objectInfoArray={objectInfoArray}
        floor={currentFloor}
      />
      <DialogArray
        setObjectInfo={setObjectInfo}
        setObjectInfoArray={setObjectInfoArray}
        openObjectModal={openObjectModal}
        TransitionComponent={Transition}
        handleCloseObjectModal={handleCloseObjectModal}
        objectInfo={objectInfo}
        objectInfoArray={objectInfoArray}
        setOpenObjectModal={setOpenObjectModal}
      />

    </div>
  );
};

function mapStateToProps(state) {
  const {
    locationsObject,
    currentLocation,
    isFetching: isFetchingLocations,
  } = state.location;
  const {
    floorsObject,
    currentFloor,
    isFetching: isFetchingFloors,
  } = state.floor;
  const { groupsObject, selectedGroup } = state.groups;
  const { objectsInMonitoringArray, json } = state.objects;
  const { zonesObject, selectedZone, zoneTypes } = state.zones;

  const { currentObject } = state.reports;
  const { currentApp, openMenu } = state.app;

  return {
    currentApp,
    zoneTypes,
    locationsObject,
    currentObject,
    currentLocation,
    isFetchingLocations,
    floorsObject,
    currentFloor,
    isFetchingFloors,
    zonesObject,
    selectedZone,
    groupsObject,
    selectedGroup,
    objectsInMonitoringArray,
    json,
    openMenu,
  };
}

export default connect(mapStateToProps)(Monitoring);
