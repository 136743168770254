import Image from 'assets/images/welcomeLogo/blundoor/logo.png';

import faviconImage from 'assets/images/favicons/blundoor/favicon.png';

const favicon = document.getElementById('favicon');

favicon.href = faviconImage;

const theme = {
  palette: {
    primary: {
      main: '#00b1f1',
    },
  },
  CustomStyles: {
    LogoImageBackground: `url(${Image}) center no-repeat`,
  },
};

export default theme;
