import React, { Suspense } from 'react';
import '../i18n';
import '../index.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ThemeProvider } from '@material-ui/core/styles';

import { Provider } from 'react-redux';
import App from '../App';
import configureStore from '../configureStore';

import materialTheme from '../themes-materialUI';

const store = configureStore();

function Root() {
  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <>
          <ThemeProvider theme={materialTheme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </>
      </Provider>
    </Suspense>
  );
}

export default Root;
