/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import {
  setApp, changeAppInToken, fetchAppInProgress, fetchedAppsList,
} from 'actions/app';
import Popover from '@material-ui/core/Popover';
import { Card, CardContent } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';

import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import WarningIcon from '@material-ui/icons/Warning';
import init from 'helpers/init';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { setNotifications, setLastId } from 'actions/notifications';
import { profile } from 'constans';
import useStyles from './RightMenu.styles';

const AppList = ({
  avatarUrl, zonesObject, dispatch, appsObject, currentApp, notificationsObject,
}) => {
  const { t } = useTranslation(['notifications', 'report']);
  const classes = useStyles();

  const [unreadCount, setUnreadCound] = useState(0);
  const [notificationsArray, setNotificationsArray] = useState([]);

  const maxbage = 99;
  useEffect(() => {
    setUnreadCound(notificationsArray.filter((notification) => !notification.viewed).length);
  }, [notificationsArray]);

  useEffect(() => {
    setNotificationsArray(notificationsObject);
  }, [notificationsObject]);

  const defaultProps = {
    color: 'error',
    children: <WarningIcon />,
  };
  const handleChangeApp = async (event) => {
    dispatch(fetchAppInProgress());
    const selectedAppId = event.target.value;
    const gotNewToken = await changeAppInToken(selectedAppId, dispatch);
    if (!gotNewToken) {
      return;
    }
    dispatch(setApp(appsObject[selectedAppId]));
    dispatch(setNotifications([]));
    dispatch(setLastId(''));
    setNotificationsArray([]);
    setUnreadCound(0);
    localStorage.setItem('appID', selectedAppId);
    await init(dispatch);
    dispatch(fetchedAppsList());
  };
  const notificationDateFormat = 'dd.MM.yyyy HH:mm';
  const notificationTypes = {
    standing: 'STANDING',
    standingOut: 'STANDING_OUT',
    exit: 'EXIT',
    entry: 'ENTRY',
    sos: 'SOS',
    fall: 'Падение',
  };

  const formatConditionDuration = (time) => {
    if (time <= 0) {
      return `0 ${t('report:sec')}`;
    }
    const minutes = Math.trunc(time / 60);
    const seconds = time % 60;

    return `${minutes > 0 ? `${minutes} ${t('report:min')}` : ''} ${seconds > 0 ? `${seconds} ${t('report:sec')}` : ''}`;
  };

  const formatNotification = (notification) => {
    const {
      trackedObjects: object,
      trackedGroups: group,
      trackedConditions: condition,
      created_at: createdAt,
    } = notification;
    const { zone_guid: zoneGuid } = condition;
    const groupTitle = group ? group.title : '';
    let zoneTitle = '';
    if (zoneGuid && zonesObject[zoneGuid]) {
      zoneTitle = zonesObject[zoneGuid].title;
    }
    let conditionDuration = condition.time;
    if (conditionDuration) {
      conditionDuration = formatConditionDuration(conditionDuration);
    }

    const dateFormatted = format(new Date(createdAt), notificationDateFormat);
    let result = '';
    if (object?.title) {
      result += `${object.title} `;
    }
    switch (condition.condition) {
      case notificationTypes.standing:
        if (zoneTitle) {
          result += t('STANDING_FULL', { zone: zoneTitle, time: conditionDuration });
        } else {
          result += t('STANDING');
        }
        break;
      case notificationTypes.standingOut:
        if (zoneTitle) {
          result += groupTitle ? `${groupTitle} ${t('STANDING_OUT_ALERT', { zone: zoneTitle, time: conditionDuration })}`
            : t('STANDING_OUT_ALERT', { zone: zoneTitle, time: conditionDuration });
        } else {
          result += t('STANDING_OUT');
        }
        break;
      case notificationTypes.exit:
        if (zoneTitle) {
          result += t('EXIT_ALERT', { zone: zoneTitle });
        } else {
          result += t('EXIT');
        }
        break;
      case notificationTypes.entry:
        if (zoneTitle) {
          result += t('ENTRY_ALERT', { zone: zoneTitle });
        } else {
          result += t('ENTRY_FULL');
        }
        break;
      case notificationTypes.sos:
        result += 'SOS';
        break;
      case notificationTypes.fall:
        result += t('FALL');
        break;
      default:
        result += condition.condition;
        break;
    }

    return `${result} ${dateFormatted}`;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hoverNotification = (notification) => {
    if (notification.viewed) {
      return;
    }
    const newNotificationsObject = Object.values({ ...notificationsObject });
    newNotificationsObject.filter(() => notification.viewed = true);
    // try to work with localstorage - it's optional

    /* blocked because need idea, how clean cache
        const viewedNotificationsFromStorage = localStorage.getItem('viewed_notifications');
        try {
            let viewedNotifications = JSON.parse(viewedNotificationsFromStorage);
            if (!viewedNotifications) {
                viewedNotifications = {}
            }
            viewedNotifications[notification.id] = true;
            localStorage.setItem('viewed_notifications', JSON.stringify(viewedNotifications))
        } catch (error) {}
        */

    dispatch(setNotifications(newNotificationsObject));
  };

  const onClickReadAll = () => {
    const newNotificationsObject = Object.values({ ...notificationsObject })
      .map((n) => {
        n.viewed = true;
        return n;
      });
    dispatch(setNotifications(newNotificationsObject));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const appsArray = Object.keys(appsObject);
  return (
    <div className={clsx(classes.appListContainer)}>
      <Badge
        onDoubleClick={onClickReadAll}
        onClick={handleClick}
        badgeContent={unreadCount}
        max={maxbage}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...defaultProps}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card>
          <CardContent>
            <div className={classes.btnWrapper}>
              <Button
                className={classes.btn}
                color="secondary"
                type="button"
                onClick={onClickReadAll}
              >
                <DoneAllIcon />
                {t('readAll')}
              </Button>
              <Button
                className={classes.btn}
                color="secondary"
                type="button"
                onClick={handleClose}
              >
                <CloseIcon />
                {t('close')}
              </Button>
            </div>
            {notificationsArray.map((notification, index) => {
              const key = `alert${index}${1}`;
              return (

                <div
                  onMouseOver={() => hoverNotification(notification)}
                  onFocus={() => hoverNotification(notification)}
                  key={key}
                >
                  <p className={clsx(classes.text,
                    !notification.viewed
                    && classes.unviewedNotification)}
                  >
                    {formatNotification(notification)}
                  </p>
                </div>
              );
            })}
          </CardContent>
        </Card>
      </Popover>
      {currentApp.id && appsArray.length > 0
        ? (
          <Select
            className={classes.list}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentApp.id}
            onChange={handleChangeApp}
          >
            {appsArray.map((appId) => (
              <MenuItem className={classes.text} key={appId} value={appId}>
                {appsObject[appId].title}
              </MenuItem>
            ))}
          </Select>
        )
        : (
          <div>
            <p>No apps</p>
          </div>
        )}
      <Link to={profile}>
        <Avatar alt="user avatar" src={avatarUrl} className={classes.large} />
      </Link>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    avatar_url: avatarUrl,
  } = state.user;
  const {
    appsObject,
    currentApp,
  } = state.app;
  const { notificationsObject } = state.notifications;
  const { zonesObject } = state.zones;

  return {
    avatarUrl,
    appsObject,
    currentApp,
    notificationsObject,
    zonesObject,
  };
}

export default connect(mapStateToProps)(AppList);
