import {
  SET_USER,
  LOGOUT,
  FETCH_USER_FINISHED,
  FETCH_USER_IN_PROGRESS,
} from 'actions/user';

const initialState = {
  name: '',
  email: '',
  avatar_url: '',
  hash: '',
  company: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        name: action.name,
        email: action.email,
        avatar_url: action.avatar_url,
        hash: action.hash,
        company: action.company || action.company_name,
      };
    case LOGOUT:
      return {
        ...state,
        name: '',
        email: '',
        avatar_url: '',
        hash: '',
        company: '',
      };
    case FETCH_USER_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCH_USER_FINISHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default userReducer;
