import {
  SET_GROUPS,
  SET_SORT_DEVICES,
  FETCHED_GROUPS,
  SET_SELECTED_GROUP,
  FETCHE_GROUPS_IN_PROGRESS,
  SET_GROUPS_IN_SETTINGS,
  OPEN_SETTINGS_MODAL,
  CLOSE_SETTINGS_MODAL,
  SET_SETTINGS_MODAL_TITLE,
  GROUP_MODAL_FETCH_START,
  GROUP_MODAL_FETCH_END,
  SET_UPDATE_GROUP_ID,
  OPEN_DELETE_GROUP_MODAL,
  CLOSE_DELETE_GROUP_MODAL,
  SET_GROUPS_LIST_IN_SETTINGS,
} from 'actions/groups';
import { allValuesConstant, formTypeCreate } from 'constans';

const initialState = {
  sortByTitle: 'asc',
  groupsObject: {},
  selectedGroup: { id: allValuesConstant },
  isFetching: false,
  openModal: false,
  isModalLoading: false,
  formType: formTypeCreate,
  groupToDelete: {},
  openDeleteModal: false,
  groupsListInSettings: [],
  modalTitle: '',
  isFetchingSettingsList: false,
  updatedGroupId: null,
};

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        groupsObject: { ...action.groupsObject },
        isFetching: false,
      };
    case SET_SORT_DEVICES:
      return {
        ...state,
        sortByTitle: action.str,
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: { ...action.group },
        isFetching: false,
      };
    case FETCHE_GROUPS_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCHED_GROUPS:
      return { ...state, isFetching: false };
    case SET_GROUPS_IN_SETTINGS:
      return {
        ...state,
        groupsListInSettings: action.list,
        isFetchingSettingsList: false,
      };
    case OPEN_SETTINGS_MODAL:
      return {
        ...state,
        openModal: true,
        formType: action.formType,
      };
    case CLOSE_SETTINGS_MODAL:
      return { ...state, openModal: false };
    case SET_SETTINGS_MODAL_TITLE:
      return { ...state, modalTitle: action.title };
    case GROUP_MODAL_FETCH_START:
      return { ...state, isModalLoading: true };
    case GROUP_MODAL_FETCH_END:
      return { ...state, isModalLoading: false };
    case SET_UPDATE_GROUP_ID:
      return { ...state, updatedGroupId: action.id };
    case OPEN_DELETE_GROUP_MODAL:
      return { ...state, openDeleteModal: true };
    case CLOSE_DELETE_GROUP_MODAL:
      return { ...state, openDeleteModal: false };
    case SET_GROUPS_LIST_IN_SETTINGS:
      return { ...state, groupsListInSettings: action.list };
    default:
      return state;
  }
};

export default groupsReducer;
