// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const StyledSelector = withStyles((materialTheme) => ({
  root: {
    '& label': {
      fontSize: '19px',
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontFamily: materialTheme.fontRegular,
      marginLeft: '20px',
    },
    '& .MuiInputBase-root': {
      '& .MuiSelect-icon': {
        marginRight: '8px',
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingLeft: '15px',
        lineHeight: '130%',
        fontSize: '18px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        textAlign: 'left',
        fontFamily: materialTheme.fontRegular,
        width: '100%',
      },
    },
  },
}))(TextField);

export default StyledSelector;
