/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import Root from 'containers/Root';
// import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

if (window._configuration_env.SENTRY_URL) {
  Sentry.init({
    dsn: window._configuration_env.SENTRY_URL,
    environment: window._configuration_env.SENTRY_ENV,
    release: 'e7f4c88b',
    autoSessionTracking: false,
  });
}

ReactDOM.render(
  <Root />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
