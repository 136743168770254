import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import { SwipeableDrawer } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hidden from '@material-ui/core/Hidden';
// eslint-disable-next-line no-unused-vars
import { setOpenMenu } from '../../actions/app';
import useStyles from './styles';

export default function MainMenu(props) {
  const { window, openMenu, dispatch } = props;
  // eslint-disable-next-line no-unused-vars
  const { children, ...rest } = props;
  const classes = useStyles();
  // const [open, setOpen] = React.useState(openMenu);
  // const [display, setDisplay] = React.useState('block');

  const handleDrawerClose = () => {
    // setOpen(false);
    dispatch(setOpenMenu(false));
  };

  const drawerSwich = () => {
    // setOpen(!open);
    dispatch(setOpenMenu(!openMenu));
  };
  const handleDrawer = () => {
    if (!openMenu) {
      // setDisplay('none');
      // setOpen(true);
      dispatch(setOpenMenu(true));
    } else {
      // setDisplay('block');
      // setOpen(false);
      dispatch(setOpenMenu(false));
    }
  };
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>

      <nav className={classes.drawer}>
        <Hidden smUp>
          <SwipeableDrawer
            hysteresis={0.22}
            minFlingVelocity={250}
            container={container}
            variant="temporary"
            anchor="left"
            onOpen={(() => dispatch(setOpenMenu(true)))}
            open={openMenu}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {children}
            <IconButton className={classes.openCloseMenu} onClick={drawerSwich}>
              {openMenu ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </SwipeableDrawer>
        </Hidden>
        <Hidden smUp>
          <IconButton
            style={{
              zIndex: 1000,
              position: 'absolute',
              left: 0,
              borderRadius: '0 40% 40% 0',
              backgroundColor: '#f8f8f8',
              border: '1px solid rgba(0, 0, 0, 0.2)',
            }}
            onClick={drawerSwich}
          >
            {openMenu ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <IconButton className={classes.btnBigScreen} onClick={handleDrawer}>
            {openMenu ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            style={openMenu ? { display: 'none' } : { display: 'block' }}
            classes={{
              paper: classes.drawerPaperFullSize,
            }}
            variant="permanent"
            // true
          >
            {children}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
