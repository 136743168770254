/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import useStyles from '../objectReport/objectReport.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const FinalReport = ({
  objectFinalReportsArray,
}) => {
  const { t, i18n } = useTranslation(['report']);
  const dateLocale = datePickerLocales[i18n.language];
  const classes = useStyles();

  const formatTime = (time) => {
    if (time <= 0) {
      return `0 ${t('sec')}`;
    }
    const days = Math.trunc(time / 86400);
    const hours = Math.trunc(time / 3600) % 24;
    const minutes = Math.trunc(time / 60) % 60;
    const seconds = time % 60;
    return `${days > 0 ? `${days} ${t('day')}` : ''} ${hours > 0 ? `${hours} ${t('hour')}` : ''} ${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${parseFloat((seconds).toFixed(2))} ${t('sec')}` : ''}`;
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className={classes.table} style={{ maxHeight: '100%' }}>
      <Typography variant="h6">{t('finalReport')}</Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTitle}>
                {' '}
                {t('zone')}
                {' '}
              </TableCell>
              <TableCell className={classes.tableTitle} align="right">{t('entryDate')}</TableCell>
              <TableCell className={classes.tableTitle} align="right">{t('entryTime')}</TableCell>
              <TableCell className={classes.tableTitle} align="right">{t('duration')}</TableCell>
              <TableCell className={classes.tableTitle} align="right">{t('timeOfStay')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {objectFinalReportsArray.length !== 0
              ? (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t('outOfZones')}
                  </TableCell>
                  <TableCell align="right">
                    -
                  </TableCell>
                  <TableCell align="right">
                    {formatTime(outOfzonesTime.period) || 0}
                  </TableCell>
                  <TableCell align="right">
                    {`${outOfzonesTime.percents} %`}
                  </TableCell>
                </TableRow>
              ) : null} */}
            {objectFinalReportsArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (objectReport, index) => {
                  const key = index + 1;
                  const entryDate = new Date(objectReport.inside * 1000);
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {objectReport.name ? objectReport.name : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {/* eslint-disable-next-line */}
                        {format(entryDate, `dd LLL yyyy '`, { locale: dateLocale })}
                      </TableCell>
                      <TableCell align="right">
                        {format(entryDate, 'HH:mm:ss.SSS') || 'Wrong time'}
                      </TableCell>
                      <TableCell align="right">
                        {formatTime(objectReport.total) || 'Wrong time'}
                      </TableCell>
                      <TableCell align="right">
                        {`${objectReport.total_time_percent} %`}
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
        component="div"
        count={objectFinalReportsArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
        labelRowsPerPage={<div className={classes.articlePagination}>{t('labelRowsPerPage')}</div>}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
          style: { color: '#41afd7' },
          autoid: 'pagination-button-previous-collector',
        }}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
          style: { color: page === 0 ? '#b5b8c4' : '#41afd7' },
          autoid: 'pagination-button-next-collector',
        }}
      />
    </div>
  );
};

export default FinalReport;
