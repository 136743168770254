import {
  SET_MONITORING_OBEJECTS_ARRAY,
  SET_SORT_OBJECTS,
  SET_SORT_GROUP,
  SET_SORT_DEVICE,
  SET_OBJECTS_LIST_BY_APP,
  FETCH_OBJECTS_BY_APP_START,
  FETCH_OBJECTS_BY_APP_END,
  FETCH_CREATE_OBJECT_START,
  FETCH_CREATE_OBJECT_END,
  FETCH_DELETE_OBJECT_START,
  FETCH_DELETE_OBJECT_END,
  CURRENT_OBJECT_SET,
  SET_JSON,
} from 'actions/objects';

const initialState = {
  sortByTitle: 'asc',
  sortByGroup: 'asc',
  sortByDevice: 'asc',
  currentObject: 1,
  objectsInMonitoringArray: [],
  json: '',
  objectsByAppArray: [],
  isObjectsByAppFetch: false,
  isFetchingCreateObject: false,
  isFetchingDeleteObject: false,
};

const objectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MONITORING_OBEJECTS_ARRAY:
      return { ...state, objectsInMonitoringArray: action.list };
    case SET_SORT_OBJECTS:
      return {
        ...state,
        sortByTitle: action.str,
      };
    case SET_SORT_GROUP:
      return {
        ...state,
        sortByGroup: action.str,
      };
    case SET_SORT_DEVICE:
      return {
        ...state,
        sortByDevice: action.str,
      };
    case SET_JSON:
      return { ...state, json: action.list };
    case SET_OBJECTS_LIST_BY_APP:
      return { ...state, objectsByAppArray: action.list };
    case FETCH_OBJECTS_BY_APP_START:
      return { ...state, isObjectsByAppFetch: true };
    case FETCH_OBJECTS_BY_APP_END:
      return { ...state, isObjectsByAppFetch: false };
    case FETCH_CREATE_OBJECT_START:
      return { ...state, isFetchingCreateObject: true };
    case FETCH_CREATE_OBJECT_END:
      return { ...state, isFetchingCreateObject: false };
    case FETCH_DELETE_OBJECT_START:
      return { ...state, isFetchingDeleteObject: true };
    case FETCH_DELETE_OBJECT_END:
      return { ...state, isFetchingDeleteObject: false };
    case CURRENT_OBJECT_SET:
      return { ...state, currentObject: action.object };
    default:
      return state;
  }
};

export default objectsReducer;
