import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';
import BuildButton from 'components/reports/BuildButton.component';
import { fetchObjectReports, fetchFinalObjectReports, setCurrentObject } from 'actions/reports';
import { objectsByAppFetch } from 'actions/objects';
import DataPickers from 'components/reports/datePickers/datePickers.component';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import AutoComplete from '../ReportsAutoComplete';
import useStyles from './objectReport.component.style';
import containerStyles from '../../shared/Container/styles';
import FinalReport from '../finalReport/finalReport';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const ObjectReport = (props) => {
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t, i18n } = useTranslation(['report']);
  const dateLocale = datePickerLocales[i18n.language];

  const {
    objectReportsArray, dispatch,
    currentObject, currentApp,
    dateFrom,
    dateFromNoZones,
    dateTo,
    dateToNoZones,
    objectsByAppArray,
    isFetching,
    objectFinalReportsArray,
    outOfzonesTime,
    openMenu,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');
  const [currentObjectLocal, setcurrentObjectLocal] = useState('');
  const [btnExcelState, setBtnExcelState] = useState(false);
  const [btnFinalExcelState, setFinalBtnExcelState] = useState(false);
  const [btnDisabledByDate, setBtnDisabledByDate] = useState(true);

  const fetchContactReportsClick = async () => {
    setPage(0);
    await dispatch(fetchObjectReports());
    setcurrentObjectLocal(currentObject.attributes.title);
    setBtnExcelState(true);
  };
  const fetchFinalReportsClick = async () => {
    setPage(0);
    await dispatch(fetchFinalObjectReports());
    setcurrentObjectLocal(currentObject.attributes.title);
    setFinalBtnExcelState(true);
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const formatTime = (time) => {
    if (time <= 0) {
      return `< 0.1 ${t('sec')}`;
    }
    const days = Math.trunc(time / 86400);
    const hours = Math.trunc(time / 3600) % 24;
    const minutes = Math.trunc(time / 60) % 60;
    const seconds = time % 60;
    return `${days > 0 ? `${days} ${t('day')}` : ''} ${hours > 0 ? `${hours} ${t('hour')}` : ''} ${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${parseFloat((seconds).toFixed(2))} ${t('sec')}` : ''}`;
  };

  const handleDownload = () => {
    const formatReportToCsv = (str) => {
      const entryDate = new Date(str.inside_at * 1000);
      const exitDate = new Date(str.outside_at * 1000);
      const dateFormat = 'dd-MM-yyyy HH:mm:ss';
      const zoneColumn = t('zone') || 'Zone';
      const entryDateTimeColumn = t('Enter') || 'Entry Date';
      const exitDateTimeColumn = t('Exit') || 'Exit Time';
      const durationColumn = t('duration') || 'Duration';
      const nameObject = t('objectTitle') || 'Object name';

      const result = {};
      result[nameObject] = currentObjectLocal;
      if (str.zone) { result[zoneColumn] = str.zone.name; } else { result[zoneColumn] = '-'; }
      result[entryDateTimeColumn] = format(entryDate, dateFormat) || 'Wrong time';
      result[exitDateTimeColumn] = str.outside_at ? format(exitDate, dateFormat) || 'Wrong time' : t('stillZone');
      result[durationColumn] = formatTime(str.total_time) || '<0.1';

      return result;
    };

    const csvData = objectReportsArray.map((element) => formatReportToCsv(element));

    const fileName = `Detailed object report- ${currentObject.attributes.title}` || 'Detailed object report';

    exportToCSV(csvData, fileName);
  };
  const handleDownloadFinal = () => {
    const formatReportToCsv = (str) => {
      const entryDate = new Date(str.inside * 1000);
      const dateFormat = 'dd-MM-yyyy HH:mm:ss';
      const zoneColumn = t('zone') || 'Zone';
      const durationColumn = t('duration') || 'Duration';
      const entryDateTimeColumn = t('Enter') || 'Entry Date';
      const percent = t('percent') || 'Percent';
      const nameObject = t('objectTitle') || 'Object name';
      const result = {};
      result[nameObject] = currentObjectLocal;
      result[zoneColumn] = str.name;
      result[entryDateTimeColumn] = format(entryDate, dateFormat);
      result[durationColumn] = formatTime(str.total) || 'Wrong time';
      result[percent] = str.total_time_percent || 'Wrong time';

      return result;
    };

    const csvData = objectFinalReportsArray.map((element) => formatReportToCsv(element));

    const fileName = `Final object report- ${currentObject.attributes.title}` || 'Final object report';

    exportToCSV(csvData, fileName);
  };

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, page, rowsPerPage, currentApp]);

  useEffect(() => {
    setPage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, rowsPerPage, currentApp, dateFrom, dateTo]);
  useEffect(() => {
    if (new Date(Date.now()) > dateFrom && dateTo > dateFrom) {
      setBtnDisabledByDate(false);
    } else {
      setBtnDisabledByDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  const onAutocompliteInput = async (value) => {
    setObjectsFilter(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const firstTimeEntry = objectReportsArray[0] === undefined ? ' ' : `${t('timeEntry')}  ${format(new Date(objectReportsArray[0].inside_at * 1000), 'dd LLL yyyy  HH:mm:ss')} `;
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div className={classes.selectorWrapp}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
              />
            </div>
            <DataPickers />
          </form>
          <div className={classes.btnWrapper}>
            <BuildButton
              style={{ margin: '5px' }}
              variant="outlined"
              color="primary"
              disableRipple
              onClick={fetchFinalReportsClick}
              disabled={!currentObject || isFetching || btnDisabledByDate}
            >
              {t('finalReport')}
            </BuildButton>
          </div>

          <div
            className={classes.downloadControlContainer}
            onClick={handleDownloadFinal}
            onKeyUp={() => handleDownloadFinal()}
            tabIndex="0"
            role="button"
            style={btnFinalExcelState && objectFinalReportsArray.length > 0 ? { display: 'inline-flex' } : { display: 'none' }}
          >
            <div className={classes.downloadIcon} />
            <div className={classes.downLoadPhraseContainer}>
              <p className={classes.downLoadPhrase}>
                {' '}
                {t('downloadXlsFinal')}
                {' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div>
            <FinalReport
              objectFinalReportsArray={objectFinalReportsArray}
              outOfzonesTime={outOfzonesTime}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              dateFromNoZones={dateFromNoZones}
              dateToNoZones={dateToNoZones}
            />
            <div className={classes.btnWrapper}>
              <BuildButton
                style={{ margin: '5px' }}
                variant="outlined"
                color="primary"
                disableRipple
                onClick={fetchContactReportsClick}
                disabled={!currentObject || isFetching || btnDisabledByDate}
              >
                {t('detailedReport')}
              </BuildButton>
              <div
                className={classes.downloadControlContainer}
                onClick={handleDownload}
                onKeyUp={() => handleDownload()}
                tabIndex="0"
                role="button"
                style={btnExcelState && objectReportsArray.length > 0 ? { display: 'inline-flex' } : { display: 'none' }}
              >
                <div className={classes.downloadIcon} />
                <div className={classes.downLoadPhraseContainer}>
                  <p className={classes.downLoadPhrase}>
                    {' '}
                    {t('downloadXlsDetailed')}
                    {' '}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.table}>
              <Typography variant="h6">{t('detailsReport')}</Typography>
              <Typography variant="h6">{firstTimeEntry}</Typography>
              <TableContainer component={Paper}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableTitle}>
                        {' '}
                        {t('zone')}
                        {' '}
                      </TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('entryDate')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('entryTime')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('exitTime')}</TableCell>
                      <TableCell className={classes.tableTitle} align="right">{t('duration')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objectReportsArray.slice(
                      page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                    )
                      .map((
                        objectReport, index,
                      ) => {
                        const entryDate = new Date(objectReport.inside_at * 1000);
                        // eslint-disable-next-line max-len
                        const exitDate = objectReport.outside_at === null ? null : new Date(objectReport.outside_at * 1000);
                        const key = index + 1;
                        return (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              {objectReport.zone ? objectReport.zone.name : t('deletedZone')}
                            </TableCell>
                            <TableCell align="right">
                              {/* eslint-disable-next-line */}
                        {entryDate ? format(entryDate, `dd LLL yyyy '`, { locale: dateLocale }) : null}
                            </TableCell>
                            <TableCell align="right">
                              {format(entryDate, 'HH:mm:ss.SSS')}
                            </TableCell>
                            <TableCell align="right">
                              {exitDate ? format(exitDate, 'HH:mm:ss.SSS') : t('stillZone')}
                            </TableCell>
                            <TableCell align="right">
                              {formatTime(objectReport.total_time) || 'Wrong time'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
                component="div"
                count={objectReportsArray.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('moreThen')} ${to}`}`}
                labelRowsPerPage={<div className={classes.articlePagination}>{t('labelRowsPerPage')}</div>}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                  style: { color: '#41afd7' },
                  autoid: 'pagination-button-previous-collector',
                }}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                  style: { color: page === 0 ? '#b5b8c4' : '#41afd7' },
                  autoid: 'pagination-button-next-collector',
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    objectReportsArray,
    objectFinalReportsArray,
    outOfzonesTime,
    isFetching,
    dateFrom,
    dateFromNoZones,
    dateToNoZones,
    dateTo,
    currentObject,
  } = state.reports;
  const { objectsByAppArray } = state.objects;
  const { currentApp, openMenu } = state.app;
  return {
    objectReportsArray,
    objectFinalReportsArray,
    outOfzonesTime,
    isFetching,
    dateFrom,
    dateTo,
    currentObject,
    objectsByAppArray,
    currentApp,
    dateFromNoZones,
    dateToNoZones,
    openMenu,
  };
}

export default connect(mapStateToProps)(ObjectReport);
