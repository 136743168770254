import {
  SET_DEVICES_LIST,
  SET_SORT_MAC,
  SET_SORT_DEVICES,
  SET_DEVICES_MODAL_FORM,
  SET_CREATED_DEVICE,
  OPEN_SETTINGS_DEVICE_MODAL_DEVICE,
  DEVICE_MODAL_FETCH_START,
  SET_SETTINGS_MODAL_TITLE_DEVICE,
  SET_SETTINGS_MODAL_MAC,
  SET_UPDATE_DEVICE_ID,
} from 'actions/devices';

import { CLOSE_SETTINGS_MODAL, CLOSE_DELETE_GROUP_MODAL } from 'actions/groups';
import merge from 'lodash/merge';
import { formTypeCreate } from 'constans';

const initialState = {
  sortByTitle: 'asc',
  sortByMac: 'asc',
  devicesList: [],
  creatingResult: {},
  openModal: false,
  isModalLoading: false,
  formType: formTypeCreate,
  isFetchingSettingsList: false,
  updateDeviceId: null,
  openDeleteModal: false,
  modalTitle: '',
  macAddress: '',
  modal: {
    title: '',
    mac: '',
  },
};

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICES_LIST:
      return {
        ...state,
        devicesList: action.list,
        isFetchingSettingsList: false,
      };
    case SET_SORT_DEVICES:
      return {
        ...state,
        sortByTitle: action.str,
      };
    case SET_SORT_MAC:
      return {
        ...state,
        sortByMac: action.str,
      };
    case SET_CREATED_DEVICE:
      return { ...state, creatingResult: { ...action.device } };
    case SET_DEVICES_MODAL_FORM:
      return { ...state, modal: merge({}, state.modal, action.modalForm) };
    case OPEN_SETTINGS_DEVICE_MODAL_DEVICE:
      return {
        ...state,
        openModal: true,
        formType: action.formType,
      };
    case DEVICE_MODAL_FETCH_START:
      return { ...state, isModalLoading: true };
    case SET_SETTINGS_MODAL_TITLE_DEVICE:
      return { ...state, modalTitle: action.title };
    case SET_SETTINGS_MODAL_MAC:
      return { ...state, macAddress: action.macAddress };
    case CLOSE_SETTINGS_MODAL:
      return {
        ...state,
        openModal: false,
        modalTitle: initialState.modalTitle,
        macAddress: initialState.macAddress,
      };
    case CLOSE_DELETE_GROUP_MODAL:
      return { ...state, openDeleteModal: false };
    case SET_UPDATE_DEVICE_ID:
      return { ...state, updateDeviceId: action.id };
    default:
      return state;
  }
};

export default devicesReducer;
