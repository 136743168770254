export const SET_TIMER_ID = 'SET_TIMER_ID';
export const SET_TIMER_PERIOD = 'SET_TIMER_PERIOD';
export const STOP_TIMER_UPDATE = 'STOP_TIMER_UPDATE';
export const SET_OBJECT_TTL = 'SET_OBJECT_TTL';

// tracking/v1/tracked-groups?api_key=B149BA3B-A034-45B1-8879-68E92E3CC122&lang=en

export const setTimerId = (timerID) => ({
  type: SET_TIMER_ID,
  timerID,
});

export const setObjectTtl = (ttl) => ({
  type: SET_OBJECT_TTL,
  ttl,
});

export const setTimerPeriod = () => ({
  type: SET_TIMER_PERIOD,
});

export const setTimerIdToNull = () => ({
  type: STOP_TIMER_UPDATE,
});
