import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger'
import rootReducer from './reducers';

// const loggerMiddleware = createLogger();
// const middleware = [thunkMiddleware, loggerMiddleware];

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk /* loggerMiddleware  */),
  );
}
