import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  content: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  table: {
    width: '100%',
  },
  topControls: {
    marginTop: '16px',
  },
  spinerWrapper: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  controlWrapper: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  txtControl: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '280px',
    marginTop: '15px',
  },
}));

export default useStyles;
