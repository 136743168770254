/*eslint-disable*/

import React from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector'
import { noValuesConstant } from 'constans';

export default function LocationSelector(props) {
    const { className, allowAllValues, allowSelectNoValues, locations, loading, value, label, ...rest } = props;
    const { t } = useTranslation(["monitoring"]);
    const locationsArray = Object.keys(locations);
    const currentValue = locationsArray.length > 0 && value ? value : noValuesConstant
    
    return (
        <OutlinedSelector
            id="select-location"
            className={className}
            label={t("locationSelectorLabel")}
            novalueslabel={currentValue === noValuesConstant ? t("noLocations") : null}
            allowNoValues={currentValue === noValuesConstant}
            allowAllValues={allowAllValues}
            allowSelectNoValues={allowSelectNoValues}
            value={currentValue}
            onChange={props.onChange}
            {...rest}
        >
            {locationsArray.map(locationId => {
                return (
                    <MenuItem key={locationId} value={locationId}>
                        {locations[locationId].title}
                    </MenuItem>
                );
            })}
        </OutlinedSelector>
    );
}
