import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import SocialContacts from 'components/reports/socialContacts/socialContacts.component';
import ObjectReport from 'components/reports/objectReport/objectReport.component';
import AlertReport from 'components/reports/alertReport/alertReport.component';
import WorkDayPic from 'components/reports/workDayPic/workDayPic.component';
import ZoneContactsComponent from 'components/reports/zoneContacts/zoneContacts.component';
import DeviceMonitoring from 'components/reports/deviceMonitoring/deviceMonitoring.component';
import DistanceTraveled from 'components/reports/distanceTraveled/distanceTraveled.component';
import InventoryReport from 'components/reports/inventoryReport/inventoryReport.component';
import LeftMenuComponent from 'components/leftMenu/left.menu';
import { useTranslation } from 'react-i18next';
import ContactIcon from 'assets/images/reports/icons/team.svg';
import IconAlert from 'assets/images/reports/icons/iconAlert.svg';
import DeviceMon from 'assets/images/reports/icons/deviceMonitoring.svg';
import IconObject from 'assets/images/reports/icons/iconObject.svg';
import { monitoring, serviceReports } from 'constans';
import workDay from 'assets/images/reports/icons/workDayMap.svg';
import Distance from 'assets/images/reports/icons/distance.svg';
import useStyles from './reports.styles.container';

const Reports = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['translation']);
  const reportsPath = serviceReports;
  const socialContactsPath = `${reportsPath}/socialContacts`;
  const objectReportPath = `${reportsPath}/objectReport`;
  const inventoryReportPath = `${reportsPath}/inventoryReport`;
  const alertReportPath = `${reportsPath}/alertReport`;
  const zoneContactsReport = `${reportsPath}/zoneContacts`;
  const deviceMonitoring = `${reportsPath}/deviceMonitoring`;
  const distanceTraveled = `${reportsPath}/distanceTraveled`;
  const workDayPic = `${reportsPath}/workDayPic`;
  const { currentApp, openMenu, dispatch } = props;
  let menu = [
    {
      name: t('socialСontacts'),
      path: socialContactsPath,
      appKey: 'social_contacts_reports',
      component: <SocialContacts />,
      icon: {
        url: ContactIcon,
        width: '27px',
        height: '19px',
      },
    },
    {
      name: t('objectReports'),
      path: objectReportPath,
      appKey: 'object_reports',
      component: <ObjectReport />,
      icon: {
        url: IconObject,
        width: '27px',
        height: '19px',
      },
    },
    {
      name: t('inventoryReports'),
      path: inventoryReportPath,
      appKey: 'inventory_reports', // Todo: fix in api and change key here
      component: <InventoryReport />,
      icon: {
        url: IconObject,
        width: '27px',
        height: '19px',
      },
    },
    {
      name: t('alertReport'),
      path: alertReportPath,
      appKey: 'alert_reports',
      component: <AlertReport />,
      icon: {
        url: IconAlert,
        width: '27px',
        height: '22px',
      },
    },
    {
      name: t('zoneReport'),
      path: zoneContactsReport,
      appKey: 'zone_contacts_reports',
      component: <ZoneContactsComponent />,
      icon: {
        url: ContactIcon,
        width: '27px',
        height: '19px',
      },
    },
    {
      name: t('deviceMonitoring'),
      path: deviceMonitoring,
      appKey: 'monitoring_devices',
      component: <DeviceMonitoring />,
      icon: {
        url: DeviceMon,
        width: '27px',
        height: '19px',
      },
    },
    {
      name: t('distanceTraveledReport'),
      path: distanceTraveled,
      appKey: 'distance_traveled',
      component: <DistanceTraveled />,
      icon: {
        url: Distance,
        width: '27px',
        height: '25px',
      },
    },
    {
      name: t('workDayPic'),
      path: workDayPic,
      appKey: 'work_day_picture',
      component: <WorkDayPic />,
      icon: {
        url: workDay,
        width: '27px',
        height: '23px',
      },
    },
  ];
  const appParamsObject = {};

  if (currentApp.properties && currentApp.properties.length > 0) {
    currentApp.properties.forEach((element) => {
      appParamsObject[element.type] = element.value === 'true';
    });
  } else {
    appParamsObject.object_reports = true;
    appParamsObject.alert_reports = true;
  }
  //
  menu = menu.filter((element) => appParamsObject[element.appKey]);

  const redirectPath = menu[0] ? menu[0].path : monitoring;

  return (
    <div className={!openMenu ? classes.container : classes.noneContainer}>
      <LeftMenuComponent options={menu} openMenu={openMenu} dispatch={dispatch} />
      <Switch>
        <Route exact path={reportsPath}>
          <Redirect
            to={{
              pathname: redirectPath,
            }}
          />
        </Route>
        {menu.map((menuElement, index) => {
          const key = `path${index}${1}`;
          return (
            <Route key={key} path={menuElement.path}>
              {menuElement.component}
            </Route>
          );
        })}
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  const { currentApp, openMenu, dispatch } = state.app;

  return {
    currentApp,
    openMenu,
    dispatch,
  };
}

export default connect(mapStateToProps)(Reports);
