/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import request, { endPointsEnum } from 'services/http';
import { formTypeCreate, notificationTypes, timeConstants } from 'constans';
import { createAlert, alertTypes } from './alert';

export const SET_NOTIFICATIONS_ARRAY = 'SET_NOTIFICATIONS_ARRAY';
export const SET_LAST_ID = 'SET_LAST_ID';
export const SET_SORT_TITLE = 'SET_SORT_TITLE';
export const SET_SORT_LOCATIONS = 'SET_SORT_LOCATIONS';
export const SET_SORT_SUBLOCATIONS = 'SET_SORT_SUBLOCATIONS';
export const SET_SORT_ZONE = 'SET_SORT_ZONE';
export const SET_SORT_GROUP = 'SET_SORT_GROUP';

export const UPDATE_NOTIFICATIONS_PERIOD = 'UPDATE_NOTIFICATIONS_PERIOD';

export const updateNotificationsPeriod = () => ({
  type: UPDATE_NOTIFICATIONS_PERIOD,
});

export const setNotifications = (notificationsObject) => ({
  type: SET_NOTIFICATIONS_ARRAY,
  notificationsObject,
});
export const setLastId = (lastId) => ({
  type: SET_LAST_ID,
  lastId,
});

export const CONDITIONS_LOADING_START = 'CONDITIONS_LOADING_START';

export const conditionsLoadingStart = () => ({
  type: CONDITIONS_LOADING_START,
});

export const CONDITIONS_LOADING_END = 'CONDITIONS_LOADING_END';

export const conditionsLoadingEnd = () => ({
  type: CONDITIONS_LOADING_END,
});

export const SET_CONDITIONS_LIST = 'SET_CONDITIONS_LIST';

export const setConditionsList = (list, includedObject) => ({
  type: SET_CONDITIONS_LIST,
  list,
  includedObject,
});

export const OPEN_MAIN_CONDITION_MODAL = 'OPEN_MAIN_CONDITION_MODAL';

export const openMainModal = (formType) => ({
  type: OPEN_MAIN_CONDITION_MODAL,
  formType,
});

export const CLOSE_MAIN_MODAL = 'CLOSE_MAIN_MODAL';

export const closeMainModal = () => ({
  type: CLOSE_MAIN_MODAL,
});

export const OPEN_DELETE_CONDITION_MODAL = 'OPEN_DELETE_CONDITION_MODAL';

export const openDeleteConditionModal = () => ({
  type: OPEN_DELETE_CONDITION_MODAL,
});

export const CLOSE_DELETE_CONDITION_MODAL = 'CLOSE_DELETE_CONDITION_MODAL';

export const closeDeleteConditionModal = () => ({
  type: CLOSE_DELETE_CONDITION_MODAL,
});

export const CONDITION_CREATING_IN_PROGRESS = 'CONDITION_CREATING_IN_PROGRESS';

export const conditionCreatingInProgress = () => ({
  type: CONDITION_CREATING_IN_PROGRESS,
});

export const CONDITION_CREATING_END = 'CONDITION_CREATING_END';

export const conditionCreatingEnd = () => ({
  type: CONDITION_CREATING_END,
});

export const SET_CONDITION_FORM_TYPE = 'SET_CONDITION_FORM_TYPE';

export const setConditionFormType = (formType) => ({
  type: SET_CONDITION_FORM_TYPE,
  formType,
});

export const SET_EDITING_CONDITION_ID = 'SET_EDITING_CONDITION_ID';

export const setEditingConditionID = (id) => ({
  type: SET_EDITING_CONDITION_ID,
  id,
});
export const setNotificationsByTitle = (str) => ({
  type: SET_SORT_TITLE,
  str,
});
export const setNotificationsByLocations = (str) => ({
  type: SET_SORT_LOCATIONS,
  str,
});
export const setNotificationsBySublocations = (str) => ({
  type: SET_SORT_SUBLOCATIONS,
  str,
});
export const setNotificationsByZone = (str) => ({
  type: SET_SORT_ZONE,
  str,
});
export const setNotificationsByGroup = (str) => ({
  type: SET_SORT_GROUP,
  str,
});
export const sortByTitle = (filter = '', page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(conditionsLoadingStart());

    const result = await request.tracking.get(endPointsEnum.trackedConditions, {
      params: {
        'like[group.title][]': filter,
        'page[offset]': page,
        'page[limit]': limit,
        api_key: apiKey,
      },
    });
    const { sortByTitleCondition } = state.notificationsSettings;
    dispatch(conditionsLoadingEnd());
    const includedObject = {
      locations: {},
      floors: {},
      groups: {},
      zones: {},
    };
    result.included.map((field) => {
      if (field.type === 'locations') {
        includedObject.locations[field.id] = field;
      }
      if (field.type === 'sublocations') {
        includedObject.floors[field.id] = field;
      }
      if (field.type === 'tracked-groups') {
        includedObject.groups[field.id] = field;
      }
      if (field.type === 'zones') {
        includedObject.zones[field.id] = field;
      }
      return field;
    });
    if (sortByTitleCondition === 'asc') {
      result.data.sort((a, b) => a.attributes.condition.localeCompare(b.attributes.condition));
      dispatch(setNotificationsByTitle('desc'));
    } else {
      result.data.sort((a, b) => b.attributes.condition.localeCompare(a.attributes.condition));
      dispatch(setNotificationsByTitle('asc'));
    }
    dispatch(setConditionsList(result.data, includedObject));
  } catch (error) {
    console.warn(`Cannot get notifications: ${error.message}`);
  }
};
export const sortByLocations = (filter = '', page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(conditionsLoadingStart());

    const result = await request.tracking.get(endPointsEnum.trackedConditions, {
      params: {
        'like[group.title][]': filter,
        'page[offset]': page,
        'page[limit]': limit,
        api_key: apiKey,
      },
    });
    const { sortByLocation } = state.notificationsSettings;
    dispatch(conditionsLoadingEnd());
    const includedObject = {
      locations: {},
      floors: {},
      groups: {},
      zones: {},
    };
    result.included.map((field) => {
      if (field.type === 'locations') {
        includedObject.locations[field.id] = field;
      }
      if (field.type === 'sublocations') {
        includedObject.floors[field.id] = field;
      }
      if (field.type === 'tracked-groups') {
        includedObject.groups[field.id] = field;
      }
      if (field.type === 'zones') {
        includedObject.zones[field.id] = field;
      }
      return field;
    });
    if (sortByLocation === 'asc') {
      result.data.sort((a, b) => a.relationships.location.data.id
        .localeCompare(b.relationships.location.data.id));
      dispatch(setNotificationsByLocations('desc'));
    } else {
      result.data.sort((a, b) => b.relationships.location.data.id
        .localeCompare(a.relationships.location.data.id));
      dispatch(setNotificationsByLocations('asc'));
    }
    dispatch(setConditionsList(result.data, includedObject));
  } catch (error) {
    console.warn(`Cannot get notifications: ${error.message}`);
  }
};
export const sortBySublocations = (filter = '', page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(conditionsLoadingStart());

    const result = await request.tracking.get(endPointsEnum.trackedConditions, {
      params: {
        'like[group.title][]': filter,
        'page[offset]': page,
        'page[limit]': limit,
        api_key: apiKey,
      },
    });
    const { sortBySublocation } = state.notificationsSettings;
    dispatch(conditionsLoadingEnd());
    const includedObject = {
      locations: {},
      floors: {},
      groups: {},
      zones: {},
    };
    result.included.map((field) => {
      if (field.type === 'locations') {
        includedObject.locations[field.id] = field;
      }
      if (field.type === 'sublocations') {
        includedObject.floors[field.id] = field;
      }
      if (field.type === 'tracked-groups') {
        includedObject.groups[field.id] = field;
      }
      if (field.type === 'zones') {
        includedObject.zones[field.id] = field;
      }
      return field;
    });
    if (sortBySublocation === 'asc') {
      result.data.sort((a, b) => a.relationships.sublocation.data.id
        .localeCompare(b.relationships.sublocation.data.id));
      dispatch(setNotificationsBySublocations('desc'));
    } else {
      result.data.sort((a, b) => b.relationships.sublocation.data.id
        .localeCompare(a.relationships.sublocation.data.id));
      dispatch(setNotificationsBySublocations('asc'));
    }
    dispatch(setConditionsList(result.data, includedObject));
  } catch (error) {
    console.warn(`Cannot get notifications: ${error.message}`);
  }
};

export const getConditions = (filter = '', page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(conditionsLoadingStart());

    const result = await request.tracking.get(endPointsEnum.trackedConditions, {
      params: {
        'like[group.title][]': filter,
        'page[offset]': page,
        'page[limit]': limit,
        api_key: apiKey,
      },
    });
    dispatch(conditionsLoadingEnd());
    const includedObject = {
      locations: {},
      floors: {},
      groups: {},
      zones: {},
    };
    result.included.map((field) => {
      if (field.type === 'locations') {
        includedObject.locations[field.id] = field;
      }
      if (field.type === 'sublocations') {
        includedObject.floors[field.id] = field;
      }
      if (field.type === 'tracked-groups') {
        includedObject.groups[field.id] = field;
      }
      if (field.type === 'zones') {
        includedObject.zones[field.id] = field;
      }
      return field;
    });

    dispatch(setConditionsList(result.data, includedObject));
  } catch (error) {
    console.warn(`Cannot get notifications: ${error.message}`);
  }
};

export const createCondition = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      formType,
      conditionType,
      conditionTime,
      conditionTimeType,
      updatedConditionId,
    } = state.notificationsSettings;
    const { currentLocation } = state.location;
    const { api_key: apiKey } = state.app.currentApp;
    const {
      currentFloor,
    } = state.floor;
    const { selectedGroup } = state.groups;
    const { selectedZone } = state.zones;

    const body = {
      attributes: {
        condition: conditionType,
      },
      relationships: {
        'tracked-group': {
          data: {},
        },
        location: {
          data: {},
        },
        sublocation: {
          data: {},
        },
        zone: {
          data: {},
        },
      },
    };

    if (conditionType === notificationTypes.standing
                || conditionType === notificationTypes.standingOut) {
      body.attributes.time = conditionTime * timeConstants[conditionTimeType];
    }
    if (currentLocation.id) {
      body.relationships.location.data = {
        id: currentLocation.id,
        type: 'locations',
      };
    }
    if (currentFloor.id && currentFloor.id > 0) {
      body.relationships.sublocation.data = {
        id: currentFloor.id,
        type: 'sublocations',
      };
    }
    if (selectedGroup.id && selectedGroup.id > 0) {
      body.relationships['tracked-group'].data = {
        id: selectedGroup.id,
        type: 'tracked-groups',
      };
    }
    if (selectedZone.id && selectedZone.id > 0) {
      body.relationships.zone.data = {
        id: selectedZone.id,
        type: 'zones',
      };
    }
    const requestBody = { ...body, api_key: apiKey };
    dispatch(conditionCreatingInProgress());
    if (formType === formTypeCreate) {
      const result = await request.tracking.post(
        endPointsEnum.trackedConditions, { body: requestBody },
      );
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert(alertTypes.info, translate('notificationCreatedAlert')));
    } else {
      const path = `${endPointsEnum.trackedConditions}/${updatedConditionId}`;
      const result = await request.tracking.patch(path, { body: requestBody });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(setEditingConditionID(null));
      dispatch(createAlert(alertTypes.info, translate('notificationEditedAlert')));
    }
    dispatch(getConditions());
    dispatch(closeMainModal());
    dispatch(conditionCreatingEnd());
  } catch (error) {
    dispatch(conditionCreatingEnd());
    console.warn(`Error in notifications' modal action: ${error.message}`);
    dispatch(createAlert(alertTypes.err, translate('notificationMainModalError')));
  }
};

export const deleteCondition = (condition, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!condition.id || !apiKey) {
      return;
    }
    const path = `${endPointsEnum.trackedConditions}/${condition.id}`;
    const params = {
      api_key: apiKey,
    };
    dispatch(conditionCreatingInProgress());
    await request.tracking.delete(path, { params });
    dispatch(createAlert('info', translate('notificationDeleteAlert')));
    dispatch(conditionCreatingEnd());
  } catch (error) {
    dispatch(conditionCreatingEnd());
    console.warn(`Error in deleting notification: ${error.message}`);
    dispatch(createAlert('error', translate('notificationDeleteError')));
  }
};

export const SET_CONDITION_TIME = 'SET_CONDITION_TIME';

export const setConditionTime = (time) => ({
  type: SET_CONDITION_TIME,
  time,
});

export const SET_CONDITION_TIME_TYPE = 'SET_CONDITION_TIME_TYPE';

export const setConditionTimeType = (timeType) => ({
  type: SET_CONDITION_TIME_TYPE,
  timeType,
});

export const SET_CONDITION_TYPE = 'SET_CONDITION_TYPE';

export const setConditionType = (conditionType) => ({
  type: SET_CONDITION_TYPE,
  conditionType,
});

export const getNotifications = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    const {
      dateFrom, dateTo, notificationsObject, lastId,
    } = state.notifications;
    if (!apiKey || !dateFrom || !dateTo) {
      return;
    }
    const response = await request.tracking.get(endPointsEnum.trackedNotifications, {
      params: {
        from: Math.round(dateFrom.getTime() / 1000),
        to: Math.round(dateTo.getTime() / 1000),
        api_key: apiKey,
        last_id: lastId,
        destination: 'push',
      },
    });
    response.forEach((newNotification) => newNotification.viewed = false);
    if (notificationsObject.length === 0) {
      dispatch(setNotifications(response));
    } else {
      const newnotificationsObject = notificationsObject;
      response.forEach((i) => newnotificationsObject.unshift(i));
      dispatch(setNotifications(newnotificationsObject));
    }
    if (response.length !== 0) {
      dispatch(setLastId(response[0].id));
    }
  } catch (error) {
    console.warn(`Cannot get notifications: ${error.message}`);
  }
};
