import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  content: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  noneContent: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  noneContainer: {
    margin: '0',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
}));

export default useStyles;
