import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { cookieTokenName } from 'constans';

// eslint-disable-next-line import/prefer-default-export
export const isAuth = () => {
  const storedToken = Cookies.get(cookieTokenName);
  if (!storedToken) {
    return false;
  }
  const tokenPayload = jwt.decode(storedToken);
  const {
    hash: storedHash, id: storedId,
  } = tokenPayload.userData;

  return storedToken && storedHash && storedId;
};
