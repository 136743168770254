import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';

import CircularProgress from '@material-ui/core/CircularProgress';

import BuildButton from 'components/reports/BuildButton.component';
import {
  setCurrentObject,
  fetchZoneContactReports,
} from 'actions/reports';
import { objectsByAppFetch } from 'actions/objects';
import DataPickers from 'components/reports/datePickers/datePickers.component';
import DurationComponent from './DurationComponent';
import AutoComplete from '../ReportsAutoComplete';
import containerStyles from '../../shared/Container/styles';
import useStyles from '../socialContacts/socialContacts.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
  et: etLocale,
};

const ZoneContacts = (props) => {
  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t, i18n } = useTranslation(['report']);
  const {
    dispatch,
    currentObject,
    currentApp,
    objectsByAppArray,
    isFetching,
    duration,
    contactZoneReportsArray,
    openMenu,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dateLocale = datePickerLocales[i18n.language];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value, 10);
    setPage(0);
  };

  const fetchContactReportsClick = async () => {
    await dispatch(fetchZoneContactReports());
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const [objectsFilter, setObjectsFilter] = useState('');

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, currentApp]);

  const onAutocompliteInput = async (action, value) => {
    setObjectsFilter(value);
  };
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div style={{ width: '318px', marginRight: '2vw' }}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
              />
            </div>
            <DataPickers />
            <div className={classes.wrappDuration}>
              <div>{t('duration')}</div>
              <DurationComponent
                duration={duration}
                dispatch={dispatch}
              />
            </div>
          </form>
          <BuildButton
            variant="outlined"
            style={{ marginLeft: '2px' }}
            color="primary"
            disableRipple
            onClick={fetchContactReportsClick}
            disabled={!duration || isFetching || !currentObject}
          >
            {t('buildReport')}
          </BuildButton>
        </div>
      </div>

      {isFetching ? (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      ) : (
        <div className={classes.table}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <p className={classes.text}>
                      {t('objectTitle')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell>
                    <p className={classes.text}>
                      {' '}
                      {t('zoneTitle')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell>
                    <p className={classes.text}>
                      {' '}
                      {t('entryTime')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell>
                    <p className={classes.text}>
                      {' '}
                      {t('durationZoneContacts')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactZoneReportsArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contact, index) => {
                    const entryDate = new Date(contact.contact_start_time);
                    const key = index + 1;
                    return (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contact.title}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.text}>
                            {' '}
                            {contact.zone_name || 'Wront zone name'}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.text}>
                            {' '}
                            {format(entryDate, 'dd LLL yyyy HH:mm:ss', { locale: dateLocale })
                              || 'Wrong time'}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.text}>
                            {' '}
                            {contact.contact_duration_sec}
                            {' '}
                            { t('sec')}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={() => page + 1}
            page={page}
            nextIconButtonProps={{
              disabled: contactZoneReportsArray.length < rowsPerPage,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('report:labelRowsPerPage')}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    contactReportsArray,
    isFetching,
    dateFrom,
    dateTo,
    currentObject,
    duration,
    contactZoneReportsArray,
  } = state.reports;
  const { objectsByAppArray } = state.objects;
  const { currentApp, openMenu } = state.app;

  return {
    contactZoneReportsArray,
    contactReportsArray,
    isFetching,
    dateFrom,
    dateTo,
    currentObject,
    objectsByAppArray,
    currentApp,
    duration,
    openMenu,
  };
}

export default connect(mapStateToProps)(ZoneContacts);
