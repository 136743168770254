import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';

import {
  fetchDevicesList, setUpdateDeviceId, openSettingsModal, sortByTitile, sortByMac,
  deleteDevice, createDeviceModalAction, setSettingsModalDeviceTitle, setSettingsModalMac,
} from 'actions/devices';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  formTypeCreate, formTypeUpdate,
} from 'constans';
import InputComponent from 'components/settings/Input.component';
import CustomButton from 'components/settings/Button.component';
import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import CustomTextField from 'components/settings/TextFiled';
import {
  fetchGroupsInSettings, closeSettingsModal,
  openDeleteGroupModal,
  closeDeleteGroupModal,
} from 'actions/groups';

import { createAlert, alertTypes } from 'actions/alert';
import arrowStyles from 'components/shared/settings/arrow';
import containerStyles from '../../shared/Container/styles';
import useStyles from '../groups/groups.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const Beacons = (props) => {
  const {
    dispatch,
    formType,
    currentApp,
    openModal,
    openDeleteModal,
    modalTitle,
    macAddress,
    isModalLoading,
    isFetchingSettingsList,
    devicesList,
    openMenu,
    updateDeviceId,
  } = props;
  const { t, i18n } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceToDelete, setDeviceToDelete] = useState({});
  const [objectsFilter, setObjectsFilter] = useState('');
  const [isSrotByTitle, setSortByTitile] = useState('3');
  const [isSrotByMac, setSortByMac] = useState('3');
  const classesContainer = containerStyles();
  const classes = useStyles();
  const arrowClasses = arrowStyles();

  const dateLocale = datePickerLocales[i18n.language];

  const handleChangeFilter = (event) => {
    setObjectsFilter(event.target.value);
  };
  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    const getDevices = async () => {
      // something wrong with pagination in
      dispatch(fetchDevicesList(objectsFilter));
    };
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, page, rowsPerPage, currentApp]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewDevice = () => {
    dispatch(openSettingsModal(formTypeCreate));
  };

  const chechFormValidation = () => !modalTitle || !macAddress;

  const handleEditDevice = (device) => {
    try {
      dispatch(setSettingsModalDeviceTitle(device.attributes.title));
      dispatch(setSettingsModalMac(device.attributes.mac_address));
      dispatch(openSettingsModal(formTypeUpdate));
      dispatch(setUpdateDeviceId(device.id));
    } catch (error) {
      dispatch(createAlert(alertTypes.warn, 'Error in reading device properties'));
    }
  };

  const handleCloseDeviceModal = () => {
    dispatch(closeSettingsModal());
  };

  const handleMainModalActionClick = async () => {
    await dispatch(createDeviceModalAction(t));
  };

  const handleCloseDelete = () => {
    dispatch(closeDeleteGroupModal());
  };

  const handleDelete = async (object) => {
    setDeviceToDelete(object);
    dispatch(openDeleteGroupModal());
  };

  const handleDeteleConfirm = async () => {
    setPage(0);
    await dispatch(deleteDevice(deviceToDelete, t));
    await dispatch(fetchGroupsInSettings(0, rowsPerPage));
    handleCloseDelete();
  };

  const sortTitle = () => {
    if (devicesList.length !== 0) {
      dispatch(sortByTitile());
      setSortByMac('3');
    }
    if (isSrotByTitle === '3') {
      setSortByTitile('2');
    } else if (isSrotByTitle === '2') {
      setSortByTitile('1');
    } else if (isSrotByTitle === '1') {
      setSortByTitile('2');
    }
  };
  const sortMac = () => {
    if (devicesList.length !== 0) {
      dispatch(sortByMac());
      setSortByTitile('3');
    }
    if (isSrotByMac === '3') {
      setSortByMac('2');
    } else if (isSrotByMac === '2') {
      setSortByMac('1');
    } else if (isSrotByMac === '1') {
      setSortByMac('2');
    }
  };
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewDevice}
        >
          {t('New Device')}
        </CustomButtonTitle>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={objectsFilter}
          onChange={handleChangeFilter}
        />
      </div>
      {isFetchingSettingsList
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={sortTitle} className={arrowClasses.titleRow}>
                      {' '}
                      {t('title')}
                      {' '}
                      {
                        (isSrotByTitle === '1')
                        && <ArrowDropUpIcon className={arrowClasses.arrow} />
                      }
                      {
                        (isSrotByTitle === '2')
                        && <ArrowDropDownIcon className={arrowClasses.arrow} />
                      }
                      {
                        (isSrotByTitle === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell onClick={sortMac} className={arrowClasses.titleRow}>
                      {' '}
                      {t('MAC address')}
                      {' '}
                      {
                        (isSrotByMac === '1')
                        && <ArrowDropUpIcon className={arrowClasses.arrow} />
                      }
                      {
                        (isSrotByMac === '2')
                        && <ArrowDropDownIcon className={arrowClasses.arrow} />
                      }
                      {
                        (isSrotByMac === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell>
                      {' '}
                      {t('Battery')}
                      {' '}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {t('Last signal')}
                      {' '}
                    </TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devicesList.slice(
                    page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                  )
                    .map((device) => {
                      const LastDateSignal = new Date(device.attributes.last_active_time * 1000);
                      return (
                        <TableRow key={device.id}>
                          <TableCell component="th" scope="row">
                            {device.attributes.title}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {device.attributes.mac_address}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {device.attributes.battery}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {device.attributes.last_active_time === 0 ? '-' : format(LastDateSignal, 'dd LLL yyyy  HH:mm:ss', { locale: dateLocale }) || '-'}
                          </TableCell>
                          <TableCell>
                            <CustomButton onClick={() => { handleEditDevice(device); }}>
                              {' '}
                              {t('editBtn')}
                              {' '}
                            </CustomButton>
                            <CustomButton onClick={() => { handleDelete(device); }}>
                              {' '}
                              {t('deleteBtn')}
                              {' '}
                            </CustomButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={() => page + 1}
              page={page}
              nextIconButtonProps={{
                disabled: devicesList.length < rowsPerPage,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t('report:labelRowsPerPage')}
            />
            <div>
              <Dialog
                open={openDeleteModal}
                onClose={handleCloseDelete}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  {t('deleteDeviceTitle')}
                </DialogTitle>
                <DialogContent dividers>
                  <p>
                    {' '}
                    {t('deleteDeviceText')}
                  </p>
                </DialogContent>
                <DialogActions>
                  <CustomButton disabled={isModalLoading} onClick={handleCloseDelete} color="primary">
                    {t('cancelBtn')}
                  </CustomButton>
                  <CustomButton disabled={isModalLoading} onClick={handleDeteleConfirm} color="primary">
                    {isModalLoading ? <CircularProgress size={26} /> : null}
                    {t('deleteBtn')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openModal}
                onClose={handleCloseDeviceModal}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  <DialogTitle id="scroll-dialog-title">
                    {formType === formTypeCreate
                      ? t('createDeviceTitle') : t('editDeviceTitle')}
                    {formType === formTypeCreate
                      ? <></> : (
                        <div className={classes.idTitle}>
                          id
                          {' '}
                          {updateDeviceId}
                        </div>
                      )}
                  </DialogTitle>
                  <DialogContent dividers>

                    <InputComponent
                      id="standard-multiline-flexible"
                      value={modalTitle}
                      label={t('title')}
                      onChange={(event) => {
                        dispatch(setSettingsModalDeviceTitle(
                          event.target.value,
                        ));
                      }}
                    />
                    <br />
                    <InputComponent
                      id="standard-multiline-flexible"
                      value={macAddress}
                      label={t('MAC address')}
                      onChange={(event) => { dispatch(setSettingsModalMac(event.target.value)); }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <CustomButton disabled={isModalLoading} onClick={handleCloseDeviceModal} color="secondary">
                      {t('cancelBtn')}
                    </CustomButton>
                    <CustomButton type="submit" disabled={chechFormValidation()} onClick={handleMainModalActionClick} color="primary">
                      {isModalLoading ? <CircularProgress size={26} /> : null}
                      {formType === formTypeCreate ? t('createDeviceButton') : t('saveBtn')}
                    </CustomButton>
                  </DialogActions>
                </form>
              </Dialog>
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    openDeleteModal,
  } = state.groups;

  const { currentApp, openMenu } = state.app;
  const {
    devicesList, openModal, modalTitle, macAddress,
    formType, isFetchingSettingsList, isModalLoading,
    updateDeviceId,
  } = state.devices;
  return {
    formType,
    isModalLoading,
    isFetchingSettingsList,
    openDeleteModal,
    modalTitle,
    macAddress,
    openModal,
    currentApp,
    devicesList,
    openMenu,
    updateDeviceId,
  };
}

export default connect(mapStateToProps)(Beacons);
