import faviconImage from 'assets/images/favicons/whiteLabel/favicon.png';

const favicon = document.getElementById('favicon');

favicon.href = faviconImage;

const theme = {
  palette: {
    primary: {
      main: '#41afd7',
    },
  },
};

export default theme;
