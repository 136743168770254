import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { arrowDown, arrowUp } from 'constans';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { format, fromUnixTime } from 'date-fns';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import CircularProgress from '@material-ui/core/CircularProgress';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import BuildButton from 'components/reports/BuildButton.component';
import {
  fetchDeviceMonitoring,
  sortByDateDeviceMonitoring,
  sortByNameDeviceMonitoring,
  filterByNameDeviceMonitoring,
  sortByMACaddressDeviceMonitoring,
  setReportDate,
  sortByStatusDeviceMonitoring,
} from 'actions/reports';
import containerStyles from '../../shared/Container/styles';
import useStyles from '../socialContacts/socialContacts.component.style';

const datePickerLocales = {
  ru: ruLocale,
  en: enLocale,
};

const DeviceMonitoring = (props) => {
  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t, i18n } = useTranslation(['report']);
  const {
    dispatch,
    isFetching,
    devicesMonitoringArray,
    filterDevicesMonitoringArray,
    arrowMac,
    arrowName,
    arrowDate,
    arrowStatus,
    getReportDate,
    openMenu,
  } = props;

  const fetchContactReportsClick = async () => {
    const dateNow = new Date().getTime();
    await dispatch(setReportDate(dateNow));
    await dispatch(fetchDeviceMonitoring());
  };

  const dateLocale = datePickerLocales[i18n.language];

  const minToCompare = 15 * 60 * 1000;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortByNames = () => {
    if (devicesMonitoringArray.length !== 0) {
      dispatch(sortByNameDeviceMonitoring());
    }
  };
  const sortByDate = () => {
    if (devicesMonitoringArray.length !== 0) dispatch(sortByDateDeviceMonitoring());
  };
  const sortByStatus = () => {
    if (devicesMonitoringArray.length !== 0) dispatch(sortByStatusDeviceMonitoring());
  };
  const sortByMACaddress = () => {
    if (devicesMonitoringArray.length !== 0) dispatch(sortByMACaddressDeviceMonitoring());
  };

  const filterByName = (event) => {
    const { value } = event.target;
    if (devicesMonitoringArray.length !== 0) {
      dispatch(filterByNameDeviceMonitoring(value));
    }
  };
  const offlineArr = devicesMonitoringArray.filter(
    (el) => !el.updated_at
      || (getReportDate - fromUnixTime(el.updated_at).getTime() > minToCompare),
  );
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls} style={{ justifyContent: 'start' }}>
        <BuildButton
          variant="outlined"
          color="primary"
          disableRipple
          onClick={fetchContactReportsClick}
        >
          {t('buildReport')}
        </BuildButton>
        <div>
          <TextField
            className={classes.filterInput}
            onChange={filterByName}
            disabled={devicesMonitoringArray.length === 0}
            placeholder={t('objectTitle')}
          />
        </div>
      </div>
      {isFetching ? (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      ) : (
        <div className={classes.table}>
          {devicesMonitoringArray.length !== 0 ? (
            <Typography variant="h6">
              {t('quantityOfObjects')}
              :
              {devicesMonitoringArray.length}
              {' '}
              &nbsp;
              {t('online')}
              /
              {t('offline')}
              :
              {' '}
              {devicesMonitoringArray.length - offlineArr.length}
              /
              {offlineArr.length}
            </Typography>
          ) : null}

          <TableContainer component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell onClick={sortByNames}>
                    {' '}
                    {arrowName ? (
                      (arrowName === arrowUp
                       && <ArrowDropUpIcon className={classes.arrow} />
                      )
                       || (arrowName === arrowDown
                       && <ArrowDropDownIcon className={classes.arrow} />
                       )
                    ) : null}
                    <p className={classes.textDevice}>
                      {' '}
                      {t('locatorsName')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell onClick={sortByMACaddress}>
                    {arrowMac ? (
                      (arrowMac === arrowUp
                       && <ArrowDropUpIcon className={classes.arrow} />
                      )
                       || (arrowMac === arrowDown
                       && <ArrowDropDownIcon className={classes.arrow} />
                       )
                    ) : null}
                    <p className={classes.textDevice}>
                      {' '}
                      {t('MAC address')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell onClick={sortByDate}>
                    {arrowDate ? (
                      (arrowDate === arrowUp
                       && <ArrowDropUpIcon className={classes.arrowD} />
                      )
                       || (arrowDate === arrowDown
                       && <ArrowDropDownIcon className={classes.arrowD} />
                       )
                    ) : null}
                    <p className={classes.textDevice}>
                      {' '}
                      {t('lastContactTime')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                  <TableCell onClick={sortByStatus}>
                    {arrowStatus ? (
                      (arrowStatus === arrowUp
                       && <ArrowDropUpIcon className={classes.arrowD} />
                      )
                       || (arrowStatus === arrowDown
                       && <ArrowDropDownIcon className={classes.arrowD} />
                       )
                    ) : null}
                    <p
                      className={classes.textDevice}
                    >
                      {' '}
                      {t('status')}
                      {' '}
                    </p>
                    {' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterDevicesMonitoringArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contactReport, index) => {
                    const entryDate = !!contactReport.updated_at
                    && fromUnixTime(contactReport.updated_at);
                    const isRedRow = entryDate
                      ? (getReportDate - entryDate.getTime() > minToCompare) : true;
                    const key = index + contactReport.name;
                    return (
                      <TableRow
                        style={isRedRow ? { backgroundColor: '#ef3636' } : {}}
                        key={key}
                      >
                        <TableCell component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contactReport.name}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contactReport.uuid}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell>
                          {entryDate ? (format(entryDate, 'dd LLL yyyy  HH:mm:ss', {
                            locale: dateLocale,
                          })) : '-'}
                        </TableCell>
                        {isRedRow ? (
                          <TableCell>{t('offline')}</TableCell>
                        ) : (
                          <TableCell>{t('online')}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filterDevicesMonitoringArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${
              count !== -1 ? count : `${t('moreThen')} ${to}`
            }`}
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    devicesMonitoringArray,
    contactReportsArray,
    isFetching,
    dateFrom,
    dateTo,
    arrowDate,
    arrowName,
    arrowMac,
    filterDevicesMonitoringArray,
    getReportDate,
  } = state.reports;
  const { currentApp, openMenu } = state.app;

  return {
    contactReportsArray,
    devicesMonitoringArray,
    filterDevicesMonitoringArray,
    isFetching,
    dateFrom,
    dateTo,
    currentApp,
    arrowDate,
    arrowName,
    arrowMac,
    getReportDate,
    openMenu,
  };
}

export default connect(mapStateToProps)(DeviceMonitoring);
