import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  const { fontRegular } = materialTheme;

  return {
    leftSelector: {
      width: '100%',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '140px',
    },
    LeftLink: {
      height: '62px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.MainLeftMargin,
      borderBottom: '1px  solid #e2e6ec',
    },
    icon: {
      width: '27px',
      height: '19px',
    },
    textDiv: {
      marginLeft: '17px',
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontFamily: fontRegular,
      fontSize: '15px',
      fontWeight: 400,
      textDecoration: 'none',
    },
    listLinkActive: {
      color: materialTheme.palette.primary.main,
    },
  };
});

export default useStyles;
