import {
  SET_ZONES,
  SET_SELECTED_ZONE,
  SET_SELECTED_ZONE_TYPE,
  FETCHED_ZONES,
  FETCHE_ZONES_IN_PROGRESS,
  FETCHED_ZONES_TYPES,
} from 'actions/zones';
import { selectNoOneConstant } from 'constans';

const initialState = {
  zonesObject: {},
  selectedZone: selectNoOneConstant,
  isFetching: false,
  zoneTypes: {},
  selectedZoneType: {},
};

const zonesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ZONES:
      return {
        ...state,
        zonesObject: { ...action.zonesObject },
        isFetching: false,
        selectedZone: selectNoOneConstant,
      };
    case SET_SELECTED_ZONE:
      return { ...state, selectedZone: { ...action.zone } };
    case FETCHE_ZONES_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCHED_ZONES:
      return { ...state, isFetching: false };
    case SET_SELECTED_ZONE_TYPE:
      return { ...state, selectedZoneType: action.zoneType };
    case FETCHED_ZONES_TYPES:
      return {
        ...state,
        zoneTypes: { ...action.zoneTypes },
        isFetching: false,
      };
    default:
      return state;
  }
};

export default zonesReducer;
