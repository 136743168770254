import {
  FETCHED_LOCATIONS,
  FETCH_LOCATIONS_IN_PROGRESS,
  SET_LOCATIONS_LIST,
  SET_CURRENT_LOCATION,
} from 'actions/locations';

const initialState = {
  locationsObject: {},
  currentLocation: {},
  isFetching: false,
};

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATIONS_LIST:
      return {
        ...state,
        locationsObject: action.locationsObject,
        isFetching: false,
      };
    case FETCH_LOCATIONS_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCHED_LOCATIONS:
      return { ...state, isFetching: false };
    case SET_CURRENT_LOCATION:
      return {
        ...state, currentLocation: { ...action.location },
        // copying object without links to old object
      };
    default:
      return state;
  }
};

export default locationsReducer;
