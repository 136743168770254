import { makeStyles } from '@material-ui/core/styles';

const arrowStyle = makeStyles((materialTheme) => ({
  titleRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrow: {
    position: 'absolute',
    top: '15px',
    [materialTheme.breakpoints.down('1100')]: {
      top: '27px',
    },
    [materialTheme.breakpoints.down('980')]: {
      top: '40px',
    },
    [materialTheme.breakpoints.down('800')]: {
      top: '40px',
    },
  },
}));

export default arrowStyle;
