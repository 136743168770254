import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import CircularProgress from '@material-ui/core/CircularProgress';

import BuildButton from 'components/reports/BuildButton.component';
import { fetchContactReports, setCurrentObject } from 'actions/reports';
import { objectsByAppFetch } from 'actions/objects';
import DataPickers from 'components/reports/datePickers/datePickers.component';
import AutoComplete from '../ReportsAutoComplete';
import containerStyles from '../../shared/Container/styles';
import useStyles from './socialContacts.component.style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const SocialContacts = (props) => {
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const [btnExcelState, setBtnExcelState] = useState(false);

  const classesContainer = containerStyles();
  const classes = useStyles();
  const { t } = useTranslation(['report']);
  const {
    contactReportsArray, dispatch,
    currentObject, currentApp, objectsByAppArray,
    isFetching, openMenu,
  } = props;

  const fetchContactReportsClick = async () => {
    await dispatch(fetchContactReports());
    setBtnExcelState(true);
  };

  const downloadClick = () => {
    const formatContactToCsv = (str) => {
      const contacNameColumn = t('contactName') || 'Contact name';
      const contacTimeColumn = t('contactTime') || 'Contact time';

      const result = {};
      result[contacNameColumn] = str.name;
      // eslint-disable-next-line no-use-before-define
      result[contacTimeColumn] = formatContactTime(str.time) || 'Wrong time';

      return result;
    };

    const csvData = contactReportsArray.map((element) => formatContactToCsv(element));

    const fileName = 'test report';

    exportToCSV(csvData, fileName);
  };

  const selectObject = (event, object) => {
    dispatch(setCurrentObject(object));
  };

  const formatContactTime = (time) => {
    if (time <= 0) {
      return `0 ${t('sec')}`;
    }
    const minutes = Math.trunc(time / 60);
    const seconds = time % 60;

    return `${minutes > 0 ? `${minutes} ${t('min')}` : ''} ${seconds > 0 ? `${seconds} ${t('sec')}` : ''}`;
  };

  const [totalContacts, setTotalContacts] = useState(0);
  const [totalTimeContacts, setTotalContactsTime] = useState(0);
  const [objectsFilter, setObjectsFilter] = useState('');

  useEffect(() => {
    const getObjects = async () => {
      await dispatch(objectsByAppFetch(objectsFilter));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, currentApp]);

  const onAutocompliteInput = async (action, value) => {
    setObjectsFilter(value);
  };

  useEffect(() => {
    const totalContactsCounter = contactReportsArray.length;
    let totalContactsTimeCounter = 0;

    contactReportsArray.map((contactReport) => {
      totalContactsTimeCounter += contactReport.time;

      return contactReport;
    });
    setTotalContacts(totalContactsCounter);
    setTotalContactsTime(totalContactsTimeCounter);
  }, [contactReportsArray]);

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <div className={classes.formControls}>
          <form className={classes.formControls} noValidate>
            <div style={{ width: '318px', marginRight: '2vw' }}>
              <AutoComplete
                filteredObjectsArray={objectsByAppArray}
                currentObject={currentObject}
                selectObject={selectObject}
                onInputChange={onAutocompliteInput}
              />
            </div>
            <DataPickers />
          </form>
          <BuildButton
            className={classes.btn}
            variant="outlined"
            color="primary"
            disableRipple
            onClick={fetchContactReportsClick}
            disabled={!currentObject || isFetching}
          >
            {t('buildReport')}
          </BuildButton>
        </div>
        <div
          onClick={downloadClick}
          className={classes.downloadControlContainer}
          onKeyUp={() => downloadClick()}
          tabIndex="0"
          role="button"
          style={btnExcelState && contactReportsArray.length > 0 ? { display: 'inline-flex' } : { display: 'none' }}
        >
          <div className={classes.downloadIcon} />
          <div className={classes.downLoadPhraseContainer}>
            <p className={classes.downLoadPhrase}>
              {' '}
              {t('downloadXls')}
              {' '}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.totalNumbers}>
        <div className={classes.timeIcon}> </div>
        <div className={classes.totalNumbersItem}>
          {' '}
          <p className={clsx(classes.totalNumbersItemText, classes.text)}>
            {' '}
            {t('totalContacts')}
            :
            {' '}
            {totalContacts}
            {' '}
          </p>
          {' '}
        </div>
        <div className={classes.totalNumbersItem}>
          {' '}
          <p className={classes.text}>
            {' '}
            {t('totalContactsTime')}
            :
            {' '}
            {formatContactTime(totalTimeContacts) || '0'}
            {' '}
          </p>
          {' '}
        </div>
      </div>
      {isFetching
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <p className={classes.text}>
                        {' '}
                        {t('contactName')}
                        {' '}
                      </p>
                      {' '}
                    </TableCell>
                    <TableCell align="right">
                      <p className={classes.text}>
                        {' '}
                        {t('contactTime')}
                        {' '}
                      </p>
                      {' '}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactReportsArray && contactReportsArray.map((contactReport, index) => {
                    const key = index + 1;
                    return (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          <p className={classes.text}>
                            {' '}
                            {contactReport.name}
                            {' '}
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p className={classes.text}>
                            {' '}
                            {formatContactTime(contactReport.time) || 'Wrong time'}
                            {' '}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    contactReportsArray,
    isFetching,
    dateFrom,
    dateTo,
    currentObject,
  } = state.reports;
  const { objectsByAppArray } = state.objects;
  const { currentApp, openMenu } = state.app;

  return {
    contactReportsArray,
    isFetching,
    dateFrom,
    dateTo,
    currentObject,
    objectsByAppArray,
    currentApp,
    openMenu,
  };
}

export default connect(mapStateToProps)(SocialContacts);
