import faviconImage from 'assets/images/favicons/platform/favicon.png';
import Image from 'assets/images/welcomeLogo/platform/platform.png';

const favicon = document.getElementById('favicon');

favicon.href = faviconImage;

const theme = {
  CustomStyles: {
    LogoImageBackground: `url(${Image}) center no-repeat`,
  },
};

export default theme;
