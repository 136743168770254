import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CustomSelector = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    root: {
      '& label': {
        fontSize: '19px',
        color: materialTheme.CustomStyles.GlobalTextColor,
        fontFamily: fontRegular,
        marginLeft: '20px',
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          marginRight: '8px',
        },
        '& .MuiSelect-select.MuiSelect-select': {
          marginLeft: '20px',
          paddingRight: '15px',
          marginTop: '8px',
          fontSize: '18px',
          color: materialTheme.CustomStyles.GlobalTextColor,
          textAlign: 'left',
          fontFamily: fontRegular,
          width: '100%',
        },
        '& .MuiInputBase-input': {
          marginLeft: '20px',
        },
      },
    },

  };
})(TextField);

export default CustomSelector;
