import {
  SET_SORT_ZONE,
  SET_CONDITIONS_LIST,
  SET_SORT_TITLE,
  SET_SORT_LOCATIONS,
  SET_SORT_SUBLOCATIONS,
  SET_SORT_GROUP,
  CONDITIONS_LOADING_START,
  CONDITIONS_LOADING_END,
  OPEN_MAIN_CONDITION_MODAL,
  CLOSE_MAIN_MODAL,
  OPEN_DELETE_CONDITION_MODAL,
  CLOSE_DELETE_CONDITION_MODAL,
  SET_CONDITION_TYPE,
  SET_CONDITION_TIME,
  SET_CONDITION_TIME_TYPE,
  CONDITION_CREATING_IN_PROGRESS,
  CONDITION_CREATING_END,
  SET_EDITING_CONDITION_ID,
} from 'actions/notifications';
import { formTypeCreate, notificationTypes, timeTypes } from 'constans';
import merge from 'lodash/merge';

const initialState = {
  sortByTitleCondition: 'asc',
  sortByLocation: 'asc',
  sortBySublocation: 'asc',
  sortByZone: 'asc',
  sortByGroup: 'asc',
  conditionsArray: [],
  isConditionsLoading: false,
  includedObject: {
    locations: {},
    floors: {},
    groups: {},
    zones: {},
  },
  updatedConditionId: null,
  conditionFilter: '',
  isFetchingCreateCondition: false,
  openModal: false,
  conditionToDelete: {},
  openDeleteModal: false,
  formType: formTypeCreate,
  conditionType: notificationTypes.entry,
  conditionTime: 60,
  conditionTimeType: timeTypes.seconds,
  isConditionCreating: false,
};

const notificationsSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONDITIONS_LIST:
      return {
        ...state,
        conditionsArray: action.list,
        includedObject: merge({}, action.includedObject),
      };
    case SET_SORT_TITLE:
      return {
        ...state,
        sortByTitleCondition: action.str,
      };
    case SET_SORT_LOCATIONS:
      return {
        ...state,
        sortByLocation: action.str,
      };
    case SET_SORT_SUBLOCATIONS:
      return {
        ...state,
        sortBySublocation: action.str,
      };
    case SET_SORT_ZONE:
      return {
        ...state,
        sortByZone: action.str,
      };
    case SET_SORT_GROUP:
      return {
        ...state,
        sortByGroup: action.str,
      };
    case CONDITIONS_LOADING_START:
      return { ...state, isConditionsLoading: true };
    case CONDITIONS_LOADING_END:
      return { ...state, isConditionsLoading: false };
    case OPEN_MAIN_CONDITION_MODAL:
      return {
        ...state,
        openModal: true,
        formType: action.formType,
      };
    case CLOSE_MAIN_MODAL:
      return { ...state, openModal: false };
    case OPEN_DELETE_CONDITION_MODAL:
      return { ...state, openDeleteModal: true };
    case CLOSE_DELETE_CONDITION_MODAL:
      return { ...state, openDeleteModal: false };
    case SET_CONDITION_TYPE:
      return { ...state, conditionType: action.conditionType };
    case SET_CONDITION_TIME:
      return { ...state, conditionTime: action.time };
    case SET_CONDITION_TIME_TYPE:
      return { ...state, conditionTimeType: action.timeType };
    case CONDITION_CREATING_IN_PROGRESS:
      return { ...state, isConditionCreating: true };
    case CONDITION_CREATING_END:
      return { ...state, isConditionCreating: false };
    case SET_EDITING_CONDITION_ID:
      return { ...state, updatedConditionId: action.id };
    default:
      return state;
  }
};

export default notificationsSettingsReducer;
