import checkObjectInWindowConfig from 'helpers/checkEnv';

export const staticTypeId = 2;
export const dynamicTypeId = 1;

export const oldDevice = 1;
export const newDevice = 2;

export const allValuesConstant = '-1';
export const selectNoOneConstant = '-2';
export const noValuesConstant = '-3';

export const formTypeCreate = 1;
export const formTypeUpdate = 2;

export const cameraPropertyTypeId = 4; // temporary unused, delete until 01.10.21
export const iconImagePropertyTypeId = 3; // temporary unused, delete until 01.10.21
export const externalJsonPropertyTypeId = 2; // temporary unused, delete until 01.10.21

export const noArrow = 3;
export const arrowDown = 2;
export const arrowUp = 1;

export const monitoring = '/monitoring';
export const history = '/history';
export const serviceReports = '/service-reports';
export const settings = '/settings';
export const profile = '/profile';
export const demo3d = '/demo3d';

export const token = 'pk.eyJ1IjoiZHNlcmdlZXYiLCJhIjoiY2twM3o4d3lhMGcxeDJ3cjJ5Z25vcTVndyJ9.YFqj96mNp9Dv5c8qP06fAQ';
export const styleUrl = 'mapbox://styles/mapbox/streets-v11';

export const timeTypes = {
  seconds: 1,
  minutes: 2,
  hours: 3,
  days: 4,
  weeks: 5,
};

export const timeConstants = {
  1: 1,
  2: 60,
  3: 3600,
  4: 86400,
  5: 604800,
};

export const notificationTypes = {
  standing: 'STANDING',
  standingOut: 'STANDING_OUT',
  exit: 'EXIT',
  entry: 'ENTRY',
  sos: 'SOS',
  fall: 'Падение',
  custom: 'Другое',
};

export const roles = {
  owner: 'owner',
  watcher: 'watcher',
  editor: 'editor',
};

export const domainEnv = 'DOMAIN';
export const cookieTokenName = 'navtok';
export const cookieRefTokenName = 'navreftok';

export const cookOpts = {
  expires: 30,
  path: '/',
  domain: checkObjectInWindowConfig(domainEnv) || undefined,
  // eslint-disable-next-line no-restricted-globals
};
