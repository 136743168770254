import React, { useEffect } from 'react';
import {
  Route, Redirect, BrowserRouter, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { removeAlert } from 'actions/alert';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
  serviceReports, history, monitoring, roles, profile, settings, demo3d,
} from 'constans';

import Login from 'containers/login/login.container';
import Monitoring from 'containers/monitoring/monitoring.container';
import Settings from 'containers/settings/settings.container';
import Profile from 'containers/profile/profile.container';
import History from 'containers/history/history.container';
import Reports from 'containers/reports/reports.container';
import Demo3d from 'containers/3d/3d.container';

import PrivateRoute from './containers/PrivateRoute';
import './App.css';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App(props) {
  const {
    dispatch, currentApp, alertOpen, timerAlert,
    alertTitle, alertMessage, alertType,
  } = props;
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(removeAlert());
  };

  const accessCheck = (app) => {
    const { role } = app;
    return role !== roles.watcher;
  };

  useEffect(() => {
    if (alertOpen === true) {
      setTimeout(() => {
        dispatch(removeAlert());
      }, timerAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertOpen, timerAlert]);

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Redirect
              to={{
                pathname: monitoring,
              }}
            />
          </Route>
          <PrivateRoute path={monitoring}>
            <Monitoring />
          </PrivateRoute>
          <PrivateRoute path={history}>
            <History />
          </PrivateRoute>
          <PrivateRoute path={serviceReports}>
            <Reports />
          </PrivateRoute>
          <PrivateRoute path={demo3d}>
            <Demo3d />
          </PrivateRoute>
          <PrivateRoute path={profile}>
            <Profile />
          </PrivateRoute>
          {accessCheck(currentApp)
            ? (
              <PrivateRoute path={settings}>
                <Settings />
              </PrivateRoute>
            )
            : null}
          <Route path="/login">
            <Login />
          </Route>
          <Redirect
            to={{
              pathname: monitoring,
            }}
          />
        </Switch>
        <Snackbar open={alertOpen}>
          <Alert onClose={handleCloseAlert} severity={alertType || 'info'}>
            {alertTitle ? (
              <AlertTitle>
                {alertTitle.toString()}
                {' '}
              </AlertTitle>
            ) : null}
            {alertMessage.toString()}
          </Alert>
        </Snackbar>
      </div>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  const {
    message: alertMessage,
    title: alertTitle,
    type: alertType,
    open: alertOpen,
    timerAlert,
  } = state.alert;
  const { currentApp } = state.app;

  return {
    alertMessage,
    alertTitle,
    alertType,
    alertOpen,
    timerAlert,
    currentApp,
  };
}

export default connect(mapStateToProps)(App);
