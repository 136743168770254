/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import request, { endPointsEnum } from 'services/http';
import { formTypeCreate } from 'constans';
import { closeSettingsModal } from 'actions/groups';
import { createAlert, alertTypes } from './alert';

export const SET_DEVICES_LIST = 'SET_DEVICES_LIST';
export const SET_SORT_DEVICES = 'SET_SORT_DEVICES';
export const SET_SORT_MAC = 'SET_SORT_MAC';

export const CREATE_DEVICE_IN_PROGRESS = 'CREATE_DEVICE_IN_PROGRESS';
export const CREATE_DEVICE_FINISHED = 'CREATE_DEVICE_FINISHED';

export const SET_DEVICES_MODAL_FORM = 'SET_DEVICES_MODAL_FORM';
export const SET_CREATED_DEVICE = 'SET_CREATED_DEVICE';

export const setDevicesModalForm = (modalForm) => ({
  type: SET_DEVICES_MODAL_FORM,
  modalForm,
});

export const OPEN_SETTINGS_DEVICE_MODAL_DEVICE = 'OPEN_SETTINGS_DEVICE_MODAL_DEVICE ';

export const openSettingsModal = (formType) => ({
  type: OPEN_SETTINGS_DEVICE_MODAL_DEVICE,
  formType,
});
export const SET_SETTINGS_MODAL_TITLE_DEVICE = 'SET_SETTINGS_MODAL_TITLE_DEVICE';

export const setSettingsModalDeviceTitle = (title) => ({
  type: SET_SETTINGS_MODAL_TITLE_DEVICE,
  title,
});

export const SET_SETTINGS_MODAL_MAC = 'SET_SETTINGS_MODAL_MAC';

export const setSettingsModalMac = (macAddress) => ({
  type: SET_SETTINGS_MODAL_MAC,
  macAddress,
});
export const SET_UPDATE_DEVICE_ID = 'SET_UPDATE_DEVICE_ID';

export const setUpdateDeviceId = (id) => ({
  type: SET_UPDATE_DEVICE_ID,
  id,
});

export const DEVICE_MODAL_FETCH_START = 'DEVICE_MODAL_FETCH_START';

export const deviceModalFetchStart = () => ({
  type: DEVICE_MODAL_FETCH_START,
});

export const setCreatedDevice = (device) => ({
  type: SET_CREATED_DEVICE,
  device,
});

export const createdDeviceInProgress = () => ({
  type: CREATE_DEVICE_IN_PROGRESS,
});

export const createDeviceFinished = () => ({
  type: CREATE_DEVICE_FINISHED,
});

export const setDevicesList = (list) => ({
  type: SET_DEVICES_LIST,
  list,
});
export const setSortDevicesTitle = (str) => ({
  type: SET_SORT_DEVICES,
  str,
});
export const setSortDevicesMac = (str) => ({
  type: SET_SORT_MAC,
  str,
});

export const sortByTitile = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const params = {
      api_key: state.app.currentApp.api_key,
    };
    const { sortByTitle } = state.devices;
    const response = await request.tracking.get(endPointsEnum.trackedBeacons, { params });
    if (!response.data) {
      throw new Error('Error in response');
    }
    if (sortByTitle === 'asc') {
      response.data.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
      dispatch(setSortDevicesTitle('desc'));
    } else {
      response.data.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
      dispatch(setSortDevicesTitle('asc'));
    }
    dispatch(setDevicesList(response.data));
  } catch (error) {
    dispatch(createAlert('error', error.message));
  }
};
export const sortByMac = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const params = {
      api_key: state.app.currentApp.api_key,
    };
    const { sortByTitle } = state.devices;
    const response = await request.tracking.get(endPointsEnum.trackedBeacons, { params });
    if (!response.data) {
      throw new Error('Error in response');
    }
    if (sortByTitle === 'asc') {
      response.data.sort((a, b) => a.attributes.mac_address.localeCompare(b.attributes.mac_address));
      dispatch(setSortDevicesTitle('desc'));
    } else {
      response.data.sort((a, b) => b.attributes.mac_address.localeCompare(a.attributes.mac_address));
      dispatch(setSortDevicesTitle('asc'));
    }
    dispatch(setDevicesList(response.data));
  } catch (error) {
    dispatch(createAlert('error', error.message));
  }
};
export const fetchDevicesList = (deviceFilter = null) => async (dispatch, getState) => {
  try {
    const state = getState();

    const params = {
      api_key: state.app.currentApp.api_key,
    };

    const response = await request.tracking.get(endPointsEnum.trackedBeacons, { params });
    if (!response.data) {
      throw new Error('Error in response');
    }
    if (deviceFilter) {
      // eslint-disable-next-line max-len
      const filteredDevices = response.data.filter((i) => i.attributes.title.toLowerCase().includes(deviceFilter.toLowerCase())
      || i.attributes.mac_address.toLowerCase().includes(deviceFilter.toLowerCase()));
      dispatch(setDevicesList(filteredDevices));
    } else {
      dispatch(setDevicesList(response.data));
    }
  } catch (error) {
    dispatch(createAlert('error', error.message));
  }
};
export const createDevice = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    dispatch(createdDeviceInProgress());
    const form = state.devices.modal;
    const body = {
      attributes: {
        title: form.title,
        mac_address: form.mac,
      },
      api_key: apiKey,
    };

    const result = await request.tracking.post(endPointsEnum.trackedBeacons, { body });

    dispatch(createDeviceFinished());
    if (!result.data) {
      throw new Error('Error in request');
    }
    dispatch(setCreatedDevice(result.data[0]));
    dispatch(setDevicesModalForm({
      title: '',
      mac: '',
    }));

    dispatch(createAlert('info', 'Device Created'));
    dispatch(createDeviceFinished());
    // eslint-disable-next-line consistent-return
    return result.data[0];
  } catch (error) {
    dispatch(createDeviceFinished());
    dispatch(createAlert('error', error.message));
  }
};
export const deleteDevice = (device, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!device.id || !apiKey) {
      return;
    }
    const path = `${endPointsEnum.trackedBeacons}/${device.id}`;
    const params = {
      api_key: apiKey,
    };
    await request.tracking.delete(path, { params });
    dispatch(createAlert('info', translate('deviceDeletedAlert')));
    await dispatch(fetchDevicesList());
  } catch (error) {
    console.warn(`Error in deleting device: ${error.message}`);
    dispatch(createAlert('error', translate('groupDeleteError')));
  }
};

export const createDeviceModalAction = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    const {
      formType,
      modalTitle,
      macAddress,
      updateDeviceId,
    } = state.devices;
    const body = {
      attributes: {
        title: modalTitle,
        mac_address: macAddress,
      },
      api_key: apiKey,
    };
    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedBeacons, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert('info', translate('pushDeviceCreated')));
      dispatch(fetchDevicesList());
      dispatch(closeSettingsModal());
    } else {
      const path = `${endPointsEnum.trackedBeacons}/${updateDeviceId}`;
      const result = await request.tracking.patch(path, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(setUpdateDeviceId(null));
      dispatch(createAlert(alertTypes.info, translate('deviceEditedAlert')));
    }
    dispatch(fetchDevicesList());
    dispatch(closeSettingsModal());
  } catch (error) {
    dispatch(createDeviceFinished());
    dispatch(createAlert('error', error.message));
  }
};
