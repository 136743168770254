import Image from 'assets/images/welcomeLogo/huawei/logo.svg';

import faviconImage from 'assets/images/favicons/huawei/favicon.ico';

const favicon = document.getElementById('favicon');

favicon.href = faviconImage;

const theme = {
  palette: {
    primary: {
      main: '#e30611',
    },
    secondary: {
      main: '#cf1519',
    },
    error: {
      main: '#f57c00',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#335df4',
    },
    success: {
      main: '#00f412',
    },
  },
  CustomStyles: {
    LogoImageBackground: `url(${Image}) center no-repeat`,
  },
};

export default theme;
