import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import userReducer from 'reducers/user';
import authReducer from 'reducers/auth';
import alertReducer from 'reducers/alert';
import appReducer from 'reducers/app';
import locationsReducer from 'reducers/locations';
import floorReducer from 'reducers/floor';
import workerReducer from 'reducers/worker';
import objectsReducer from 'reducers/objects';
import zonesReducer from 'reducers/zones';
import groupsReducer from 'reducers/groups';
import reportsResucer from 'reducers/reports';
import settingsReducer from 'reducers/settings';
import devicesReducer from 'reducers/devices';
import notificationsReducer from 'reducers/notifications';
import historyReducer from 'reducers/history';
import notificationsSettingsReducer from 'reducers/notifications.settings';

import { RESET_STORE_TO_DEFAULT } from 'actions/user';

const appRedusers = combineReducers({
  routing: routerReducer,
  user: userReducer,
  auth: authReducer,
  alert: alertReducer,
  app: appReducer,
  location: locationsReducer,
  floor: floorReducer,
  worker: workerReducer,
  objects: objectsReducer,
  zones: zonesReducer,
  groups: groupsReducer,
  reports: reportsResucer,
  settings: settingsReducer,
  devices: devicesReducer,
  notifications: notificationsReducer,
  history: historyReducer,
  notificationsSettings: notificationsSettingsReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === RESET_STORE_TO_DEFAULT) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appRedusers(state, action);
};

export default rootReducer;
