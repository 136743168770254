import request, { endPointsEnum } from 'services/http';
import { createAlert } from './alert';

export const SET_FLOOR_LIST = 'SET_FLOOR_LIST';
export const FETCH_FLOORS_IN_PROGRESS = 'FETCH_FLOORS_IN_PROGRESS';
export const FETCHED_FLOORS = 'FETCHED_FLOORS';
export const SET_CURRENT_FLOOR = 'SET_CURRENT_FLOOR';

export const setFloorList = (floorsObject) => ({
  type: SET_FLOOR_LIST,
  floorsObject,
});

export const setFloor = (floor) => ({
  type: SET_CURRENT_FLOOR,
  floor,
});

export const fetchFloorsInProgress = () => ({
  type: FETCH_FLOORS_IN_PROGRESS,
});

export const fetchedFloors = () => ({
  type: FETCHED_FLOORS,
});

export const fetchFloors = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
      userId: localStorage.id,
    };

    dispatch(fetchFloorsInProgress());

    const response = await request.client.get(endPointsEnum.floorsGetAll, { params });

    const floorsArray = response.data;
    const floorsObject = {};
    let firstFloor = {};
    floorsArray.map((floor, index) => {
      floorsObject[floor.id] = {
        id: floor.id,
        title: floor.attributes.name,
        location: floor.relationships.location.data.id,
        h: floor.attributes.dimensions_in_meters.height,
        hor: floor.attributes.hor,
        image_bounds: floor.attributes.image_bounds,
        image_url: floor.attributes.files.length ? floor.attributes.files[0].full_url : null,
        lat: floor.attributes.lat,
        lon: floor.attributes.lon,
        ph: floor.attributes.dimensions_in_pixels.height,
        pw: floor.attributes.dimensions_in_pixels.width,
        w: floor.attributes.dimensions_in_meters.width,
        sort: floor.attributes.sort,
      };
      if (index === 0) {
        firstFloor = { ...floorsObject[floor.id] };
      }

      return floor;
    });

    dispatch(setFloorList(floorsObject));
    dispatch(setFloor(firstFloor));
    dispatch(fetchedFloors());
  } catch (error) {
    dispatch(fetchedFloors());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert('error', error.message));
    }
  }
};
