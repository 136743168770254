import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { userFetchIfNeeded } from 'actions/user';
import { appFetch } from 'actions/app';
import { objectsFetch } from 'actions/objects';
import { getNotifications, updateNotificationsPeriod } from 'actions/notifications';
import init from 'helpers/init';
// import NoAppsComponent from './NoApps.Component';
import Loading from './Loading';

const timersArray = [];

const PrivateComponent = ({
  children,
  dispatch,
  currentApp: app,
  updatePeriod,
  objectTTL,
  userHash,
  isFetchingApps,
}) => {
  useEffect(() => {
    dispatch(userFetchIfNeeded())
      .then(async (isNeedToFetch) => {
        if (isNeedToFetch) {
          await dispatch(appFetch());
          await init(dispatch);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHash]);

  const checkToPlan = () => app && app.id && app.api_key && objectTTL;

  const clearAllTimers = () => {
    timersArray.forEach((id) => {
      clearTimeout(id);
    });
  };

  const planInterval = () => {
    clearAllTimers();
    if (!checkToPlan()) {
      return;
    }
    // eslint-disable-next-line no-use-before-define
    timersArray.push(setTimeout(getObjects, updatePeriod));
  };

  const getObjects = async () => {
    dispatch(updateNotificationsPeriod());
    await dispatch(objectsFetch());
    await dispatch(getNotifications());
    planInterval();
  };

  useEffect(() => {
    dispatch(updateNotificationsPeriod());
    dispatch(objectsFetch())
      .then(async () => {
        await dispatch(getNotifications());
        planInterval();
      })
      .catch(() => {
        console.warn('Error in timer');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.api_key, app.id, objectTTL]);

  if (app.id && !isFetchingApps) {
    return (
      children
    );
  }
  // if (!isFetchingApps && !app.id && userHash) {
  //   return (<NoAppsComponent />);
  // }
  return <Loading />;
};

function mapStateToProps(state) {
  const { currentApp, isFetching: isFetchingApps } = state.app;
  const {
    timerID,
    updatePeriod,
    objectTTL,
  } = state.worker;
  const {
    hash: userHash,
  } = state.user;

  return {
    isFetchingApps,
    currentApp,
    timerID,
    updatePeriod,
    objectTTL,
    userHash,
  };
}

export default connect(mapStateToProps)(PrivateComponent);
