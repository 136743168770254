import React, { useState } from 'react';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';
import { setModalForm } from 'actions/settings';
import { createAlert } from 'actions/alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  fileFormWrap: {
    width: '255px',
    margin: 'auto',
    fontFamily: 'Regular',
  },
  fileUpload: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '50px',
    lineHeight: '70px',
    fontSize: '18px',
    borderBottom: '1px solid rgb(155, 155, 155)',
    color: 'rgb(120,120,120)',
    '&input': {
      display: 'none',
    },
    '&:hover': {
      borderBottom: '2px solid rgb(50, 50, 50)',
    },
    '&:label': {
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  },
  fileUploadd: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '50px',
    lineHeight: '70px',
    fontSize: '18px',
    borderBottom: '1px solid rgb(155, 155, 155)',
    color: 'rgb(120,120,120)',
    '&input': {
      display: 'none',
    },
    '&:hover': {
      borderBottom: '2px solid rgb(50, 50, 50)',
    },
    '&:label': {
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  },
  textInput: {},
  hiddenInput: {
    display: 'none',
  },
  imgCamera: {
    float: 'right',
    marginTop: '15px',
  },
  imgInput: {
    position: 'absolute',
    left: '170px',
    fill: 'currentColor',
    maxWidth: '1.8em',
    maxHeight: '1.5em',
    display: 'inline-block',
    fontSize: '2rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    flexShrink: '0',
    userSelect: 'none',
  },
  cancelBtn: {
    float: 'right',
    marginTop: '15px',
    '&:hover': {
      color: 'black',
    },
  },
});

const InputTypeFile = (props) => {
  const classes = useStyles();
  const [fileNameInput, setFileNameInput] = useState();
  const {
    image,
    fileInfo,
    iconImageUrl,
  } = props;

  const { t } = useTranslation(['settings', 'report']);

  const dispatch = useDispatch();

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
  const handleChangeForm = async (event) => {
    const y = new Error(t('chooseAnotherFile'));
    const info = event.target.files[0] || '';
    const b64 = await convertBase64(info);
    const isLt5M = info.size < 10 * 1024 * 1024;
    const isName = /\.(jpe?g|bmp|gif|png|svg|)$/i.test(info.name);
    if (!isLt5M || !isName) {
      dispatch(createAlert('error', y.message));
    } else {
      // dispatch(setObjectImageToStore(info, b64));
      dispatch(
        setModalForm({
          fileInfo: info,
          image: b64,
        }),
      );
      if (info.name.length > 15) {
        setFileNameInput(`${info.name.substr(0, 18)}...`);
      } else {
        setFileNameInput(info.name);
      }
    }
  };

  const HandleClearIcon = () => {
    dispatch(
      setModalForm({
        fileInfo: ' ',
        iconImageUrl: '',
        image: '',
      }),
    );
    document.getElementById('contained-file').value = '';
  };

  return (
    <div className={classes.fileFormWrap}>
      {!fileInfo.name ? (
        <div>
          {iconImageUrl ? (
            <div className={classes.fileUploadd}>
              <label htmlFor="contained-file">
                <input
                  type="file"
                  onChange={handleChangeForm}
                  placeholder="Choose file"
                  id="contained-file"
                  className={classes.hiddenInput}
                />
                <span style={{ width: '140%' }} className={classes.textInput}>
                  {t('ChangeFile')}
                </span>

                <img
                  className={classes.imgInput}
                  alt="124"
                  height="50px"
                  src={iconImageUrl}
                />
              </label>
              <ClearIcon
                className={classes.cancelBtn}
                onClick={HandleClearIcon}
              />
            </div>
          ) : (
            <div className={classes.fileUploadd}>
              <label htmlFor="contained-file">
                <input
                  type="file"
                  onChange={handleChangeForm}
                  placeholder="Choose file"
                  id="contained-file"
                  className={classes.hiddenInput}
                />
                <span style={{ width: '140%' }} className={classes.textInput}>
                  {t('ChooseFile')}
                </span>
                <input
                  type="file"
                  onChange={handleChangeForm}
                  placeholder="Choose file"
                  id="contained-file"
                  className={classes.hiddenInput}
                />
                :
                <PhotoCamera className={classes.imgCamera} />
              </label>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.fileUpload}>
          <label htmlFor="contained-file">
            <input
              type="file"
              onChange={handleChangeForm}
              placeholder="Choose file"
              id="contained-file"
              className={classes.hiddenInput}
            />
            <span className={classes.textInput}>{fileNameInput}</span>
            <img
              src={image}
              height="50px"
              alt="noImg"
              className={classes.imgInput}
            />
          </label>
          <ClearIcon className={classes.cancelBtn} onClick={HandleClearIcon} />
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  const {
    image, fileInfo, title, iconImageUrl,
  } = state.settings.modal;
  return {
    image,
    fileInfo,
    title,
    iconImageUrl,
  };
}
export default connect(mapStateToProps)(InputTypeFile);
