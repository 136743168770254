import {
  SET_LAST_ID,
  SET_NOTIFICATIONS_ARRAY,
  UPDATE_NOTIFICATIONS_PERIOD,
} from 'actions/notifications';

const getLastHourBefore = () => {
  const currentDate = new Date().getTime();
  const sevenDays = 60 * 60 * 1000;

  return currentDate - sevenDays;
};

const initialState = {
  notificationsObject: [],
  lastId: '',
  timerID: null,
  updatePeriod: 1000,
  dateFrom: new Date(getLastHourBefore()),
  dateTo: new Date(),
};

// Todo: use immutable js lib

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_ARRAY:
      return { ...state, notificationsObject: [...action.notificationsObject] };
    case SET_LAST_ID:
      return { ...state, lastId: action.lastId };
    case UPDATE_NOTIFICATIONS_PERIOD:
      return {
        ...state,
        dateFrom: new Date(getLastHourBefore()),
        dateTo: new Date(),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
