import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import WelcomeLogo from 'components/login/logo/logo';
import { connect } from 'react-redux';
import {
  settings, history, monitoring, roles, serviceReports, demo3d,
} from 'constans';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu, MenuItem } from '@material-ui/core';
import AppList from './RightMenu/RightMenu';
import useStyles from './styles';

const TopMenu = (props) => {
  const { t } = useTranslation(['topMenu']);
  const location = useLocation();
  const path = location.pathname;
  const classes = useStyles();
  const { currentApp } = props;

  const accessCheck = (app) => {
    const { role } = app;
    return role !== roles.watcher;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const openDrawer = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDrawer = () => {
    setAnchorEl(null);
  };

  const is3dEnabled = () => {
    if (!currentApp || !currentApp.properties) {
      return false;
    }
    const prop3d = currentApp.properties.filter(({ type }) => type === '3dDemoEnabled')[0];
    if (!prop3d) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.menu}>
      <div className={classes.logo}>
        <WelcomeLogo />
      </div>
      <MenuIcon
        className={classes.menuIcon}
        onClick={openDrawer}
        fontSize="large"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDrawer}
      >
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={clsx(
              classes.listLink,
              path === '/monitoring' ? classes.listLinkActive : '',
            )}
            to="/monitoring"
          >
            {t('monitoring')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={clsx(
              classes.listLink,
              path === '/history' ? classes.listLinkActive : '',
            )}
            to="/history"
          >
            {t('history')}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            onClick={closeDrawer}
            className={clsx(
              classes.listLink,
              path.indexOf('/service-reports') === 0 ? classes.listLinkActive : '',
            )}
            to="/service-reports"
          >
            {t('reports')}
          </Link>
        </MenuItem>
        <MenuItem>
          {accessCheck(currentApp) ? (
            <Link
              onClick={closeDrawer}
              className={clsx(
                classes.listLink,
                path.indexOf('/settings') === 0 && classes.listLinkActive,
              )}
              to="/settings"
            >
              {t('settings')}
            </Link>
          ) : (
            <p className={clsx(classes.listLink, classes.inActiveLink)}>
              {t('settings')}
            </p>
          )}
        </MenuItem>
      </Menu>
      <div className={classes.menuList}>
        <ul
          onClose={closeDrawer}
          className={classes.list}
        >
          <li>
            <Link
              onClick={closeDrawer}
              className={clsx(
                classes.listLink,
                path === monitoring ? classes.listLinkActive : '',
              )}
              to={monitoring}
            >
              {t('monitoring')}
            </Link>
          </li>
          <li>
            <Link
              className={clsx(
                classes.listLink,
                path === history ? classes.listLinkActive : '',
              )}
              to={history}
            >
              {t('history')}
            </Link>
          </li>
          {is3dEnabled() ? (
            <li>
              <Link
                className={clsx(
                  classes.listLink,
                  path === demo3d ? classes.listLinkActive : '',
                )}
                to={demo3d}
              >
                3d
              </Link>
            </li>
          ) : null}
          <li>
            <Link
              className={clsx(
                classes.listLink,
                path.indexOf(serviceReports) === 0 ? classes.listLinkActive : '',
              )}
              to={serviceReports}
            >
              {t('reports')}
            </Link>
          </li>
          <li>
            {accessCheck(currentApp) ? (
              <Link
                className={clsx(
                  classes.listLink,
                  path.indexOf(settings) === 0 && classes.listLinkActive,
                )}
                to={settings}
              >
                {t('settings')}
              </Link>
            ) : (
              <p className={clsx(classes.listLink, classes.inActiveLink)}>
                {t('settings')}
              </p>
            )}
          </li>
        </ul>
      </div>
      <div className={classes.profile}>
        <AppList />
      </div>
    </div>
  );
};

/*
          <li><Link className={clsx(classes.listLink, path === '/reports' ?
          classes.listLinkActive : '')}
           to='/reports'>{t('reports')}</Link></li>
*/

function mapStateToProps(state) {
  const { currentApp } = state.app;

  return {
    currentApp,
  };
}

export default connect(mapStateToProps)(TopMenu);
