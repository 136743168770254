import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  profoleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '32px 50px 0 10vw',
    whiteSpace: 'nowrap',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  title: {
    fontSize: '24px',
    [materialTheme.breakpoints.down('800')]: {
      fontSize: '22px',
    },
  },
  wrapp: {
    display: 'flex',
    marginTop: '40px',
    textAlign: 'left',
    lineHeight: '14px',
    fontSize: '20px',
    [materialTheme.breakpoints.down('800')]: {
      fontSize: '18px',
    },
  },
  rightBlock: {
    margin: '0 0 0 5vw',
  },
}));

export default useStyles;
