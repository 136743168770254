import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const customButton = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    root: {
      fontFamily: fontRegular,
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  };
})(Button);
export default customButton;
