import {
  SET_HISTORY_LIST,
  FETCH_HISTORY_END,
  FETCH_HISTORY_IN_PROGRESS,
  RESET_HISTORY_LIST,
} from 'actions/history';

const initialState = {
  history: {},
  objectsList: {},
  isFetching: false,
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY_LIST:
      return {
        ...state,
        history: action.historyArray,
        objectsList: action.objectsList,
        isFetching: false,
      };
    case RESET_HISTORY_LIST:
      return {
        ...state,
        history: {},
        objectsList: {},
        isFetching: false,
      };
    case FETCH_HISTORY_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCH_HISTORY_END:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default historyReducer;
