import { withStyles } from '@material-ui/core/styles';
import {
  DateTimePicker,
} from '@material-ui/pickers';

const styledDataPicker = withStyles(() => ({
  root: {
    borderRadius: 0,
    paddingLeft: '17px',
    height: '37px',
    border: '1px solid #edeff3',
    backgroundColor: '#ffffff',
  },
}))(DateTimePicker);

export default styledDataPicker;
