/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';

// import components
import LocationSelector from 'components/shared/LocationSelector/LocationSelector';
import FloorSelector from 'components/shared/FloorSelector/FloorSelector';
import ZoneSelector from 'components/shared/ZonesSelector/ZonesSelector';
import GroupSelector from 'components/shared/GroupSelector/GroupSelector';
import AutoComplete from 'components/shared/AutoComplete/autoComplete';

import MainMenu from 'components/menu/MainMenu';
import {
  allValuesConstant,
} from 'constans';

import theeD from './3d';
import useStyles from './style';

const Three3d = (props) => {
  const {
    dispatch,
    locationsObject,
    currentLocation,
    floorsObject,
    currentFloor,
    openMenu,
    groupsObject,
    selectedGroup,
    objectsInMonitoringArray,
    zonesObject,
    selectedZone,
    zoneTypes,
    currentObject,
  } = props;

  const filteredObjectsArray = objectsInMonitoringArray.filter((object) => {
    const floorFilter = parseInt(object.attributes.sublocation_id, 10)
      === parseInt(currentFloor.id, 10);
    const groupFilter = selectedGroup.id === allValuesConstant
      || parseInt(object.attributes.tracked_group_id, 10)
      === parseInt(selectedGroup.id, 10);
    return floorFilter && groupFilter;
  });

  const { t } = useTranslation(['monitoring']);
  const classes = useStyles();
  const sceneElement = useRef();
  const three = useRef();

  // eslint-disable-next-line
  const [showTrackLines, setShowTrackLines] = useState(false);

  useEffect(() => {
    let evt;
    const resize = (entires, observer) => {
      three.current = theeD(sceneElement);
      evt = three.current.onWindowResize;

      observer.unobserve(sceneElement.current);
    };
    new ResizeObserver(resize).observe(sceneElement.current);

    return () => {
      window.removeEventListener('resize', evt, false);
      window.cancelAnimationFrame(window.animationId);
    };
  }, []);

  useEffect(() => {
    if (filteredObjectsArray.length > 0 && three.current) {
      const { updateModelCoordinates } = three.current;
      const { kx, ky } = filteredObjectsArray[0].attributes;
      updateModelCoordinates(kx, ky);
    }
  }, [filteredObjectsArray]);

  return (
    <div className={classes.threeD}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.control_wrapper}>
            <LocationSelector
              locations={locationsObject}
              value={currentLocation.id}
              // onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FloorSelector
              floors={floorsObject}
              // onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
          </div>
          <div className={classes.control_wrapper}>
            <ZoneSelector
              // onChange={handleChangeZone}
              zones={zonesObject}
              value={selectedZone.id}
              zoneTypes={zoneTypes}
              currentFloorId={currentFloor.id}
            />
          </div>

          <div className={classes.control_wrapper}>
            <GroupSelector
              // onChange={handleChangeGroup}
              groups={groupsObject}
              value={selectedGroup.id}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FormControlLabel
              className={classes.swich_element_monitoring}
              control={(
                <Switch
                  checked={showTrackLines}
                  // onChange={handleShowTrackLines}
                  name="show track lines"
                  color="primary"
                />
              )}
              label={t('showTrackLines')}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FormControl>
              <div className={classes.objectsCount}>
                {' '}
                <p>
                  {' '}
                  {t('countOfObjects')}
                  {' '}
                  <b>{filteredObjectsArray.length}</b>
                </p>
                {' '}
              </div>
              <AutoComplete
                filteredObjectsArray={filteredObjectsArray}
                currentObject={currentObject}
                // selectObject={selectObject}
              />
            </FormControl>
          </div>
        </div>
      </MainMenu>
      <div className={classes.sceneElement} ref={sceneElement} />
    </div>
  );
};

function mapStateToProps(state) {
  const {
    locationsObject,
    currentLocation,
  } = state.location;
  const {
    floorsObject,
    currentFloor,
  } = state.floor;
  const { openMenu } = state.app;

  const { groupsObject, selectedGroup } = state.groups;
  const { objectsInMonitoringArray, json } = state.objects;
  const { zonesObject, selectedZone, zoneTypes } = state.zones;
  const { currentObject } = state.reports;

  return {
    locationsObject,
    currentLocation,
    floorsObject,
    currentFloor,
    openMenu,
    currentObject,
    groupsObject,
    selectedGroup,
    objectsInMonitoringArray,
    json,
    zonesObject,
    selectedZone,
    zoneTypes,
  };
}
export default connect(mapStateToProps)(Three3d);
