import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ObjectSettings from 'components/settings/objects/objects.componetns';
import LeftMenuComponent from 'components/leftMenu/left.menu';
import { useTranslation } from 'react-i18next';
import NotificationsComponent from 'components/settings/notifications/notifications.componetns';
import Groups from 'components/settings/groups/groups.componetns';
import Devices from 'components/settings/devices/devices.component';
import { settings } from 'constans';
import { connect } from 'react-redux';
import useStyles from './settings.style';

const Settings = (props) => {
  const { dispatch, openMenu } = props;
  const classes = useStyles();
  const { t } = useTranslation(['settings']);
  const settingsPath = settings;
  const objectsPath = `${settingsPath}/objects`;
  const notificationsPath = `${settingsPath}/notifications`;
  const groupsPath = `${settingsPath}/groups`;
  const devicesPath = `${settingsPath}/devices`;
  const menu = [
    {
      name: t('objectsLinkName'),
      path: objectsPath,
    },
    {
      name: t('notificationsLink'),
      path: notificationsPath,
    },
    {
      name: t('groupsLink'),
      path: groupsPath,
    },
    {
      name: t('Devices'),
      path: devicesPath,
    },
  ];
  return (
    <div className={!openMenu ? classes.container : classes.noneContainer}>
      <LeftMenuComponent options={menu} openMenu={openMenu} dispatch={dispatch} />
      <Switch>
        <Route exact path={settingsPath}>
          <Redirect
            to={{
              pathname: objectsPath,
            }}
          />
        </Route>
        <Route path={objectsPath}>
          <ObjectSettings />
        </Route>
        <Route path={notificationsPath}>
          <NotificationsComponent />
        </Route>
        <Route path={groupsPath}>
          <Groups />
        </Route>
        <Route path={devicesPath}>
          <Devices />
        </Route>
      </Switch>
    </div>
  );
};

function mapStateToProps(state) {
  const { openMenu, dispatch } = state.app;

  return {
    openMenu,
    dispatch,
  };
}

export default connect(mapStateToProps)(Settings);
