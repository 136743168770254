import jwt from 'jsonwebtoken';
import request, { endPointsEnum } from 'services/http';
import init from 'helpers/init';
import { appFetch } from 'actions/app';
import Cookies from 'js-cookie';
import { cookieTokenName, cookOpts, cookieRefTokenName } from 'constans';
import { setUser, logout } from './user';

export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS';
export const FETCH_FINISHED = 'FETCH_FINISHED';

export const SET_LOGIN = 'SET_LOGIN';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SHOW_PASSWORD = 'SHOW_PASSWORD';

export const setLogin = (login) => ({
  type: SET_LOGIN,
  login,
});

export const setPassword = (password) => ({
  type: SET_PASSWORD,
  password,
});

export const setShowPassword = (showPassword) => ({
  type: SHOW_PASSWORD,
  showPassword,
});

export const fetchFinished = () => ({
  type: FETCH_FINISHED,
});

export const loginInProgress = () => ({
  type: LOGIN_IN_PROGRESS,
});

export const tokenRefresh = async () => {
  try {
    const storedRefreshToken = Cookies.get(cookieRefTokenName);
    const params = {
      token: storedRefreshToken,
    };
    const result = await request.auth.get(endPointsEnum.tokenRefresh, {
      params,
    });
    const { token, refreshToken } = result;

    if (!token || !refreshToken) {
      return false;
    }

    Cookies.set(cookieTokenName, token, cookOpts);
    Cookies.set(cookieRefTokenName, refreshToken, cookOpts);

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
    return false;
  }
};

export const tokenVerifyAndUpdate = async (dispatch) => {
  try {
    const storedToken = Cookies.get(cookieTokenName);
    const params = {
      token: storedToken,
    };
    const result = await request.auth.get(endPointsEnum.tokenVerify, {
      params,
    }).catch((err) => {
      if (err.httpStatus === 403 || err.httpStatus === 401) {
        return false;
      }
      throw new Error(err);
    });

    if (!result || !result.activeApp || !result.user) {
      const isRefreshed = await tokenRefresh();
      if (!isRefreshed) {
        dispatch(logout());

        return false;
      }
    }

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
    return false;
  }
};

export const loginFetch = (login, password) => async (dispatch) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const body = {
      email: login,
      password,
    };
    dispatch(loginInProgress());

    const result = await request.auth.post(endPointsEnum.authorization, {
      body,
    });

    const { token, refreshToken } = result;
    const tokenPayload = jwt.decode(token);
    const {
      hash, company, email, name, id, avatar_url: avatarUrl,
    } = tokenPayload.userData;

    Cookies.set(cookieTokenName, token, cookOpts);
    Cookies.set(cookieRefTokenName, refreshToken, cookOpts);
    // this.cookies.set('navtok', token, ttl, path, domain, secure);
    localStorage.setItem('token', token);
    localStorage.setItem('hash', hash);
    localStorage.setItem('id', id);

    dispatch(
      setUser({
        name,
        email,
        avatar_url: avatarUrl,
        hash,
        company,
      }),
    );

    dispatch(fetchFinished());
    await dispatch(appFetch());
    await init(dispatch);
  } catch (error) {
    throw error;
  }
};
