import { fetchZones } from 'actions/zones';
import { fetchGroups } from 'actions/groups';
import { fetchLocations } from 'actions/locations';
import { fetchFloors } from 'actions/floors';
import { resetReports } from 'actions/reports';
import { resetHistoryList } from 'actions/history';
import { tokenVerifyAndUpdate } from 'actions/auth';

const init = async (dispatch) => {
  await tokenVerifyAndUpdate(dispatch);
  await dispatch(fetchLocations());
  await dispatch(fetchFloors());
  await dispatch(fetchZones());
  await dispatch(fetchGroups());
  await dispatch(resetReports());
  await dispatch(resetHistoryList());
};

export default init;
