/* eslint-disable no-param-reassign */
import request, { endPointsEnum } from 'services/http';
import { closeModalAction, addCustomProperty } from 'actions/settings';
import { createDevice, setDevicesModalForm } from 'actions/devices';
import {
  staticTypeId,
  dynamicTypeId,
  oldDevice,
  formTypeCreate,
} from 'constans';
import { setCurrentObject } from 'actions/reports';
import { createAlert } from './alert';

export const SET_SORT_OBJECTS = 'SET_SORT_OBJECTS';
export const SET_SORT_DEVICE = 'SET_SORT_DEVICE3';
export const SET_SORT_GROUP = 'SET_SORT_GROUP';
export const SET_MONITORING_OBEJECTS_ARRAY = 'SET_MONITORING_OBEJECTS_ARRAY';
export const SET_OBJECTS_LIST_BY_APP = 'SET_OBJECTS_LIST_BY_APP';

export const FETCH_OBJECTS_BY_APP_START = 'FETCH_OBJECTS_BY_APP_START';
export const FETCH_OBJECTS_BY_APP_END = 'FETCH_OBJECTS_BY_APP_END';

export const FETCH_CREATE_OBJECT_START = 'FETCH_CREATE_OBJECT_START';
export const FETCH_CREATE_OBJECT_END = 'FETCH_CREATE_OBJECT_END';

export const FETCH_DELETE_OBJECT_START = 'FETCH_DELETE_OBJECT_START';
export const FETCH_DELETE_OBJECT_END = 'FETCH_DELETE_OBJECT_END';

export const CURRENT_OBJECT_SET = 'CURRENT_OBJECT_SET';
export const SET_JSON = 'SET_JSON';

export const deleteObjectFetchStart = () => ({
  type: FETCH_DELETE_OBJECT_START,
});

export const deleteObjectFetchEnd = () => ({
  type: FETCH_DELETE_OBJECT_END,
});

export const fetchCreateObjectStart = () => ({
  type: FETCH_CREATE_OBJECT_START,
});

export const fetchCreateObjectEnd = () => ({
  type: FETCH_CREATE_OBJECT_END,
});

export const setobjectsInMonitoringArray = (list) => ({
  type: SET_MONITORING_OBEJECTS_ARRAY,
  list,
});
export const setJson = (list) => ({
  type: SET_JSON,
  list,
});

export const setFetchByAppStart = () => ({
  type: FETCH_OBJECTS_BY_APP_START,
});

export const setFetchByAppEnd = () => ({
  type: FETCH_OBJECTS_BY_APP_END,
});

export const currentObjectSet = (object) => ({
  type: CURRENT_OBJECT_SET,
  object,
});

export const setObjectsListByApp = (list) => ({
  type: SET_OBJECTS_LIST_BY_APP,
  list,
});
export const setSortObjectsTitle = (str) => ({
  type: SET_SORT_OBJECTS,
  str,
});
export const setSortGroup = (str) => ({
  type: SET_SORT_GROUP,
  str,
});
export const sortByTitles = (objectTitleFilter = '', offset = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    const { sortByTitle } = state.objects;

    dispatch(setFetchByAppStart());
    const result = await request.tracking.get(endPointsEnum.trackedObjectList, {
      params: {
        'page[offset]': offset,
        'page[limit]': limit,
        'like[title][]': objectTitleFilter || '',
        'like[group.title][]': objectTitleFilter || '',
        api_key: apiKey,
      },
    });
    dispatch(setFetchByAppEnd());
    if (!result.data) {
      throw new Error('Error in request');
    }

    if (sortByTitle === 'asc') {
      result.data.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
      dispatch(setSortObjectsTitle('desc'));
    } else {
      result.data.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
      dispatch(setSortObjectsTitle('asc'));
    }
    dispatch(setObjectsListByApp(result.data.reverse()));
  } catch (error) {
    dispatch(createAlert('error', error.message));
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};
export const sortByGroup = (objectTitleFilter = '', offset = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    const { sortByTitle } = state.objects;

    dispatch(setFetchByAppStart());
    const result = await request.tracking.get(endPointsEnum.trackedObjectList, {
      params: {
        'page[offset]': offset,
        'page[limit]': limit,
        'like[title][]': objectTitleFilter || '',
        'like[group.title][]': objectTitleFilter || '',
        api_key: apiKey,
      },
    });
    dispatch(setFetchByAppEnd());
    if (!result.data) {
      throw new Error('Error in request');
    }

    if (sortByTitle === 'asc') {
      result.data.sort((a, b) => a.relationships['tracked-group'].data.title.localeCompare(b.relationships['tracked-group'].data.title));
      dispatch(setSortObjectsTitle('desc'));
    } else {
      result.data.sort((a, b) => b.relationships['tracked-group'].data.title.localeCompare(a.relationships['tracked-group'].data.title));
      dispatch(setSortObjectsTitle('asc'));
    }
    dispatch(setObjectsListByApp(result.data.reverse()));
  } catch (error) {
    dispatch(createAlert('error', error.message));
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};
export const sortByDevice = (objectTitleFilter = '', offset = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }
    const { sortByTitle } = state.objects;

    dispatch(setFetchByAppStart());
    const result = await request.tracking.get(endPointsEnum.trackedObjectList, {
      params: {
        'page[offset]': offset,
        'page[limit]': limit,
        'like[title][]': objectTitleFilter || '',
        'like[group.title][]': objectTitleFilter || '',
        api_key: apiKey,
      },
    });
    dispatch(setFetchByAppEnd());
    if (!result.data) {
      throw new Error('Error in request');
    }
    if (sortByTitle === 'asc') {
      result.data.forEach((element) => {
        if (!element.relationships['tracked-beacon']) {
          element.relationships['tracked-beacon'] = {};
          element.relationships['tracked-beacon'].data = {};
          element.relationships['tracked-beacon'].data.title = '-';
        }
      });
      result.data.sort((a, b) => a.relationships['tracked-beacon'].data.title.localeCompare(b.relationships['tracked-beacon'].data.title));
      dispatch(setSortObjectsTitle('desc'));
    } else {
      result.data.forEach((element) => {
        if (!element.relationships['tracked-beacon']) {
          element.relationships['tracked-beacon'] = {};
          element.relationships['tracked-beacon'].data = {};
          element.relationships['tracked-beacon'].data.title = '-';
        }
      });
      result.data.sort((a, b) => b.relationships['tracked-beacon'].data.title.localeCompare(a.relationships['tracked-beacon'].data.title));
      dispatch(setSortObjectsTitle('asc'));
    }
    dispatch(setObjectsListByApp(result.data.reverse()));
  } catch (error) {
    dispatch(createAlert('error', error.message));
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};
export const objectsByAppFetch = (objectTitleFilter = '', offset = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!apiKey) {
      return;
    }

    dispatch(setFetchByAppStart());
    const result = await request.tracking.get(
      endPointsEnum.trackedObjectList,
      {
        params: {
          'page[offset]': offset,
          'page[limit]': limit,
          'like[title][]': objectTitleFilter || '',
          'like[group.title][]': objectTitleFilter || '',
          api_key: apiKey,
        },
      },
    );
    dispatch(setFetchByAppEnd());
    if (!result.data) {
      throw new Error('Error in request');
    }
    dispatch(setObjectsListByApp(result.data.reverse()));
  } catch (error) {
    dispatch(createAlert('error', error.message));
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};
export const deleteObject = (objectToDelete, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!objectToDelete.id) {
      return;
    }
    dispatch(deleteObjectFetchStart());
    const path = `${endPointsEnum.trackedObjects}/${objectToDelete.id}`;
    const params = {
      api_key: apiKey,
    };
    await request.tracking.delete(path, { params });

    await dispatch(objectsByAppFetch());
    await dispatch(setCurrentObject(null));

    dispatch(deleteObjectFetchEnd());
    dispatch(createAlert('info', translate('ObjectDeleteAlert')));
  } catch (error) {
    dispatch(deleteObjectFetchEnd());
    console.warn(`Error in deleting object: ${error.message}`);
    dispatch(createAlert('error', translate('objectDeleteError')));
  }
};

export const createObject = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const appStore = state.app;
    const { api_key: apiKey } = appStore.currentApp;
    const {
      objectUpdateId,
      formType,
      title,
      group,
      type,
      deviceType,
      device,
      newDeviceMac,
      floor,
      x,
      y,
      cameraUrl,
      iconImageUrl,
      image,
      // fileInfo,
      color,
      properties,
      objectColor,
    } = state.settings.modal;

    const {
      customProps,
    } = state.settings;

    if (!apiKey) {
      return;
    }

    const body = {
      attributes: {
        title,
        videostream_url: cameraUrl,
        url: iconImageUrl,
        file: image,
        // imageSourceName: fileInfo,
        color,
        objectColor,
      },
      relationships: {
        'tracked-beacon': {
          data: {},
        },
        'tracked-group': {
          data: {
            id: group.id,
            type: 'tracked-groups',
          },
        },
      },
    };

    properties.forEach((prop) => {
      body.attributes[prop.key] = prop.value;
    });
    customProps.forEach((prop) => {
      body.attributes[prop.key] = prop.value;
    });
    if (type === dynamicTypeId) {
      let deviceId;
      if (deviceType === oldDevice) {
        deviceId = device.id;
        body.attributes.beaconType = 'old';
      } else {
        const deviceform = {
          title,
          mac: newDeviceMac,
        };
        await dispatch(setDevicesModalForm(deviceform));
        const resultDevice = await dispatch(createDevice());
        deviceId = resultDevice.id;
        body.attributes.beaconType = 'new';
        body.attributes.mac_address = newDeviceMac;
      }
      body.attributes = {
        ...body.attributes,
        objectType: type,
        tracked_object_type_id: dynamicTypeId,
      };
      body.relationships['tracked-beacon'].data = {
        id: deviceId,
        type: 'tracked-beacons',
      };
    }
    if (type === staticTypeId) {
      body.attributes = {
        ...body.attributes,
        kx: x / floor.w,
        ky: y / floor.h,
        objectType: 'static',
        sublocation_id: parseInt(floor.id, 10),
        tracked_object_type_id: staticTypeId,
      };
    }
    dispatch(fetchCreateObjectStart());
    const requestBody = { ...body, api_key: apiKey };

    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedObjects, {
        body: requestBody,
      });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert('info', translate('ObjectCreatedAlert')));
    } else {
      const path = `${endPointsEnum.trackedObjects}/${objectUpdateId}`;
      const result = await request.tracking.put(path, { body: requestBody });
      dispatch(addCustomProperty(result.data.properties));
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert('info', translate('ObjectEditedAlert')));
    }

    dispatch(fetchCreateObjectEnd());
    dispatch(closeModalAction());
  } catch (error) {
    dispatch(fetchCreateObjectEnd());
    dispatch(createAlert('error', translate('objectMainModalError')));
    console.warn(`Error in objects' modal action: ${error.message}`);
  }
};

export const objectsFetch = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey, properties } = state.app.currentApp;
    const { objectTTL } = state.worker;
    if (!apiKey || !objectTTL) {
      return;
    }
    const objTtl = properties.filter((obj) => obj.type === 'object_ttl')[0];
    const response = await request.tracking.get(
      `${endPointsEnum.trackedObjects}/${endPointsEnum.trackedObjectsGetAll}`,
      {
        params: {
          actv: objTtl ? objTtl.value : objectTTL,
          api_key: apiKey,
          // "filter%5Bsublocation.id%5D": 6164,
        },
      },
    );
    const result = response.data || [];
    dispatch(setobjectsInMonitoringArray(result));
  } catch (error) {
    console.warn(`Cannot get tracked objects: ${error.message}`);
  }
};

export const unlinkDevice = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { currentObject } = state.objects;
    const body = {
      api_key: state.app.currentApp.api_key,
    };

    const response = await request.tracking.patch(
      endPointsEnum.unlinkDevice + currentObject,
      { body },
    );
    if (!response) {
      throw new Error('Error in response');
    }
    // dispatch(setObjectsListByApp())
    await dispatch(objectsByAppFetch());
    dispatch(deleteObjectFetchEnd());
  } catch (error) {
    dispatch(createAlert('error', error.message));
  }
};
