import {
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_MODAL_FORM,
  CLOSE_DELETE_MODAL,
  OPEN_DELETE_MODAL,
  OPEN_DELETE_DEVICE_MODAL,
  CLOSE_DELETE_DEVICE_MODAL,
  ADD_CUSTOM_PROPERTY,
  DELETE_CUSTOM_PROPERTY,
  EDIT_CUSTOM_PROPERTY,
  ADD_COLOR_PROPERTY_TRACK,
  ADD_COLOR_PROPERTY_OBJECT,
  CLOSE_CUSTOM_PROPERTY,
  REMOVE_CUSTOM_PROPERTY,
} from 'actions/settings';
import merge from 'lodash/merge';
import { dynamicTypeId, newDevice, formTypeCreate } from 'constans';

const initialState = {
  objectFilter: '',
  isFetchingCreateObject: false,
  openModal: false,
  objectToDelete: {},
  openDeleteModal: false,
  openDeleteDeviceModal: false,
  modal: {
    formType: formTypeCreate,
    objectUpdateId: null,
    title: '',
    group: {},
    type: dynamicTypeId,
    deviceType: newDevice,
    device: {},
    newDeviceMac: '',
    floor: {},
    x: 0,
    y: 0,
    customPropertyUrl: '',
    cameraUrl: '',
    iconImageUrl: '',
    fileInfo: {},
    image: '',
    color: '#f9eea4',
    objectColor: '#fff',
    properties: [],
  },
  customProps: [],
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        openModal: true,
        modal: merge({}, state.modal, action.formType),
      };
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: false,
        modal: { ...initialState.modal },
      };
    case ADD_CUSTOM_PROPERTY:
      return { ...state, customProps: [...state.customProps, action.prop] };
      // return { ...state, customProps: action.prop };
    case REMOVE_CUSTOM_PROPERTY:
      return { ...state, customProps: action.prop };
      // return { ...state, customProps: action.prop };
    case EDIT_CUSTOM_PROPERTY:
      return { ...state, customProps: action.prop };
      // return { ...state, customProps: action.prop };
    case CLOSE_CUSTOM_PROPERTY:
      // return { ...state, customProps: [...state.customProps, action.prop] };
      return { ...state, customProps: [] };
    case DELETE_CUSTOM_PROPERTY:
      return {
        ...state,
        modal: merge({},
          { ...state.modal, properties: [] }, action.arr),
      };
    case ADD_COLOR_PROPERTY_OBJECT:
      return { ...state, colorObject: action.prop };
    case ADD_COLOR_PROPERTY_TRACK:
      return { ...state, color: action.prop };
    case SET_MODAL_FORM:
      return { ...state, modal: merge({}, state.modal, action.modalForm) };
    case OPEN_DELETE_MODAL:
      return { ...state, openDeleteModal: true };
    case CLOSE_DELETE_MODAL:
      return { ...state, openDeleteModal: false };
    case OPEN_DELETE_DEVICE_MODAL:
      return { ...state, openDeleteDeviceModal: true };
    case CLOSE_DELETE_DEVICE_MODAL:
      return { ...state, openDeleteDeviceModal: false };
    default:
      return state;
  }
};

export default settingsReducer;
