import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  legendWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.7vw',
  },
  legendColor: {
    width: '20px',
    height: '5px',
    marginTop: '7px',
    marginRight: '3px',
  },
});

const ObjectsList = ({ objectsList, showTrackLines }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.legendWrapper}
    >
      {objectsList ? Object.values(objectsList).map((object, index) => (
        <div
          className={classes.legendItem}
          // eslint-disable-next-line react/no-array-index-key
          key={index.toString()}
        >
          {showTrackLines
            ? (
              <div
                className={classes.legendColor}
                style={{ background: object.color }}
              />
            )
            : null}
          <div>
            {' '}
            {object.title}
            {' '}
          </div>
        </div>
      )) : null}
    </div>
  );
};

export default ObjectsList;
