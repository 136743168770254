import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  container: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  noneContainer: {
    margin: '0 auto',
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  mapControls: {
    width: '100%',
    background: materialTheme.CustomStyles.MainBackgroundColor,
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
  objectsFilter: {
    width: '100%',
  },
  map_container: {
    width: 'calc(100vw)',
    [materialTheme.breakpoints.down('800')]: {
      width: '100vw',
    },
    height: 'calc(100vh - 80px)',
    display: 'block',
    margin: '0 auto',
    '&::backdrop': {
      background: '#fafafa',
    },
  },
  control_wrapper: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  swich_element_monitoring: {
    marginLeft: '9px',
  },
  tableElementEven: {
    backgroundColor: '#FFFFFF',
  },
  tableElementOdd: {
    backgroundColor: '#DADADA',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  noneDialogContent: {
    width: '400px',
  },
  videoBlock: {
    width: '400px',
    margin: '10px',
    height: '470px',
  },
  itemImage: {
    maxWidth: '100px',
    maxHeight: '100px',
  },
  arrowBtn: {
    display: 'none',
    [materialTheme.breakpoints.down('800')]: {
      display: 'block',
    },
  },
  listImg: {
    width: '30px',
    height: '30px',
  },
  circle: {
    width: '30px',
    height: '30px',
    border: '2px solid black',
    borderRadius: '50%',
  },
  listItemTitle: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listItems: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

}));

export default useStyles;
