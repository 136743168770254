/*eslint-disable*/

import React from "react";
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from 'components/reports/CustomAutocomplete.component';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    paddingTop: '1vh',
    width: '100%',
  },
});

export default function AutoComplete (props) {
  const classes = useStyles();
  const {
    filteredObjectsArray,
    currentObject,
    selectObject,
    onAutocompliteInput,
    ... rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const getOptionLabel = (option) => {
    return option.attributes.title
  }
  const getOptionSelected = (option, value) => {
    return +option.id === +value.id
  }
  
  return (
      <div className={classes.wrapper}>
          <div>{t('object')}</div>
    <CustomAutocomplete
    id="objects"
    options={filteredObjectsArray}
    value={currentObject}
    onChange={selectObject}
    onInputChange={onAutocompliteInput}
    getOptionLabel={getOptionLabel}
    getOptionSelected={getOptionSelected}
    renderInput={(params) => (
        <TextField
        InputProps={{ disableUnderline: true }}
        variant="outlined"
        type="text"
        placeholder={t('autoCompletePlaceholder')}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        />
        )}
        />
        </div>
  );
}
