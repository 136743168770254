/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import SelectorComponent from 'components/settings/Selector.component';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';

import InputComponent from 'components/settings/Input.component';
import FormControl from '@material-ui/core/FormControl';
import PaletteIcon from '@material-ui/icons/Palette';

import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  objectsByAppFetch, deleteObject, unlinkDevice, createObject, currentObjectSet, sortByGroup,
  sortByTitles, sortByDevice,
} from 'actions/objects';
import {
  fetchGroupsInSettings,
} from 'actions/groups';
import { fetchDevicesList } from 'actions/devices';
import {
  openModalAction,
  closeModalAction,
  setModalForm,
  openDeleteModalAction,
  closeDeleteModal,
  openDeleteDeviceModalAction,
  closeDeleteDeviceModalAction,
  addCustomProperty,
  deleteCustomProperty,
  addColorPropertyTrack,
  addColorPropertyObject,
  closeCustomProperty,
  removeCustomProperty,
  editCustomProperty,
} from 'actions/settings';

import {
  staticTypeId,
  dynamicTypeId,
  oldDevice,
  newDevice,
  formTypeCreate,
  formTypeUpdate,
} from 'constans';

import { createAlert } from 'actions/alert';
import CustomTextField from 'components/settings/TextFiled';
import CustomButton from 'components/settings/Button.component';
import CustomButtonTitle from 'components/settings/ButtonTitle.component';
import arrowStyles from 'components/shared/settings/arrow';
import containerStyles from '../../shared/Container/styles';
import TabPanel from '../../shared/Tabs/Tabs';
import a11yProps from '../../shared/Tabs/allProps';
import useStyles from './objects.component.style';
import InputTypeFile from './InputTypeFile.component';
import ViewObject from './ViewObject';

const Objects = (props) => {
  const {
    dispatch,
    objectsByAppArray,
    openModal,
    modal,
    groupsObject,
    groupsListInSettings,
    devicesList,
    floorsObject,
    currentApp,
    openDeleteModal,
    isObjectsByAppFetch,
    isFetchingCreateObject,
    isFetchingDeleteObject,
    openDeleteDeviceModal,
    openMenu,
    customProps,
  } = props;

  const { t } = useTranslation(['settings', 'report']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const objectDevices = {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [objectsFilter, setObjectsFilter] = useState('');
  const [objectToDelete, setObjectToDelete] = useState({});
  const [deviceToDelete, setDeviceToDelete] = useState({});
  const [isSrotByTitle, setSortByTitile] = useState('3');
  const [isSrotByGroupTitile, setIsSrotByGroupTitile] = useState('3');
  const [isSrotByDevice, setIsSrotByDevice] = useState('3');
  const classesContainer = containerStyles();
  const classes = useStyles();
  const arrowClasses = arrowStyles();
  const [keyProps, setKeyProps] = useState();
  const [valueProps, setValueProps] = useState();
  // const [switchBtn, setSwitchBtn] = useState(false);
  const [colorTrack, setColorTrack] = useColor('hex', modal.color);
  const [colorObject, setColorObject] = useColor('hex', modal.objectColor);
  const [showPaletteColorTrack, setShowPaletteColorTrack] = useState(false);
  const [showPaletteColorObject, setShowPaletteColorObject] = useState(false);
  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  const addProperty = () => {
    const resultObject = {
      key: keyProps,
      value: valueProps,
      id: Math.floor(Math.random() * 101),
      btn: false,
    };
    dispatch(addCustomProperty(resultObject));
    setKeyProps();
    setValueProps();
  };
  const removeProps = (prop) => {
    const newForm = JSON.parse(JSON.stringify(customProps));
    const newCustomProps = newForm.filter((i, index) => index !== prop);
    dispatch(removeCustomProperty(newCustomProps));
  };
  const editProperty = () => {
    dispatch(editCustomProperty());
  };
  const deleteProperty = (id) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties = modal.properties.filter((i) => i.id !== id);
    dispatch(deleteCustomProperty(newForm));
  };
  useEffect(() => {
    devicesList.map((device) => {
      objectDevices[device.id] = device;
      return device;
    });
  }, [devicesList, objectDevices]);

  const handleChangeFilter = (event) => {
    setPage(0);
    setObjectsFilter(event.target.value);
  };
  useEffect(() => {
    const getObjects = async () => {
      // something wrong with pagination in
      dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    };

    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsFilter, page, rowsPerPage, currentApp]);

  useEffect(() => {
    const getDevices = async () => {
      await dispatch(fetchGroupsInSettings());
      await dispatch(fetchDevicesList());
    };

    getDevices();
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const groupsList = Object.keys(groupsObject).map((element) => groupsObject[element]);

  const floorsArray = Object.keys(floorsObject);

  const changeProperties = (prop, value) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties.forEach((i) => {
      if (i.id === prop.id) {
        i['key'] = value;
      }
    });
    dispatch(deleteCustomProperty(newForm));
  };
  const changeValues = (prop, value) => {
    const newForm = JSON.parse(JSON.stringify(modal));
    newForm.properties.forEach((i) => {
      if (i.id === prop.id) {
        i['value'] = value;
      }
    });
    dispatch(deleteCustomProperty(newForm));
  };
  const handleCustomKeyChangeForm = (prop, index, value) => {
    setKeyProps(value);
  };
  const handleCustomValueChangeForm = (fieldName, value) => {
    setValueProps(value);
  };
  const handleChangeForm = (fieldName, value) => {
    const resultObject = {};
    resultObject[fieldName] = value;
    dispatch(setModalForm(resultObject));
  };
  const handleCreateNewObject = () => {
    dispatch(openModalAction(formTypeCreate));
    if (groupsList.length > 0) {
      handleChangeForm('group', groupsList[0]);
    }
  };
  const addColorTrack = () => {
    dispatch(addColorPropertyTrack(colorTrack.hex));
    handleChangeForm('color', colorTrack.hex);
    setShowPaletteColorTrack(false);
  };
  const addColorObject = () => {
    dispatch(addColorPropertyObject(colorObject.hex));
    handleChangeForm('objectColor', colorObject.hex);
    setShowPaletteColorObject(false);
  };
  // eslint-disable-next-line consistent-return
  const chechFormValidation = () => {
    const {
      title,
      group,
      type,
      device,
      floor,
      x,
      y,
      deviceType,
      newDeviceMac,
    } = modal;
    const mainProptiesCheck = !(title && group.id);
    if (type === dynamicTypeId) {
      const deviceIdCheck = !device.id;
      if (deviceType === newDevice) {
        return mainProptiesCheck || !newDeviceMac || isFetchingCreateObject;
      }
      if (deviceType === oldDevice) {
        return mainProptiesCheck || deviceIdCheck || isFetchingCreateObject;
      }
    }
    if (type === staticTypeId) {
      const floorCheck = !floor.id;

      return (
        mainProptiesCheck
        || floorCheck
        || x === ''
        || y === ''
        || isFetchingCreateObject
      );
    }
  };

  const handleEditObject = (object) => {
    const formFields = {};
    object.attributes.properties.map((objectProp) => {
      // if (i.key !== Object) {
      //   formFields.i.key = i.value;
      // }
      const { key, value } = objectProp;
      formFields[key] = value;
    });
    try {
      const objectAttributes = object.attributes;
      const objectRelationships = object.relationships;
      formFields.objectUpdateId = object.id;
      formFields.customPropertyUrl = object.attributes.external_property_url || '';
      formFields.color = objectAttributes.color || '';
      formFields.title = objectAttributes.title || '';
      formFields.customProps = objectAttributes.customProps || '';
      formFields.group = groupsObject[objectRelationships['tracked-group'].data.id] || {};
      formFields.iconImageUrl = objectAttributes.url || '';
      const rejectedProps = new Set(['videostream_url', 'file_url', 'color', 'objectColor']);
      formFields.properties = objectAttributes
        .properties
        .filter((prop) => !rejectedProps.has(prop.key));
      objectAttributes.properties.map((property) => {
        if (property.key === 'videostream_url') {
          formFields.cameraUrl = property.value || '';
        }
        if (
          property.key === 'file_url'
        ) {
          formFields.iconImageUrl = property.value || '';
        }
        // if (
        //   property.tracked_object_property_type_id === externalJsonPropertyTypeId
        // ) {
        //   formFields.customPropertyUrl = property.data.external_property_url || '';
        // }

        return property;
      });
      if (objectAttributes.properties.tracked_object_property_type_id === []) {
        return;
      }

      if (objectAttributes.tracked_object_type_id === dynamicTypeId) {
        formFields.type = dynamicTypeId;
        formFields.deviceType = oldDevice;
        formFields.device = objectDevices[objectRelationships['tracked-beacon'].data.id] || {};
      }
      if (objectAttributes.tracked_object_type_id === staticTypeId) {
        formFields.type = staticTypeId;
        const sublocation = object.attributes.properties.filter((i) => i.key === 'sublocation_id')[0];
        const kx = object.attributes.properties.filter((i) => i.key === 'kx')[0];
        const ky = object.attributes.properties.filter((i) => i.key === 'ky')[0];
        const floor = floorsObject[sublocation.value];
        if (floor) {
          formFields.x = kx.value * floor.w;
          formFields.y = ky.value * floor.h;
          formFields.floor = floor;
        }
      }
    } catch (error) {
      dispatch(createAlert('warn', 'Error in getting properties from object'));
      console.error(error);
    }
    dispatch(setModalForm(formFields));
    dispatch(openModalAction(formTypeUpdate));
  };

  const handleClose = () => {
    dispatch(closeModalAction());
    setShowPaletteColorTrack(false);
    setValueTabs(0);
    dispatch(closeCustomProperty());
    setKeyProps();
    setValueProps();
  };

  const handleCreateObject = async () => {
    await dispatch(createObject(t));
    setObjectsFilter('');
    await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    await dispatch(fetchDevicesList());
    setValueTabs(0);
    setKeyProps();
    setKeyProps();
    dispatch(closeCustomProperty());
  };

  const handleCloseDelete = () => {
    dispatch(closeDeleteDeviceModalAction());
  };
  const handleCloseDeleteObject = () => {
    dispatch(closeDeleteModal());
  };

  const handleDelete = async (object) => {
    setObjectToDelete(object);
    dispatch(openDeleteModalAction());
  };

  const handleDeteleConfirm = async () => {
    setObjectsFilter('');
    await dispatch(deleteObject(objectToDelete, t));
    await dispatch(objectsByAppFetch(objectsFilter, 0, 4000));
    dispatch(closeDeleteModal());
  };
  const handleDeleteDevice = () => {
    dispatch(unlinkDevice(deviceToDelete));
    dispatch(closeDeleteDeviceModalAction());
  };
  const unlinkBtn = (object) => {
    dispatch(currentObjectSet(object.id));
    setDeviceToDelete(object);
    dispatch(openDeleteDeviceModalAction());
  };
  const sortTitle = () => {
    if (devicesList.length !== 0) {
      dispatch(sortByTitles());
      setIsSrotByDevice('3');
      setIsSrotByGroupTitile('3');
    }
    if (isSrotByTitle === '3') {
      setSortByTitile('2');
    } else if (isSrotByTitle === '2') {
      setSortByTitile('1');
    } else if (isSrotByTitle === '1') {
      setSortByTitile('2');
    }
  };
  const sortGroup = () => {
    if (devicesList.length !== 0) {
      dispatch(sortByGroup());
      setSortByTitile('3');
      setIsSrotByDevice('3');
    }
    if (isSrotByGroupTitile === '3') {
      setIsSrotByGroupTitile('2');
    } else if (isSrotByGroupTitile === '2') {
      setIsSrotByGroupTitile('1');
    } else if (isSrotByGroupTitile === '1') {
      setIsSrotByGroupTitile('2');
    }
  };
  const sortDevice = () => {
    if (devicesList.length !== 0) {
      dispatch(sortByDevice());
      setSortByTitile('3');
      setIsSrotByGroupTitile('3');
    }
    if (isSrotByDevice === '3') {
      setIsSrotByDevice('2');
    } else if (isSrotByDevice === '2') {
      setIsSrotByDevice('1');
    } else if (isSrotByDevice === '1') {
      setIsSrotByDevice('2');
    }
  };
  const handlePaletteColor = () => {
    setShowPaletteColorTrack(!showPaletteColorTrack);
  };
  const handlePaletteColorObject = () => {
    setShowPaletteColorObject(!showPaletteColorObject);
  };
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewObject}
          disabled={groupsListInSettings.length === 0} // Кнопка создать объект
        >
          {t('createBtn')}
        </CustomButtonTitle>
        <CustomTextField
          id="standard-multiline-flexible"
          label={t('typeToSearch')}
          value={objectsFilter}
          onChange={handleChangeFilter} /// Поле поиска
        />
      </div>
      {isObjectsByAppFetch ? (
        <div className={classes.spinerWrapper}>
          <CircularProgress size={26} />
        </div>
      ) : (
        <div className={classes.table}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell onClick={sortTitle} className={arrowClasses.titleRow}>
                    {' '}
                    {t('objectsTitle')}
                    {' '}
                    {
                      (isSrotByTitle === '1')
                      && <ArrowDropUpIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByTitle === '2')
                      && <ArrowDropDownIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByTitle === '3')
                      && null
                    }
                  </TableCell>
                  <TableCell onClick={sortGroup} className={arrowClasses.titleRow}>
                    {' '}
                    {t('groupTitle')}
                    {' '}
                    {
                      (isSrotByGroupTitile === '1')
                      && <ArrowDropUpIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByGroupTitile === '2')
                      && <ArrowDropDownIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByGroupTitile === '3')
                      && null
                    }
                  </TableCell>
                  <TableCell onClick={sortDevice} className={arrowClasses.titleRow}>
                    {' '}
                    {t('beaconTitle')}
                    {' '}
                    {
                      (isSrotByDevice === '1')
                      && <ArrowDropUpIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByDevice === '2')
                      && <ArrowDropDownIcon className={arrowClasses.arrow} />
                    }
                    {
                      (isSrotByDevice === '3')
                      && null
                    }
                  </TableCell>
                  <TableCell>{t('actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectsByAppArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((object) => (
                    <TableRow key={object.id}>
                      <TableCell component="th" scope="row">
                        {object.attributes.title}
                      </TableCell>
                      <TableCell>
                        {object.relationships['tracked-group']
                          ? object.relationships['tracked-group'].data.title
                          : null}
                      </TableCell>
                      {object.relationships['tracked-beacon'] ? (
                        <TableCell>
                          <HighlightOffRoundedIcon
                            className={classes.iconCancel}
                            onClick={() => unlinkBtn(object)}
                          />
                          {object.relationships['tracked-beacon'].data.title}
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}

                      <TableCell>
                        <CustomButton
                          onClick={() => {
                            handleEditObject(object); // Редактирование объекта
                          }}
                        >
                          {' '}
                          {t('editBtn')}
                          {' '}
                        </CustomButton>
                        <CustomButton
                          onClick={() => {
                            handleDelete(object);
                          }}
                        >
                          {' '}
                          {t('deleteBtn')}
                          {' '}
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={objectsByAppArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('report:labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('report:of')} ${count !== -1 ? count : `${t('report:moreThen')} ${to}`
            }`}
          />
        </div>
      )}
      <div>
        <Dialog
          open={openDeleteDeviceModal}
          onClose={handleCloseDelete}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            {t('deleteDevice')}
          </DialogTitle>
          <DialogContent dividers>
            <p>
              {' '}
              {t('acceptDeleteDevice')}
            </p>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseDelete} color="primary">
              {t('cancelBtn')}
            </CustomButton>
            <CustomButton
              disabled={isFetchingDeleteObject}
              onClick={handleDeleteDevice}
              color="primary"
            >
              {t('deleteBtn')}
              {isFetchingDeleteObject ? (
                <div className={classes.spinerWrapper}>
                  <CircularProgress size={26} />
                </div>
              ) : null}
            </CustomButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteObject}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            {t('deleteFormTitle')}
          </DialogTitle>
          <DialogContent dividers>
            <p>
              {' '}
              {t('deleteFormContentText')}
              &quot;
              {objectToDelete.attributes ? objectToDelete.attributes.title : ''}
              &quot; ?
            </p>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleCloseDeleteObject} color="primary">
              {t('cancelBtn')}
            </CustomButton>
            <CustomButton
              disabled={isFetchingDeleteObject}
              onClick={handleDeteleConfirm}
              color="primary"
            >
              {t('deleteBtn')}
              {isFetchingDeleteObject ? (
                <div className={classes.spinerWrapper}>
                  <CircularProgress size={26} />
                </div>
              ) : null}
            </CustomButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModal}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{
            style: {
              position: 'absolute',
              top: '15vh',
            },
          }}
        >
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={classes.tabsMainWrapper}>
              <div className={classes.tabContainer}>
                <DialogTitle id="scroll-dialog-title">
                  {modal.formType === formTypeCreate
                    ? t('createFormTitle')
                    : t('editFormTitle')}
                  {modal.formType === formTypeCreate
                    ? <></> : (
                      <div className={classes.idTitle}>
                        id
                        {' '}
                        {modal.objectUpdateId}
                      </div>
                    )}
                </DialogTitle>
                <AppBar position="static" className={classes.wrappApp}>
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                    className={classes.tabsLabel}
                    TabIndicatorProps={{ style: { background: 'black' } }}
                  >
                    <Tab label={t('base')} {...a11yProps(0)} className={classes.tabLabel} style={{ maxWidth: '10%' }} />
                    <Tab label={t('view')} {...a11yProps(1)} className={classes.tabLabel} />
                    <Tab label={t('custom')} {...a11yProps(2)} className={classes.tabLabel} />
                  </Tabs>
                </AppBar>
                <TabPanel value={valueTabs} index={0}>
                  <DialogContent dividers>
                    <FormControl>
                      <InputComponent
                        id="object-title"
                        label={t('objectFormFieldTitle')}
                        type="text"
                        value={modal.title}
                        onChange={(event) => {
                          handleChangeForm('title', event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <SelectorComponent
                        label={t('objectFormFieldGroup')}
                        select
                        onChange={(event) => {
                          handleChangeForm('group', groupsObject[event.target.value]);
                        }}
                        value={modal.group.id || ''}
                      >
                        {groupsList.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.title}
                          </MenuItem>
                        ))}
                      </SelectorComponent>
                    </FormControl>
                    <br />
                    <FormControl>
                      <SelectorComponent
                        select
                        label={t('objectFormFieldType')}
                        onChange={(event) => {
                          handleChangeForm('type', event.target.value);
                        }}
                        value={modal.type || ''}
                      >
                        <MenuItem key={staticTypeId} value={staticTypeId}>
                          {t('static')}
                        </MenuItem>
                        <MenuItem key={dynamicTypeId} value={dynamicTypeId}>
                          {t('dynamic')}
                        </MenuItem>
                      </SelectorComponent>
                    </FormControl>
                    <br />
                    {modal.type === dynamicTypeId ? (
                      <FormControl>
                        <SelectorComponent
                          select
                          fullWidth
                          label={t('objectFormFieldDeviceType')}
                          onChange={(event) => {
                            handleChangeForm('deviceType', event.target.value);
                          }}
                          value={modal.deviceType || ''}
                        >
                          <MenuItem key={oldDevice} value={oldDevice}>
                            {t('old')}
                          </MenuItem>
                          <MenuItem key={newDevice} value={newDevice}>
                            {t('new')}
                          </MenuItem>
                        </SelectorComponent>
                        {modal.deviceType === oldDevice ? (
                          <FormControl>
                            <SelectorComponent
                              select
                              label={t('objectFormFieldDevice')}
                              onChange={(event) => {
                                handleChangeForm(
                                  'device',
                                  objectDevices[event.target.value],
                                );
                              }}
                              value={modal.device.id || ''}
                            >
                              {devicesList.map((device) => (
                                <MenuItem key={device.id} value={device.id}>
                                  {device.attributes.title}
                                </MenuItem>
                              ))}
                            </SelectorComponent>
                          </FormControl>
                        ) : (
                          <FormControl>
                            <InputComponent
                              id="new-device-mac"
                              label={t('deviceMac')}
                              value={modal.newDeviceMac}
                              fullWidth
                              onChange={(event) => {
                                handleChangeForm('newDeviceMac', event.target.value);
                              }}
                            />
                          </FormControl>
                        )}
                      </FormControl>
                    ) : null}
                    {modal.type === staticTypeId ? (
                      <FormControl>
                        <SelectorComponent
                          select
                          label={t('objectFormFieldFloor')}
                          onChange={(event) => {
                            handleChangeForm('floor', floorsObject[event.target.value]);
                          }}
                        // eslint-disable-next-line max-len
                          value={(floorsObject[modal.floor.id] && modal.floor.id) || modal.sublocation_id || ''}
                        >
                          {floorsArray.map((floorId) => (
                            <MenuItem key={floorId} value={floorId}>
                              {floorsObject[floorId].title}
                            </MenuItem>
                          ))}
                        </SelectorComponent>
                        <br />
                        <InputComponent
                          id="custom-property-url"
                          label={t('objectFormFieldX')}
                          value={modal.x}
                          fullWidth
                          type="number"
                          onChange={(event) => {
                            handleChangeForm('x', event.target.value);
                          }}
                        />
                        <br />
                        <InputComponent
                          id="custom-property-url"
                          label={t('objectFormFieldY')}
                          value={modal.y}
                          fullWidth
                          type="number"
                          onChange={(event) => {
                            handleChangeForm('y', event.target.value);
                          }}
                        />
                      </FormControl>
                    ) : null}
                    {/* <FormControl>
                  <InputComponent
                    id="custom-property-url"
                    label={t('objectFormFieldJSON')}
                    value={modal.customPropertyUrl}
                    fullWidth
                    onChange={(event) => {
                      handleChangeForm('customPropertyUrl', event.target.value);
                    }}
                  />
                </FormControl>
                <br />
                <FormControl>
                  <InputComponent
                    id="camera-url"
                    label={t('objectFormFieldCamera')}
                    value={modal.cameraUrl}
                    fullWidth
                    onChange={(event) => {
                      handleChangeForm('cameraUrl', event.target.value);
                    }}
                  />
                </FormControl> */}
                  </DialogContent>
                </TabPanel>
                <TabPanel value={valueTabs} index={1}>
                  <DialogContent dividers>
                    <FormControl>
                      <InputTypeFile />
                    </FormControl>
                    <br />
                    <FormControl>
                      <InputComponent
                        id="icon-image-url"
                        label={t('objectFormFieldIcon')}
                        value={modal.iconImageUrl}
                        fullWidth
                        onChange={(event) => {
                          handleChangeForm('iconImageUrl', event.target.value);
                        }}
                      />
                    </FormControl>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FormControl>
                        <InputComponent
                          label={t('colorObject')}
                          value={modal.objectColor}
                          fullWidth
                          InputProps={{
                            style: { color: 'black' },
                          }}
                          onClick={handlePaletteColorObject}
                          disabled
                        />
                      </FormControl>
                      <PaletteIcon onClick={handlePaletteColorObject} C fontSize="large" />
                    </div>
                    {showPaletteColorObject ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ColorPicker
                          width={250}
                          height={228}
                          color={colorObject}
                          onChange={setColorObject}
                          hideRGB
                          hideHSV
                        />
                        <CustomButtonTitle
                          onClick={addColorObject}
                          variant="contained"
                          color="primary"
                        >
                          {t('saveBtn')}
                        </CustomButtonTitle>
                      </div>
                    ) : (
                      null
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <FormControl>
                        <InputComponent
                          label={t('colorTrack')}
                          value={modal.color}
                          fullWidth
                          disabled
                          InputProps={{
                            style: { color: 'black' },
                          }}
                          onClick={handlePaletteColor}
                        />
                      </FormControl>
                      <PaletteIcon onClick={handlePaletteColor} C fontSize="large" />
                    </div>
                    {!showPaletteColorTrack && !showPaletteColorObject
                      ? (
                        <ViewObject
                          color={modal.color}
                          colorObject={modal.objectColor}
                          image={modal.image}
                          iconImageUrl={modal.iconImageUrl}
                        />
                      )
                      : (
                        null)}
                    {showPaletteColorTrack ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ColorPicker
                          width={250}
                          height={228}
                          color={colorTrack}
                          onChange={setColorTrack}
                          hideRGB
                        // hideHEX
                          hideHSV
                        />
                        <CustomButtonTitle
                          onClick={addColorTrack}
                          variant="contained"
                          color="primary"
                        >
                          {t('saveBtn')}
                        </CustomButtonTitle>
                      </div>
                    ) : (null)}
                    <br />
                  </DialogContent>
                </TabPanel>
                <TabPanel value={valueTabs} index={2}>
                  <div className={classes.customPropsContainer}>
                    <FormControl>
                      <TextField
                        className={classes.customProps}
                        id="object-title"
                        label="Property"
                        type="text"
                        value={keyProps || ''}
                        onChange={(event) => {
                          handleCustomKeyChangeForm('a', 'b', event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        className={classes.customProps}
                        id="object-title"
                        label="Value"
                        type="text"
                        value={valueProps || ''}
                        onChange={(event) => {
                          handleCustomValueChangeForm('title', event.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <div className={classes.btnCusotmContainer}>
                      <IconButton
                        disabled={!keyProps || !valueProps}
                        onClick={addProperty}
                        className={classes.btnCustomProps}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    {customProps.map((prop, index) => {
                      const key = index;
                      return (
                        <div key={key} className={classes.customPropsContainer}>
                          <FormControl>
                            <TextField
                              className={classes.customProps}
                              disabled
                              id="object-title"
                              label="Property"
                              type="text"
                              onChange={(event) => {
                                handleCustomKeyChangeForm(prop, index, event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                              value={prop.key}
                            />
                          </FormControl>
                          <FormControl>
                            <TextField
                              disabled
                              value={prop.value}
                              className={classes.customProps}
                              id="object-title"
                              label="Value"
                              type="text"
                              onChange={(event) => {
                                handleCustomValueChangeForm('title', event.target.value);
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                          <div className={classes.btnCusotmContainer}>
                            <IconButton
                              onClick={() => removeProps(index)}
                              className={classes.btnCustomProps}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </div>

                        </div>
                      );
                    })}
                  </div>
                  {modal.properties.map((i, index) => {
                    const key = index + 1;
                    return (
                      <div key={key} className={classes.customPropsContainer}>
                        <FormControl>
                          <TextField
                            className={classes.customProps}
                            id="object-title"
                            label="Property"
                            value={i.key}
                            type="text"
                            onChange={(event) => {
                              changeProperties(i, event.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            className={classes.customProps}
                            id="object-title"
                            label="Value"
                            type="text"
                            value={i.value}
                          // disabled
                            onChange={(event) => {
                              changeValues(i, event.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <div className={classes.btnCusotmContainer}>
                          <IconButton
                            onClick={() => deleteProperty(i.id)}
                            className={classes.btnCustomProps}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>

                        <br />
                      </div>
                    );
                  })}
                </TabPanel>
              </div>
            </div>
            <DialogActions>
              <CustomButton onClick={handleClose} color="secondary">
                {t('cancelBtn')}
              </CustomButton>
              <CustomButton
                disabled={chechFormValidation()}
                onClick={handleCreateObject}
                color="primary"
                type="submit"
              >
                {modal.formType === formTypeCreate
                  ? t('createBtn')
                  : t('saveBtn')}
                {isFetchingCreateObject ? (
                  <div className={classes.spinerWrapper}>
                    <CircularProgress size={26} />
                  </div>
                ) : null}
              </CustomButton>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    objectsByAppArray,
    isObjectsByAppFetch,
    isFetchingCreateObject,
    isFetchingDeleteObject,
    currentObject,
  } = state.objects;
  const {
    openModal,
    modal,
    objectToDelete,
    openDeleteModal,
    openDeleteDeviceModal,
    customProps,
  } = state.settings;
  const { groupsObject, groupsListInSettings } = state.groups;
  const { devicesList } = state.devices;
  const { floorsObject, currentFloor } = state.floor;
  const { currentApp, openMenu } = state.app;

  return {
    isObjectsByAppFetch,
    isFetchingCreateObject,
    isFetchingDeleteObject,
    objectsByAppArray,
    openModal,
    modal,
    groupsObject,
    groupsListInSettings,
    devicesList,
    floorsObject,
    currentFloor,
    currentApp,
    objectToDelete,
    openDeleteModal,
    currentObject,
    openDeleteDeviceModal,
    openMenu,
    customProps,
  };
}

export default connect(mapStateToProps)(Objects);
