import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const InputComponent = withStyles(() => ({
  root: {
    width: '256px',
    margin: '7px 0',
  },
}))(TextField);

export default InputComponent;
