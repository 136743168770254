/*eslint-disable*/
import React from "react";

import { useTranslation } from "react-i18next";

// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";

import StyledSelector from './MaterialOutlinedSelector'

import { allValuesConstant, noValuesConstant, selectNoOneConstant } from 'constans';

export default function OutlinedSelector(props) {
  const { allowNoValues,
    allowSelectNoValues,
    allowAllValues,
    className,
    children, ...rest } = props;
  const { t } = useTranslation(["monitoring"]);

  return (
    <StyledSelector
      select
      id={props.id}
      label={props.label}
      value={props.value || ''}
      onChange={props.onChange}
      className={className}
      {...rest}
    >
      {allowNoValues ? (
        <MenuItem key={noValuesConstant} value={noValuesConstant}>
          {props.novalueslabel}
        </MenuItem>) : null}
      {allowAllValues ? (
        <MenuItem key={allValuesConstant} value={allValuesConstant}>
          {t("all")}
        </MenuItem>) : null}
      {allowSelectNoValues ? (
        <MenuItem key={selectNoOneConstant} value={selectNoOneConstant}>
          {t("noOne")}
        </MenuItem>) : null}
      {children}
    </StyledSelector>
  );
}
