import request, { endPointsEnum } from 'services/http';
import { createAlert } from './alert';

export const SET_HISTORY_LIST = 'SET_HISTORY_LIST';
export const FETCH_HISTORY_END = 'FETCH_HISTORY_END';
export const FETCH_HISTORY_IN_PROGRESS = 'FETCH_HISTORY_IN_PROGRESS';
export const RESET_HISTORY_LIST = 'RESET_HISTORY_LIST';

export const setHistoryList = (historyArray, objectsList) => ({
  type: SET_HISTORY_LIST,
  historyArray,
  objectsList,
});

export const resetHistoryList = () => ({
  type: RESET_HISTORY_LIST,
});

export const setFetchHistoryEnd = () => ({
  type: FETCH_HISTORY_END,
});

export const fetchHistoryInProgress = () => ({
  type: FETCH_HISTORY_IN_PROGRESS,
});

const dateToSec = (date) => Math.round((date / 1000) - ((date / 1000) % 60));
// eslint-disable-next-line consistent-return
export const getHistoryList = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dateFrom, dateTo } = state.reports;
    const { currentFloor } = state.floor;
    const { selectedGroup } = state.groups;
    const querryParrams = {
      api_key: state.app.currentApp.api_key,
      from: dateToSec(dateFrom.getTime()),
      to: dateToSec(dateTo.getTime()),
      'filter[sublocation.id]': currentFloor.id,
    };

    if (selectedGroup && selectedGroup.id > 0) {
      querryParrams['filter[group.id]'] = selectedGroup.id;
    }

    dispatch(fetchHistoryInProgress());

    const response = await request.tracking.get(
      endPointsEnum.heatmapHistory, { params: querryParrams },
    );

    const historyArray = response.data;
    const objectsArray = response.included;
    const objectsList = {};
    objectsArray.map((object) => {
      objectsList[object.id] = object;

      return object;
    });
    Object.keys(historyArray).forEach((frameID) => {
      if (frameID < 0) {
        delete historyArray[frameID];
      }
    });

    dispatch(setHistoryList(historyArray, objectsList));
    dispatch(setFetchHistoryEnd());
    return historyArray;
  } catch (error) {
    dispatch(setFetchHistoryEnd());
    dispatch(createAlert('error', error.message));
  }
};
