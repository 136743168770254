import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    whitelist: ['en', 'ru', 'et', 'ch', 'jp'],
    interpolation: {
      escapeValue: false,
    },
    preload: ['en', 'ru', 'et', 'ch', 'jp'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
    },
  });

export default i18n;
