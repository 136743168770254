import Image from 'assets/images/welcomeLogo/mts/logo.svg';

import faviconImage from 'assets/images/favicons/mts/favicon.ico';

import MtsRegular from 'assets/fonts/font-mts/MTSSans-Regular.woff';
import MtsLight from 'assets/fonts/font-mts/MTSSans-Medium.woff';
import MtsBold from 'assets/fonts/font-mts/MTSSans-Bold.woff';

const mtsRegular = {
  fontFamily: 'Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${MtsRegular}') format('woff')`,
  ].join(','),
};

const light = {
  fontFamily: 'Light',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${MtsLight}') format('woff')`,
  ].join(','),
};
const bold = {
  fontFamily: 'Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: [
    'local(\'SalesforceSans\')',
    'local(\'SalesforceSans-Regular\')',
    `url('${MtsBold}') format('woff')`,
  ].join(','),
};

const favicon = document.getElementById('favicon');

favicon.href = faviconImage;

const theme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [mtsRegular, light, bold],
      },
    },
  },
  palette: {
    primary: {
      main: '#E30611',
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#f57c00',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#0082C8',
    },
    success: {
      main: '#AAC613',
    },
  },
  CustomStyles: {
    LogoImageBackground: `url(${Image}) center no-repeat`,
    MainBackgroundColor: '#F2F3F7',
    GlobalTextColor: '#001424',
  },
};

export default theme;
