import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  threeD: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  sceneElement: {
    width: '100%',
    height: '100%',
  },
  mapControls: {
    width: '100%',
    background: materialTheme.CustomStyles.MainBackgroundColor,
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  control_wrapper: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
  swich_element_monitoring: {
    marginLeft: '9px',
  },
}));

export default useStyles;
