import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { createAlert } from 'actions/alert';
import Grid from '@material-ui/core/Grid';
import WelcomeLogo from 'components/login/logo/logo';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { monitoring } from 'constans';

import {
  loginFetch,
  setLogin,
  setPassword,
  fetchFinished,
  setShowPassword,
} from 'actions/auth';
import useStyles from './styles';

const Login = (props) => {
  const { t } = useTranslation(['login', 'translation']);
  const history = useHistory();
  const {
    login, password, showPassword,
    dispatch, isFetching,
  } = props;
  const classes = useStyles();

  const router = (path) => {
    history.push(path);
  };

  const onClickLogin = (event) => {
    event.preventDefault();
    if (login && password) {
      dispatch(loginFetch(login, password))
        .then(() => {
          router(monitoring);
          dispatch(setPassword(''));
          dispatch(setLogin(''));
        })
        .catch((err) => {
          dispatch(fetchFinished());
          dispatch(setPassword(''));
          if (err.httpStatus === 400) {
            dispatch(createAlert('error', t('loginWrongError')));
          } else if (err.httpStatus === 500) {
            dispatch(createAlert('error', t('translation:serverError')));
          } else {
            dispatch(createAlert('error', err.message));
          }
        });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classes.logo}>
        <WelcomeLogo />
      </div>
      <form onSubmit={onClickLogin} className={classes.loginForm}>
        <Grid
          className={clsx(classes.FormItemGrid)}
          container
          spacing={0}
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <div
              className={clsx(classes.FormLoginIcon, classes.FormItemGridFirst)}
            />
          </Grid>
          <Grid item>
            <FormControl margin="none" className={clsx(classes.textField, classes.textFieldLogin)}>
              <InputLabel
                htmlFor="standard-adornment-login"
              >
                {t('login')}
              </InputLabel>
              <Input
                margin="none"
                disableUnderline
                autoComplete="email"
                id="standard-adornment-login"
                type="email"
                className={clsx(classes.Inputs)}
                value={login}
                onChange={(event) => {
                  dispatch(setLogin(event.target.value));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.FormItemGrid)}
          container
          spacing={0}
          justify="flex-start"
          alignItems="center"
        >
          <Grid
            onClick={() => {
              dispatch(setShowPassword(!showPassword));
            }}
            onMouseDown={handleMouseDownPassword}
            item
          >
            <div className={showPassword ? clsx(classes.FormPasswordIcon)
              : clsx(classes.FormPasswordIcon)}
            />
          </Grid>
          <Grid item>
            <FormControl margin="none" className={clsx(classes.textField)}>
              <InputLabel
                className={clsx(classes.Inputs)}
                htmlFor="standard-adornment-password"
              >
                {t('password')}
              </InputLabel>
              <Input
                margin="none"
                disableUnderline
                autoComplete="current-password"
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                className={clsx(classes.Inputs)}
                value={password}
                onChange={(event) => {
                  dispatch(setPassword(event.target.value));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                dispatch(setShowPassword(!showPassword));
              }}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Grid>
        </Grid>
        <FormControl>
          <Button
            className={clsx(classes.buttonLogin)}
            onClick={onClickLogin}
            type="submit"
            disabled={!login || !password || isFetching}
            variant="contained"
          >
            {t('login:loginBtn')}
          </Button>
        </FormControl>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    login, password, showPassword, isFetching,
  } = state.auth;

  return {
    login,
    password,
    showPassword,
    isFetching,
  };
}

export default connect(mapStateToProps)(Login);
