import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => (
  {
    appListContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    list: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontFamily: materialTheme.fontRegular,
      width: '150px',
      marginLeft: '10px',
      minWidth: '130px',
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    btn: {
      color: 'black',
      '&:hover': {
        opacity: 1,
      },
    },
    large: {
      width: materialTheme.spacing(7),
      height: materialTheme.spacing(7),
    },
    text: {
      color: materialTheme.CustomStyles.GlobalTextColor,
      fontFamily: materialTheme.fontRegular,
      fontWeight: 'normal',
    },
    unviewedNotification: {
      color: materialTheme.palette.primary.main,
      '&::before': {
        content: '"*"',
        color: materialTheme.palette.primary.main,
        marginRight: '3px',
        fontWeight: 'bold',
      },
    },
  }
));

export default useStyles;
