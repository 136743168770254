import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from 'helpers/checkAuth';

import { makeStyles } from '@material-ui/core/styles';

import PrivateComponent from 'components/private.component';
import Header from './header/header';

const styles = makeStyles({
  privateComponent: {
    width: '100vw',
    height: 'calc(100vh - 80px)',
  },
});

function PrivateRoute({ children, ...rest }) {
  const isAuthanticated = isAuth();
  const classNames = styles();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => (isAuthanticated ? (
        <PrivateComponent>
          <Header />
          <div className={classNames.privateComponent}>
            {children}
          </div>
        </PrivateComponent>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default PrivateRoute;
