import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import { setDateFrom, setDateTo } from 'actions/reports';
import StyledDataPicker from './styledDataPicker';

const useStyles = makeStyles({
  wrapper: {
    paddingTop: '1vh',
    // marginLeft: '2vw',
  },
  defaultInputStyles: {
    marginRight: '2vw',
  },
  '@media screen and (max-width: 1123px)': {
    wrapper: {
      marginLeft: '0px',
    },
  },
  secondWrapper: {
    paddingTop: '1vh',
  },
  history: {
    marginLeft: '20px',
  },
  defaultLabelStyles: {
    marginLeft: '0px',
  },
});

const DataPickers = (props) => {
  const classes = useStyles();
  const datePickerLocales = {
    ru: ruLocale,
    en: enLocale,
    et: etLocale,
  };
  const { t, i18n } = useTranslation(['report']);

  const datePickerLocale = datePickerLocales[i18n.language];
  const {
    dateFrom, dateTo, dispatch, labelStyles, inputStyles,
  } = props;

  // eslint-disable-next-line
  const dateFormat = `dd LLL yyyy HH:mm`;
  const handleDateChangeFrom = (date) => {
    dispatch(setDateFrom(date));
  };
  const handleDateChangeTo = (date) => {
    dispatch(setDateTo(date));
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datePickerLocale}>
      <div className={classes.wrapper}>
        <div className={labelStyles || classes.defaultLabelStyles}>{t('dateStart')}</div>
        <StyledDataPicker
          className={inputStyles || classes.defaultInputStyles}
          value={dateFrom}
          onChange={handleDateChangeFrom}
          ampm={false}
          disableFuture
          format={dateFormat}
          maxDate={dateTo}
          strictCompareDates
          maxDateMessage={t('maxDateMessage')}
          autoOk
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="pick a date"
                >
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.secondWrapper}>
        {/* <div className={speed ? classes.history : classes.reports}>{t('dateFinish')}</div> */}
        <div className={labelStyles || classes.defaultLabelStyles}>{t('dateFinish')}</div>
        <StyledDataPicker
          className={classes.to}
          value={dateTo}
          onChange={handleDateChangeTo}
          format={dateFormat}
          disableFuture
          minDate={dateFrom}
          ampm={false}
          minDateMessage={t('minDateMessage')}
          strictCompareDates
          autoOk
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="pick a date"
                >
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

function mapStateToProps(state) {
  const { dateFrom, dateTo } = state.reports;

  return {
    dateFrom,
    dateTo,
  };
}

export default connect(mapStateToProps)(DataPickers);
