import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  content: {
    width: 'calc(100vw - 30vh)',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 45px 0 32px',
    overflowY: 'auto',
    [materialTheme.breakpoints.down('800')]: {
      width: '90%',
      marginLeft: '-1vw',
    },
  },
  iconCancel: {
    float: 'right',
    marginLeft: '-50px',
    fontSize: '20px',
    color: 'black',
    '&:hover': {
      color: '#f44336',
    },
  },
  table: {
    width: '100%',
  },
  topControls: {
    marginTop: '16px',
  },
  spinerWrapper: {
    width: '100%',
    maxHeight: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tabsMainWrapper: {
    maxWidth: '360px',
  },
  tabContainer: {
    margin: '0 auto',
    maxWidth: '305px',
  },
  tabLabel: {
    color: 'black',
    fontWeight: '600',
    opacity: 0.4,
  },
  wrappApp: {
    background: 'black',
    boxShadow: '0px 0px 0px 0px grey',
  },
  tabsLabel: {
    maxWidth: '400px',
    background: '#fff',
    '& .MuiTab-root': {
      minWidth: '100px',
    },
  },
  customPropsContainer: {
    display: 'flex',
    margin: '0 auto',
    width: '300px',
  },
  customProps: {
    maxWidth: '150x',
    marginLeft: '10px',
    marginTop: '10px',
  },
  btnCusotmContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  btnCustomProps: {
    '&:hover': {
      cursor: 'pointer',
      color: materialTheme.palette.primary.main,
    },
  },
  btnSetColor: {
    zIndex: '1000000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  multilineColor: {
    color: 'green',
  },
  idTitle: {
    color: 'grey',
    fontSize: '0.9rem',
  },
}));

export default useStyles;
