/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import OpenLayersMap from '@navigine/openlayers-navigine';
import { setLocation } from 'actions/locations';
import { setFloor } from 'actions/floors';
import { setSelectedZone, fetchZoneTypesIfNeeded } from 'actions/zones';
import { setSelectedGroup } from 'actions/groups';
import { useTranslation } from 'react-i18next';

import DataPickers from 'components/reports/datePickers/datePickers.component';
import BuildButton from 'components/reports/BuildButton.component';
import {
  allValuesConstant, selectNoOneConstant, token, styleUrl,
} from 'constans';
import { getHistoryList, resetHistoryList } from 'actions/history';
import PauseIcon from '@material-ui/icons/Pause';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// import { Button } from '@material-ui/core';
import LocationSelector from 'components/shared/LocationSelector/LocationSelector';
import FloorSelector from 'components/shared/FloorSelector/FloorSelector';
// import ZoneSelector from 'components/shared/ZonesSelector/ZonesSelector';
import GroupSelector from 'components/shared/GroupSelector/GroupSelector';
import MainMenu from 'components/menu/MainMenu';
import ObjectsList from 'components/history/objectsList';
import useStyles from './style';

const History = (props) => {
  const { dispatch } = props;
  const { t } = useTranslation(['monitoring', 'history']);
  const {
    locationsObject,
    currentLocation,
    floorsObject,
    currentFloor,
    selectedGroup,
    groupsObject,
    zonesObject,
    selectedZone,
    zoneTypes,
    history: frames,
    objectsList,
    isFetchingHistory,
    currentApp,
    dateFrom,
    dateTo,
    openMenu,
  } = props;
  const classes = useStyles();

  const [map, setMap] = useState({});
  const [playing, setPlaying] = useState(false);
  const [currentSecond, setCurrentSecond] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [speed, setSpeed] = useState(1);
  const [playedPersantage, setPlayedPersantage] = useState(0);
  const [timeTimeout, setTimeTimeout] = useState(null);
  const [showTrackLines, setShowTrackLines] = useState(true);
  const mapElement = useRef();

  const [appTtl, setAppTtl] = useState(60);

  const frameKeys = Object.keys(frames);

  const [totalTime, setTotalTime] = useState(1);

  const zonesArray = Object.keys(zonesObject)
    .filter((zoneId) => parseInt(zonesObject[zoneId].floor, 10)
  === parseInt(currentFloor.id, 10));

  useEffect(() => {
    setTotalTime(parseInt(((dateTo.getTime() / 1000)
    - ((dateFrom.getTime() / 1000))), 10));
  }, [dateTo, dateFrom]);

  const stopPlayer = () => {
    setPlaying(false);
    clearTimeout(timeTimeout);
  };

  const resetState = () => {
    stopPlayer();
    setCurrentSecond(0);
    setPlayedPersantage(0);
    if (map instanceof OpenLayersMap) {
      map.addTrackedObjects([]);
      map.removeTrackHistory();
    }
  };

  useEffect(() => {
    const fetchZoneTypes = async () => {
      await dispatch(fetchZoneTypesIfNeeded());
    };
    fetchZoneTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor.id]);

  useEffect(() => {
    resetState();
    const { properties } = currentApp;
    const appTtlProp = properties.filter((prop) => prop.type === 'object_ttl')[0];
    let objTtl = 60;
    if (appTtlProp) {
      objTtl = parseInt(appTtlProp.value, 10);
    }
    setAppTtl(objTtl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  useEffect(() => {
    if (map instanceof OpenLayersMap) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      map.destroyMap();
    }

    try {
      const translate = {
        global: t('global'), local: t('local'), street: t('street'), satellite: t('satellite'), img: t('img'), noImg: t('noImg'),
      };
      const {
        lat, lon, hor, w, h,
      } = currentFloor;
      const newMap = new OpenLayersMap({
        target: mapElement.current,
        imageURL: currentFloor.image_url,
        imageWidth: currentFloor.pw,
        imageHeight: currentFloor.ph,
        imageBounds: currentFloor.image_bounds,
        lat,
        lon,
        w,
        h,
        hor,
        isGlobalMap: false,
        token,
        styleUrl,
        translate,
      });
      const { clientWidth, clientHeight } = mapElement.current;
      if (!clientWidth || !clientHeight) {
        const resize = (entires, observer) => {
          newMap.map.updateSize();
          observer.unobserve(mapElement.current);
        };
        new ResizeObserver(resize).observe(mapElement.current);
      }
      setMap(newMap);
    } catch (error) {
      setMap({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor.id]);

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };
  // const handleChangeZone = (event) => {
  //   event.preventDefault();
  //   const zoneId = event.target.value;
  //   map.deleteAllFeaturesFromSourse(map.enum.zones);

  //   if (zoneId === allValuesConstant) {
  //     const zones = zonesArray.map((zoneID) => zonesObject[zoneID]);
  //     dispatch(setSelectedZone({ id: allValuesConstant }));

  //     map.addZones(zones);
  //     return;
  //   }

  //   if (zoneId === selectNoOneConstant) {
  //     dispatch(setSelectedZone({ id: selectNoOneConstant }));
  //   }

  //   if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
  //     dispatch(setSelectedZone(zonesObject[zoneId]));
  //     map.addZone(zonesObject[zoneId]);
  //     map.zoomToFeature(map.getZoneFeature(zoneId));
  //   }
  // };

  const handleChangeGroup = (event) => {
    event.preventDefault();
    const groupId = event.target.value;
    if (groupId === allValuesConstant) {
      dispatch(setSelectedGroup({ id: allValuesConstant }));
      return;
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const fetchHistory = async () => {
    dispatch(resetHistoryList());
    resetState();
    await dispatch(getHistoryList());
    /*
    if(frameKeys.length < 1){
      dispatch(createAlert(alertTypes.warn, t("history:empryData")))
      return
    }
    dispatch(createAlert(alertTypes.info, t("history:playStart")))
    setPlaying(true);
    */
  };

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    if (zoneTypes) {
      map.setZoneTypes(zoneTypes);
    }

    if (selectedZone && selectedZone.id) {
      if (selectedZone.id === allValuesConstant) {
        const zones = zonesArray.map((zoneID) => zonesObject[zoneID]);
        map.addZones(zones);
      } else if (selectedZone.id > 0) {
        map.addZone(zonesObject[selectedZone.id]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoneTypes]);

  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }
    if (zoneTypes) {
      map.setZoneTypes(zoneTypes);
    }

    if (selectedZone && selectedZone.id) {
      if (selectedZone.id === allValuesConstant) {
        const zones = zonesArray.map((zoneID) => zonesObject[zoneID]);
        map.addZones(zones);
      } else if (selectedZone.id > 0) {
        map.addZone(zonesObject[selectedZone.id]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, zoneTypes]);

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  const renderTrackedObjects = async (frame = [], frameID) => {
    const trackedObjects = frame.map((frameEl) => ({
      attributes: {
        kx: parseFloat(frameEl.kx),
        ky: parseFloat(frameEl.ky),
        file_url: objectsList[frameEl.id].file_url,
        title: objectsList[frameEl.id].title,
        color: objectsList[frameEl.id].color,
      },
      id: frameEl.id,
      lastFrame: frameEl.lastFrame || frameID,
    }));
    const trackedObjectsOnMap = map.getAllTrackedObjects();

    if (trackedObjectsOnMap.length > 0) {
      const updatedObjects = {};
      const promiseArr = trackedObjects.map(async (currentObject) => {
        const objectOnMap = map.getFeaturePayload(
          map.getTrackedObjectFeature(currentObject.id),
        );
        if (!objectOnMap) {
          await map.addTrackedObject(currentObject);
          return;
        }

        await objectOnMap.updateTrackedObject(currentObject);
        updatedObjects[currentObject.id] = true;
      });

      await Promise.all(promiseArr);

      trackedObjectsOnMap.forEach((featureOnMap) => {
        const currentObjectId = featureOnMap.getId();
        if (!updatedObjects[currentObjectId]) {
          const objectOnMap = map.getFeaturePayload(
            map.getTrackedObjectFeature(currentObjectId),
          );
          const {
            lastFrame,
          } = objectOnMap?.trackedObject;

          if (frameID - lastFrame > appTtl) {
            map.deleteTrackedObject(featureOnMap);
          }
        }
      });
    } else {
      map.addTrackedObjects(trackedObjects);
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const currentFrame = frames[currentSecond];
      if (currentSecond >= totalTime) {
        setPlaying(false);
        setPlayedPersantage(100);
        return;
      }
      await renderTrackedObjects(currentFrame, currentSecond);
      setTimeTimeout(setTimeout(() => {
        setCurrentSecond(currentSecond + 1);
      }, 1000 / speed));
      setPlayedPersantage(totalTime ? ((currentSecond / (totalTime)) * 100) : 0);
    };

    if (playing) {
      asyncFn();
    }
    if (map instanceof OpenLayersMap) {
      if (showTrackLines) {
        map.addTrackHistory();
      } else {
        map.removeTrackHistory();
      }
    }
  }, [currentSecond, frames, playing, speed]);

  const moveTo = async (event) => {
    clearTimeout(timeTimeout);
    const frameKeysCopy = Object.keys(frames);

    if (!frameKeysCopy.length) {
      return;
    }
    // to - from
    const clientRect = event.currentTarget.getBoundingClientRect();
    const moveToSecond = Math.floor(((event.clientX - clientRect.left) / clientRect.width)
    * totalTime); // total = to - from

    const renderFrameKeys = new Map();
    frameKeysCopy
      .filter((frameID) => frameID < moveToSecond && (moveToSecond - frameID) < appTtl)
      .forEach((frameID) => renderFrameKeys.set(frameID, frames[frameID]));
    const moveToObject = {};

    renderFrameKeys.forEach((leftFrame, leftFrameId) => {
      leftFrame.forEach((frameEl) => {
        moveToObject[frameEl.id] = { ...frameEl, lastFrame: leftFrameId };
      });
    });

    const trackedObjType = map.enum.trackedObjects;
    const trackLineType = map.enum.trackLines;
    map.deleteAllFeaturesFromSourse(trackedObjType);
    map.deleteAllFeaturesFromSourse(trackLineType);

    const moveToFrame = Object.values(moveToObject);
    if (moveToFrame.length > 0) {
      await renderTrackedObjects(moveToFrame, moveToSecond);
    }

    setCurrentSecond(moveToSecond);
    setPlayedPersantage(totalTime ? ((moveToSecond / (totalTime)) * 100) : 0);
  };

  // track lines callback
  const handleShowTrackLines = (event) => {
    const { checked } = event.target;
    setShowTrackLines(checked);
  };

  // track lines hook
  useEffect(() => {
    if (!(map instanceof OpenLayersMap)) {
      return;
    }

    if (showTrackLines) {
      map.addTrackHistory();
    } else {
      map.removeTrackHistory();
    }
  }, [showTrackLines, map]);

  const toggleReplay = () => {
    resetState();
    togglePlaying();
  };
  const countIncrement = () => {
    if (speed < 16) {
      const count = speed * 2;
      setSpeed(count);
    }
  };
  const countDecrement = () => {
    setSpeed(1);
  };

  return (
    <div className={!openMenu ? classes.history : classes.noneContainer}>
      <MainMenu openMenu={openMenu} dispatch={dispatch}>
        <div className={classes.mapControls}>
          <div className={classes.control_wrapper}>
            <LocationSelector
              locations={locationsObject}
              value={currentLocation.id}
              onChange={handleChangeLocation}
            />
          </div>
          <div className={classes.control_wrapper}>
            <FloorSelector
              floors={floorsObject}
              className={classes.selector}
              onChange={handleChangeFloor}
              value={currentFloor.id}
              currentLocationId={currentLocation.id}
            />
            {/* <div className={classes.control_wrapper}>
              <ZoneSelector
                onChange={handleChangeZone}
                zones={zonesObject}
                value={selectedZone.id}
                zoneTypes={zoneTypes}
                currentFloorId={currentFloor.id}
              />
            </div> */}
            <div className={classes.control_wrapper}>
              <GroupSelector
                onChange={handleChangeGroup}
                groups={groupsObject}
                value={selectedGroup.id}
              />
            </div>
            <div className={classes.control_wrapper}>
              <FormControlLabel
                className={classes.swich_element}
                control={(
                  <Switch
                    checked={showTrackLines}
                    onChange={handleShowTrackLines}
                    name="show track lines"
                    color="primary"
                  />
                )}
                label={t('showTrackLines')}
              />
            </div>
          </div>
          <DataPickers
            labelStyles={classes.historyDate}
            inputStyles={classes.historyInput}
          />
          <BuildButton
            className={classes.btn}
            variant="outlined"
            color="primary"
            disableRipple
            onClick={fetchHistory}
            disabled={isFetchingHistory}
          >
            {isFetchingHistory && <CircularProgress size={18} />}
            {t('history:apply')}
          </BuildButton>
          {/* <div className={classes.objectsCount}>
            <p>
              {' '}
              {t('countOfObjects')}
              {' '}
              <b>{Object.values(objectsList).length}</b>
            </p>
          </div> */}

          <ObjectsList
            objectsList={objectsList}
            showTrackLines={showTrackLines}
          />

        </div>
      </MainMenu>
      <div ref={mapElement} className={classes.map_container}>
        <div className={classes.player}>
          <div
            className={classes.timeLine}
            tabIndex="-5"
            role="button"
            onKeyDown={moveTo}
            onClick={moveTo}
          >
            <div className={classes.line} style={{ width: `${Math.round(playedPersantage * 10) / 10}%` }} />
            <div className={classes.carret} style={{ left: `${Math.round(playedPersantage * 10) / 10}%` }} />
          </div>
          <div className={classes.playBtn}>
            { playedPersantage < 100
              ? (
                <BuildButton disabled={frameKeys.length < 1} onClick={togglePlaying}>
                  {playing ? <PauseIcon />
                    : <PlayCircleFilledIcon />}
                </BuildButton>
              ) : (
                <BuildButton disabled={frameKeys.length < 1} onClick={toggleReplay}>
                  <ReplayIcon />
                </BuildButton>
              )}

          </div>
          {/* <div className={classes.speedControls}>
            {
              [' ', 1].map((renderSpeed) => (
                <span
                  key={renderSpeed}
                  className={`${classes.speedBtn} ${parseInt(speed, 10) === parseInt(renderSpeed, 10) ? classes.activeSpeedBtn : ''}`}
                  onClick={() => setSpeed(renderSpeed)}
                  role="button"
                  onKeyUp={() => setSpeed(renderSpeed)}
                  tabIndex="0"
                >
                  {renderSpeed}
                  x
                </span>
              ))
            }
          </div> */}
          <div role="button" tabIndex={-1} className={classes.speedBtn} variant="outlined" onClick={countDecrement}>
            <button className={classes.btnEvent} type="button">
              1x
            </button>
          </div>
          <div role="button" tabIndex={-1} className={classes.speedBtn} variant="outlined" onClick={countIncrement}>
            <button className={classes.btnEvent} type="button">
              &gt;&gt;
            </button>
          </div>
          <div className={classes.description}>
            {`${t('time')}: `}
            <div className={classes.descriptionTime}>
              <p>
                {format(Math.round(dateFrom.getTime()) + currentSecond * 1000, 'dd.MM.yyyy HH:mm:ss')}
              </p>
            </div>
            {`${t('speed')}: ${speed}x`}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    locationsObject,
    currentLocation,
    isFetching: isFetchingLocations,
  } = state.location;
  const { zonesObject, selectedZone, zoneTypes } = state.zones;
  const { dateFrom, dateTo } = state.reports;
  const { groupsObject, selectedGroup } = state.groups;
  const { history, objectsList, isFetching: isFetchingHistory } = state.history;
  const {
    floorsObject,
    currentFloor,
    isFetching: isFetchingFloors,
  } = state.floor;
  const { currentApp, openMenu } = state.app;

  return {
    locationsObject,
    currentLocation,
    isFetchingLocations,
    floorsObject,
    currentFloor,
    isFetchingFloors,
    groupsObject,
    selectedGroup,
    objectsList,
    zonesObject,
    zoneTypes,
    selectedZone,
    history,
    isFetchingHistory,
    currentApp,
    dateFrom,
    dateTo,
    openMenu,
  };
}
export default connect(mapStateToProps)(History);
