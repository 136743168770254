const distanceFormat = (dist, t) => {
  if (dist <= 0) {
    return `0 ${t('m')}`;
  }
  const km = Math.trunc(dist / 1000);
  const m = dist % 1000;
  return `${km > 0 ? `${km} ${t('km')}` : ''} ${m > 0 ? `${parseFloat((m).toFixed())} ${t('m')}` : ''}`;
};

export default distanceFormat;
