import request, { endPointsEnum } from 'services/http';
import { createAlert } from './alert';

export const SET_ZONES = 'SET_ZONES';
export const FETCHED_ZONES = 'FETCHED_ZONES';
export const SET_SELECTED_ZONE = 'SET_SELECTED_ZONE';
export const SET_SELECTED_ZONE_TYPE = 'SET_SELECTED_ZONE_TYPE';
export const FETCHED_ZONES_TYPES = 'FETCHED_ZONES_TYPES';
export const FETCHE_ZONES_IN_PROGRESS = 'FETCHE_ZONES_IN_PROGRESS';

export const setZones = (zonesObject) => ({
  type: SET_ZONES,
  zonesObject,
});

export const setSelectedZone = (zone) => ({
  type: SET_SELECTED_ZONE,
  zone,
});

export const fetchedFinished = () => ({
  type: FETCHED_ZONES,
});

export const fetchZonesInProgress = () => ({
  type: FETCHE_ZONES_IN_PROGRESS,
});

export const setSelectedZoneType = (zoneType) => ({
  type: FETCHED_ZONES_TYPES,
  zoneType,
});

export const setZoneTypes = (zoneTypes) => ({
  type: FETCHED_ZONES_TYPES,
  zoneTypes,
});

export const fetchZoneTypesIfNeeded = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (!state.floor.currentFloor || !state.floor.currentFloor.id || !state.user.hash) {
      return;
    }
    if (Object.entries(state.zones.zoneTypes).length > 1) {
      return;
    }
    const params = {
      userHash: state.user.hash,
      sublocationId: state.floor.currentFloor.id,
    };

    dispatch(fetchZonesInProgress());
    const response = await request.client.get(endPointsEnum.zonesGetAll, { params });

    const typesArray = response.types;
    const typesObject = {};
    typesArray.map((type) => {
      typesObject[type.id] = type;

      return type;
    });

    dispatch(fetchedFinished());
    dispatch(setZoneTypes(typesObject));
  } catch (error) {
    dispatch(fetchedFinished());
    dispatch(createAlert('error', error.message));
  }
};

export const fetchZones = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
    };

    dispatch(fetchZonesInProgress());

    const response = await request.client.get(endPointsEnum.zonesGetAllByApp, { params });
    const zonesArray = response.zones;
    const zonesObject = {};
    zonesArray.map((zones) => {
      zonesObject[zones.id] = {
        id: zones.id,
        title: zones.title,
        color: zones.color,
        points: zones.coordinates,
        object_type: zones.object_type,
        floor: zones.sublocation_id,
        zoneTypeID: zones.zone_type,
      };

      return zones;
    });
    dispatch(fetchedFinished());
    dispatch(setZones(zonesObject));
  } catch (error) {
    dispatch(fetchedFinished());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert('error', error.message));
    }
  }
};
