import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import MenuItem from '@material-ui/core/MenuItem';

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector';
import { noValuesConstant } from 'constans';

export default function GroupSelector(props) {
  const {
    className, allowAllValues,
    allowSelectNoValues, groups,
    loading, value, label, onChange, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const groupsArray = Object.keys(groups);

  const currentValue = groupsArray.length > 0
        && value ? value : noValuesConstant;

  return (
    <OutlinedSelector
      id="select-group"
      className={className}
      label={t('groupsSelectorLabel')}
      novalueslabel={currentValue === noValuesConstant ? t('noGroups') : null}
      allowNoValues={currentValue === noValuesConstant}
      allowAllValues={allowAllValues || true}
                // allowSelectNoValues={allowSelectNoValues || true}
      value={currentValue}
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {groupsArray.map((groupId) => (
        <MenuItem key={groupId} value={groupId}>
          {groups[groupId].title}
        </MenuItem>
      ))}
    </OutlinedSelector>
  );
}
