import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const buildReportButton = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid #edeff3',
      color: materialTheme.CustomStyles.GlobalTextColor,
      borderRadius: 0,
      lineHeight: 1.5,
      backgroundColor: '#ffffff',
      fontFamily: fontRegular,
      '&:hover': {
        backgroundColor: materialTheme.palette.primary.main,
        borderColor: materialTheme.palette.primary.main,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: materialTheme.palette.primary.main,
        borderColor: materialTheme.palette.primary.main,
      },
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: '#edeff3',
        borderColor: '#edeff3',
        '&:hover': {
          cursor: 'move',
        },
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem #edeff3',
      },
    },
  };
})(Button);
export default buildReportButton;
