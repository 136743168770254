import { CREATE_ALERT, REMOVE_ALERT, SET_TO_DEFAULT } from 'actions/alert';

const initialState = {
  message: '',
  title: '',
  type: '',
  open: false,
  timerAlert: 3000,
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        message: action.message,
        type: action.messageType,
        title: action.title,
        open: true,
      };
    case REMOVE_ALERT:
      return { ...state, open: false };
    case SET_TO_DEFAULT:
      return {
        ...state,
        message: '',
        type: '',
        title: '',
      };
    default:
      return state;
  }
};

export default alertReducer;
