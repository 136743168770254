import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import distanceFormat from 'helpers/distanceFormatter';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const fitToColumn = (colums) => colums.map((col) => ({
  wch: col.toString().length,
}));

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  ws['!cols'] = fitToColumn(Object.keys(csvData[0]));
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export const formatCoordinates = ({ x, y, z }) => `x: ${x}\u00A0y:${y}${z ? `\u00A0z:${z}` : ''}`;

const formatReportToCsv = ({
  record, translate,
}) => {
  const titleObject = translate('objectTitle') || 'Object name';
  const sublocation = translate('monitoring:sublocation') || 'Group';
  const distance = translate('Distance') || 'Zone';

  const result = {};
  result[titleObject] = record?.title || '-';
  result[sublocation] = record?.sublocation_name || '-';
  result[distance] = distanceFormat(record?.dist, translate) || '-';

  return result;
};

export const downloadXls = ({
  report,
  translate,
  locale,
}) => {
  const csvData = report.map((record) => formatReportToCsv({
    record,
    translate,
    locale,
  }));

  const fileName = 'Distance traveled report';

  exportToCSV(csvData, fileName);
};
