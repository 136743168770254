import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => {
  const customStyles = materialTheme.CustomStyles;
  const mainTopMenuTheme = customStyles.MainTopMenu;

  return {
    menuIcon: {
      display: 'none',
      [materialTheme.breakpoints.down('800')]: {
        width: '50px',
        display: 'block',
      },
    },
    menuIconOff: {
      [materialTheme.breakpoints.down('800')]: {
        display: 'none',
      },
    },
    menu: {
      height: '80px',
      width: '100%',
      top: 0,
      left: 0,
      background: mainTopMenuTheme.background,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    menuList: {
      width: '60vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      paddingLeft: '35px',
      [materialTheme.breakpoints.down('800')]: {
        display: 'none',
      },
    },
    list: {
      listStyleType: 'none',
      margin: '0',
      padding: '0',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      [materialTheme.breakpoints.down('900')]: {
        paddingLeft: '20px',
      },
    },
    listLink: {
      marginRight: '40px',
      color: customStyles.GlobalTextColor,
      textDecoration: 'none',
      fontSize: '16px',
      fontFamily: 'Regular, sans-serif',
      fontWeight: 700,
      transition: 'all 0.3s ease-in-out',
      WebkitTransition: 'all 0.3s ease-in-out',
      '&:hover': {
        color: materialTheme.palette.primary.main,
      },
      [materialTheme.breakpoints.down('900')]: {
        marginRight: '20px',
      },
    },
    listLinkActive: {
      color: materialTheme.palette.primary.main,
      '&:аfter': {
        height: '2px',
        color: materialTheme.palette.primary.main,
      },
    },
    profile: {
      width: '20vw',
      minWidth: '200px',
      marginRight: '10px',
    },
    logo: {
      width: customStyles.LeftBarWidth,
      minWidth: customStyles.LeftBarWidth,
      marginLeft: customStyles.MainLeftMargin,
      [materialTheme.breakpoints.down('800')]: {
        display: 'none',
      },
    },
    MuiInputUnderline: {
      '&:before': {
        content: '',
      },
    },
    MuiSelectIcon: {
      color: 'white',
    },
    inActiveLink: {
      color: 'gray',
      '&:hover': {
        color: '#d0c9c9',
        cursor: 'not-allowed',
      },
    },
    accessHint: {
      fontSize: '12px',
    },
  };
});

export default useStyles;
