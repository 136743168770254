import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => {
  const theme = materialTheme.CustomStyles;
  return {
    welcomeLogo: {
      width: theme.Logo.imageWidth,
      height: theme.Logo.imageHeight,
      background: theme.LogoImageBackground,
    },
  };
});

const WelcomeLogo = () => {
  const classNames = useStyles();
  return (
    // eslint-disable-next-line no-underscore-dangle
    <div style={{ display: window._configuration_env.THEME === 'whiteLabel' ? 'none' : null }} className={classNames.welcomeLogo} />
  );
};

export default WelcomeLogo;
