export const CREATE_ALERT = 'CREATE_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_TO_DEFAULT = 'SET_TO_DEFAULT';

export const alertTypes = {
  err: 'error',
  warn: 'warning',
  info: 'info',
  success: 'success',
};

export const createAlert = (messageType, message, title) => ({
  type: CREATE_ALERT,
  message,
  title,
  messageType,
});

export const removeAlert = () => ({
  type: REMOVE_ALERT,
});

export const setAlertToDefault = () => ({
  type: SET_TO_DEFAULT,
});
