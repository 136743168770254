import {
  SET_LOGIN,
  SET_PASSWORD,
  SHOW_PASSWORD,
  FETCH_FINISHED,
  LOGIN_IN_PROGRESS,
} from 'actions/auth';

const initialState = {
  login: '',
  password: '',
  showPassword: false,
  isFetching: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return { ...state, login: action.login };
    case SET_PASSWORD:
      return { ...state, password: action.password };
    case SHOW_PASSWORD:
      return { ...state, showPassword: action.showPassword };
    case LOGIN_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCH_FINISHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default authReducer;
