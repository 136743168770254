/* eslint-disable global-require */

import { createMuiTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import checkObjectInWindowConfig from 'helpers/checkEnv';

const env = checkObjectInWindowConfig('THEME') || 'prod';
const defaultThemeObject = require('./default/styles').default;

let themeObject;
// eslint-disable-next-line import/no-mutable-exports
let theme;
switch (env) {
  case 'mts':
    themeObject = require('./mts/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'huawei':
    themeObject = require('./huawei/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'megafon':
    themeObject = require('./megafon/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'ramco':
    themeObject = require('./ramco/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'zyfra':
    themeObject = require('./zyfra/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'hipla':
    themeObject = require('./hipla/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'eliko':
    themeObject = require('./eliko/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'minew':
    themeObject = require('./minew/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'mokosmart':
    themeObject = require('./mokosmart/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'naviin':
    themeObject = require('./naviin/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'solut':
    themeObject = require('./solut/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'rzd':
    themeObject = require('./rzd/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'blundoor':
    themeObject = require('./blundoor/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'whiteLabel':
    themeObject = require('./whiteLabel/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'atbe':
    themeObject = require('./atbe/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'honeywell':
    themeObject = require('./honeywell/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'platform':
    themeObject = require('./platform/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
  case 'prod':
  default:
    themeObject = require('./prod/styles').default;
    theme = createMuiTheme(merge({}, defaultThemeObject, themeObject));
    break;
}

export default theme;
