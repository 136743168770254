import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((materialTheme) => ({
  history: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  noneContainer: {
    margin: '0 auto',
    // display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    background: materialTheme.CustomStyles.MainBackgroundColor,
  },
  mapControls: {
    width: '100%',
    background: materialTheme.CustomStyles.MainBackgroundColor,
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    width: '30px',
    height: '30px',
  },
  speedControls: {
    marginRight: '10px',
    float: 'left',
  },
  map_container: {
    width: 'calc(100vw)',
    [materialTheme.breakpoints.down('800')]: {
      width: '100vw',
    },
    height: 'calc(100vh - 80px)',
    display: 'block',
    margin: '0 auto',
    '&::backdrop': {
      background: '#fafafa',
    },
  },
  control_wrapper: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  swich_element: {
    marginLeft: '9px',
  },
  player: {
    position: 'absolute',
    bottom: '20px',
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '55px',
    alignItems: 'center',
    height: '30px',
    lineHeight: '30px',
    zIndex: '35',
    background: '#f8f8f8',
  },
  timeLine: {
    width: '35vw',
    marginTop: '1px',
    float: 'left',
    background: 'rgb(222,222,222)',
    cursor: 'pointer',
    position: 'relative',
  },
  description: {
    flexWrap: 'wrap',
    width: 'fitContent',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '15px',
    flexDirection: 'row',
    whiteSpace: 'normal',
  },
  descriptionTime: {
    width: '125px',
    lineHeight: '1px',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
  },
  line: {
    height: '16px',
    background: 'rgb(52,71,92)',
  },
  playBtn: {
    margin: '0px 5px 0px 13px',
    float: 'left',
  },
  btnEvent: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    lineHeight: '0.5',
  },
  speedBtn: {
    display: 'block',
    margin: '0 2px',
    cursor: 'pointer',
    float: 'right',
  },
  activeSpeedBtn: {
    fontWeight: 'bold',
  },
  carret: {
    position: 'absolute',
    top: '-2px',
    left: '0',
    height: '20px',
    width: '2px',
    background: 'rgb(52,71,92)',
  },
  textt: {
    float: 'left',
    position: 'relative',
    width: '20px',
    height: '20px',
    lineHeight: '22px',
    textAlign: 'center',
    borderRadius: '50%',
    top: '-20px',
    left: '-10px',
    background: 'rgb(52,71,92)',
    fontSize: '10px',
    color: '#fff',
  },
  btn: {
    width: '246px',
    minWidth: '140px',
  },
  historyDate: {
    marginLeft: '20px',
  },
  historyInput: {
    marginRight: 0,
  },
  objectsCount: {
    marginLeft: '15px',
    fontSize: '16px',
  },
}));

export default useStyles;
