import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';

import { fetchDevicesList } from 'actions/devices';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  formTypeCreate, formTypeUpdate,
} from 'constans';
import CustomButton from 'components/settings/Button.component';
import CustomButtonTitle from 'components/settings/ButtonTitle.component';

import {
  fetchGroupsInSettings, openSettingsModal, closeSettingsModal,
  deleteGroup, settingsGroupModalAction,
  setUpdateGroupId, openDeleteGroupModal,
  closeDeleteGroupModal,
  setSettingsModalTitle,
  sortByTitles,
} from 'actions/groups';
import { createAlert, alertTypes } from 'actions/alert';
import arrowStyles from 'components/shared/settings/arrow';
import containerStyles from '../../shared/Container/styles';
import useStyles from './groups.component.style';

const GroupsComponent = (props) => {
  const {
    dispatch,
    formType,
    currentApp,
    openModal,
    openDeleteModal,
    modalTitle,
    isModalLoading,
    isFetchingSettingsList,
    groupsListInSettings,
    openMenu,
    updatedGroupId,
  } = props;
  const { t } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [groupToDelete, setGroupToDelete] = useState({});
  const [isSrotByTitle, setSortByTitile] = useState('3');
  const classesContainer = containerStyles();
  const classes = useStyles();
  const arrowClasses = arrowStyles();

  useEffect(() => {
    const getGroups = async () => {
      // something wrong with pagination in
      await dispatch(fetchGroupsInSettings(page * rowsPerPage, rowsPerPage));
    };

    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, currentApp]);

  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewGroup = () => {
    dispatch(openSettingsModal(formTypeCreate));
  };

  const chechFormValidation = () => !modalTitle;

  const handleEditGroup = (group) => {
    try {
      dispatch(setSettingsModalTitle(group.attributes.title));
      dispatch(openSettingsModal(formTypeUpdate));
      dispatch(setUpdateGroupId(group.id));
    } catch (error) {
      dispatch(createAlert(alertTypes.warn, 'Error in reading group properties'));
    }
  };

  const handleClose = () => {
    dispatch(closeSettingsModal());
  };

  const handleMainModalActionClick = async () => {
    await dispatch(settingsGroupModalAction(t));
    setPage(0);
    await dispatch(fetchGroupsInSettings(0, rowsPerPage));
    await dispatch(fetchDevicesList());
  };

  const handleCloseDelete = () => {
    dispatch(closeDeleteGroupModal());
  };

  const handleDelete = async (object) => {
    setGroupToDelete(object);
    dispatch(openDeleteGroupModal());
  };

  const handleDeteleConfirm = async () => {
    setPage(0);
    await dispatch(deleteGroup(groupToDelete, t));
    await dispatch(fetchGroupsInSettings(0, rowsPerPage));
    handleCloseDelete();
  };
  const sortTitle = () => {
    if (groupsListInSettings.length !== 0) {
      dispatch(sortByTitles());
    }
    if (isSrotByTitle === '3') {
      setSortByTitile('2');
    } else if (isSrotByTitle === '2') {
      setSortByTitile('1');
    } else if (isSrotByTitle === '1') {
      setSortByTitile('2');
    }
  };

  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewGroup}
        >
          {t('createGroupTitle')}
        </CustomButtonTitle>
      </div>
      {isFetchingSettingsList
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={sortTitle} className={arrowClasses.titleRow}>
                      {' '}
                      {t('title')}
                      {' '}
                      {
                      (isSrotByTitle === '1')
                        && <ArrowDropUpIcon className={arrowClasses.arrow} />
                      }
                      {
                      (isSrotByTitle === '2')
                        && <ArrowDropDownIcon className={arrowClasses.arrow} />
                      }
                      {
                      (isSrotByTitle === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupsListInSettings.map((group) => (
                    <TableRow key={group.id}>
                      <TableCell component="th" scope="row">
                        {group.attributes.title}
                      </TableCell>
                      <TableCell>
                        <CustomButton onClick={() => { handleEditGroup(group); }}>
                          {' '}
                          {t('editBtn')}
                          {' '}
                        </CustomButton>
                        <CustomButton onClick={() => { handleDelete(group); }}>
                          {' '}
                          {t('deleteBtn')}
                          {' '}
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={() => page + 1}
              page={page}
              nextIconButtonProps={{
                disabled: groupsListInSettings.length < rowsPerPage,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t('report:labelRowsPerPage')}
            />
            <div>
              <Dialog
                open={openDeleteModal}
                onClose={handleCloseDelete}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  {t('deleteGroupTitle')}
                </DialogTitle>
                <DialogContent dividers>
                  <p>
                    {' '}
                    {t('deleteGroupText')}
                  </p>
                </DialogContent>
                <DialogActions>
                  <CustomButton disabled={isModalLoading} onClick={handleCloseDelete} color="primary">
                    {t('cancelBtn')}
                  </CustomButton>
                  <CustomButton disabled={isModalLoading} onClick={handleDeteleConfirm} color="primary">
                    {isModalLoading ? <CircularProgress size={26} /> : null}
                    {t('deleteBtn')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openModal}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  <DialogTitle id="scroll-dialog-title">
                    {formType === formTypeCreate
                      ? t('createGroupTitle')
                      : t('editGroupTitle')}
                    {formType === formTypeCreate
                      ? <></> : (
                        <div className={classes.idTitle}>
                          id
                          {' '}
                          {updatedGroupId}
                        </div>
                      )}
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      id="standard-multiline-flexible"
                      label={t('groupModalTitle')}
                      value={modalTitle}
                      onChange={(event) => { dispatch(setSettingsModalTitle(event.target.value)); }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <CustomButton disabled={isModalLoading} onClick={handleClose} color="secondary">
                      {t('cancelBtn')}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      disabled={chechFormValidation()}
                      onClick={handleMainModalActionClick}
                      color="primary"
                    >
                      {isModalLoading ? <CircularProgress size={26} /> : null}
                      {formType === formTypeCreate ? t('createGroupButton') : t('saveBtn')}
                    </CustomButton>
                  </DialogActions>
                </form>
              </Dialog>
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    groupsObject,
    groupsListInSettings,
    selectedGroup,
    isModalLoading,
    isFetchingSettingsList,
    formType,
    openModal,
    modalTitle,
    openDeleteModal,
    groupsListInPage,
    updatedGroupId,
  } = state.groups;

  const { currentApp, openMenu } = state.app;

  return {
    formType,
    openDeleteModal,
    groupsListInPage,
    isModalLoading,
    groupsObject,
    isFetchingSettingsList,
    groupsListInSettings,
    modalTitle,
    openModal,
    selectedGroup,
    currentApp,
    openMenu,
    updatedGroupId,
  };
}

export default connect(mapStateToProps)(GroupsComponent);
