import Cookies from 'js-cookie';
import request, { endPointsEnum } from 'services/http';
import { cookieTokenName, cookOpts } from 'constans';
import { createAlert } from './alert';

export const SET_APP_LIST = 'SET_APP_LIST';
export const SET_CURRENT_APP = 'SET_CURRENT_APP';
export const FETCH_APP_LIST_IN_PROGRESS = 'FETCH_APP_LIST_IN_PROGRESS';
export const FETCHED_APPS_LIST = 'FETCHED_APPS_LIST';
export const SET_OPEN_MENU = 'SET_OPEN_MENU';

export const setAppsObject = (appsObject) => ({
  type: SET_APP_LIST,
  appsObject,
});

export const setApp = (app) => ({
  type: SET_CURRENT_APP,
  app,
});

export const fetchAppInProgress = () => ({
  type: FETCH_APP_LIST_IN_PROGRESS,
});

export const fetchedAppsList = () => ({
  type: FETCHED_APPS_LIST,
});
export const setOpenMenu = (action) => ({
  type: SET_OPEN_MENU,
  action,
});

export const changeAppInToken = async (appId, dispatch) => {
  try {
    const params = {
      appId,
    };
    const { newToken } = await request.auth.get(endPointsEnum.appChange, { params });
    Cookies.set(cookieTokenName, newToken, cookOpts);
    return true;
  } catch (e) {
    dispatch(createAlert('error', e.message));
    return false;
  }
};

export const appFetch = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const params = {
      userHash: state.user.hash,
    };

    dispatch(fetchAppInProgress());

    const appsArray = await request.client.get(endPointsEnum.appsGetAll, { params });
    const appsObject = {};
    appsArray.map((app) => {
      appsObject[app.id] = app;
      return app;
    });

    dispatch(setAppsObject(appsObject));
    const appIDFormStorage = localStorage.getItem('appID');

    if (appIDFormStorage && appsObject[appIDFormStorage]) {
      await changeAppInToken(appIDFormStorage, dispatch);
      dispatch(setApp(appsObject[appIDFormStorage]));
    } else {
      dispatch(setApp(appsArray[0]));
      localStorage.setItem('appID', appsArray[0].id);
    }

    dispatch(fetchedAppsList());
  } catch (error) {
    dispatch(fetchedAppsList());
    // dispatch(createAlert('error', error.message));
  }
};
