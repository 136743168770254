import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const customAutocomplete = withStyles((materialTheme) => {
  const { fontRegular } = materialTheme;

  return {
    input: {
      padding: 0,
    },
    inputRoot: {
      height: '37px',
      fontFamily: fontRegular,
      width: '100%',
      borderRadius: 0,
      color: materialTheme.CustomStyles.GlobalTextColor,
      backgroundColor: '#ffffff',
      '& $input': {
        width: 30,
        minWidth: 30,
        color: materialTheme.CustomStyles.GlobalTextColor,
      },
      '& fieldset': {
        borderRadius: 0,
        border: '1px solid #edeff3',
        color: materialTheme.CustomStyles.GlobalTextColor,
      },
      '&[class*="MuiOutlinedInput-root"]': {
        '& $input': {
          padding: 0,
        },
      },
    },
  };
})(Autocomplete);
export default customAutocomplete;
