import {
  FETCHED_CONTACT_REPORTS,
  FETCH_CONTACT_REPORTS_IN_PROGRESS,
  SET_CONTACT_REPORTS_LIST,
  SET_CONTACT_ZONE_REPORTS_LIST,
  SET_DATE_FROM,
  SET_DATE_TO,
  SET_CURRENT_OBJECT,
  SET_CURRENT_DURATION,
  SET_DEVICE_MONITORING_LIST,
  SET_OBJECT_REPORT_LIST,
  RESET_REPORTS,
  SET_ALERT_REPORT_LIST,
  FETCH_ALERT_REPORT_END,
  FETCH_ALERT_REPORT_IN_PROGRESS,
  SET_ALERT_PAGE,
  SET_ALERT_REPORT_LIMIT,
  SET_SORT_DATE,
  SET_ARROW_STATUS,
  SET_SORT_STATUS,
  SET_SORT_NAME,
  SET_SORT_MAC_ADDRESS,
  SET_ARROW_DATE,
  SET_ARROW_NAME,
  SET_ARROW_MAC,
  FILTER_DEVICE_ARRAY,
  SET_FINAL_OBJECT_REPORT_LIST,
  SET_TIME_OUT_OF_ZONES,
  GET_REPORT_DATE,
  FETCH_HISTORY_LIST_BY_OBJECT,
  FETCH_HISTORY_LIST_BY_OBJECT_END,
  FETCH_INVENTORY_REPORT_END,
  FETCH_INVENTORY_REPORT_IN_PROGRESS,
  SET_INVENTORY_REPORT,
  SET_DISTANCE_REPORT,
  FETCH_DISTANCE_REPORT_END,
  FETCH_DISTANCE_REPORT_IN_PROGRESS,
} from 'actions/reports';

import { noArrow } from 'constans';

const getOneDayBefore = () => {
  const currentDate = new Date().getTime();
  const sevenDays = 1 * 3 * 60 * 60 * 1000;

  return currentDate - sevenDays;
};

const initialState = {
  devicesMonitoringArray: [],
  inventoryReport: [],
  inventoryRepLastCoords: [],
  isFetchingInventoryReport: false,
  isFetchingDistanceTraveledReport: false,
  distanceTraveledReport: [],
  filterDevicesMonitoringArray: [],
  sortByName: 'asc',
  sortByStatus: 'asc',
  sortByDate: 'asc',
  sortByMACaddress: 'asc',
  arrowDate: noArrow,
  arrowStatus: noArrow,
  arrowName: noArrow,
  arrowMac: noArrow,
  contactReportsArray: [],
  contactZoneReportsArray: [],
  objectReportsArray: [],
  objectFinalReportsArray: [],
  outOfzonesTime: [],
  alertReportsArray: [],
  isFetching: false,
  dateFrom: new Date(getOneDayBefore()),
  dateTo: new Date(),
  dateFromNoZones: new Date(getOneDayBefore()),
  dateToNoZones: new Date(),
  currentObject: null,
  isFetchingAlertReport: false,
  isFetchingHistoryPicReport: false,
  alertReportLimit: 10,
  currentPage: 0,
  duration: '120',
  getReportDate: '',
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_ALERT_REPORT_LIMIT:
      return { ...state, alertReportLimit: action.payload };
    case SET_CONTACT_REPORTS_LIST:
      return {
        ...state,
        contactReportsArray: action.contactReportsArray,
        isFetching: false,
      };
    case SET_CONTACT_ZONE_REPORTS_LIST:
      return {
        ...state,
        contactZoneReportsArray: action.contactZoneReportsArray,
        isFetching: false,
      };
    case FETCH_CONTACT_REPORTS_IN_PROGRESS:
      return { ...state, isFetching: true };

    case SET_INVENTORY_REPORT:
      return {
        ...state,
        inventoryReport: action.report,
        inventoryRepLastCoords: action.lastCoordinates,
      };

    case FETCH_INVENTORY_REPORT_IN_PROGRESS:
      return { ...state, isFetchingInventoryReport: true };

    case FETCH_INVENTORY_REPORT_END:
      return { ...state, isFetchingInventoryReport: false };

    case SET_DISTANCE_REPORT:
      return { ...state, distanceTraveledReport: action.report };
    case FETCH_DISTANCE_REPORT_IN_PROGRESS:
      return { ...state, isFetchingDistanceTraveledReport: true };
    case FETCH_DISTANCE_REPORT_END:
      return { ...state, isFetchingDistanceTraveledReport: false };

    case SET_DATE_TO:
      return { ...state, dateTo: action.date };
    case SET_DATE_FROM:
      return { ...state, dateFrom: action.date };
    case SET_CURRENT_OBJECT:
      return { ...state, currentObject: action.object };
    case SET_CURRENT_DURATION:
      return { ...state, duration: action.duration };
    case SET_OBJECT_REPORT_LIST:
      return {
        ...state,
        objectReportsArray: action.list,
        dateFromNoZones: action.dateFrom,
        dateToNoZones: action.dateTo,
      };
    case SET_FINAL_OBJECT_REPORT_LIST:
      return {
        ...state,
        objectFinalReportsArray: action.list,
        dateFromNoZones: action.dateFrom,
        dateToNoZones: action.dateTo,
      };
    case SET_TIME_OUT_OF_ZONES:
      return { ...state, outOfzonesTime: action.list };
    case SET_DEVICE_MONITORING_LIST:
      return { ...state, devicesMonitoringArray: action.list };
    case FETCHED_CONTACT_REPORTS:
      return { ...state, isFetching: false };
    case SET_ALERT_REPORT_LIST:
      return { ...state, alertReportsArray: action.list };
    case SET_SORT_DATE:
      return { ...state, sortByDate: action.str };
    case SET_SORT_STATUS:
      return { ...state, sortByStatus: action.str };
    case SET_SORT_NAME:
      return { ...state, sortByName: action.str };
    case SET_SORT_MAC_ADDRESS:
      return { ...state, sortByMACaddress: action.str };
    case SET_ARROW_DATE:
      return { ...state, arrowDate: action.str };
    case SET_ARROW_STATUS:
      return { ...state, arrowStatus: action.str };
    case SET_ARROW_NAME:
      return { ...state, arrowName: action.str };
    case SET_ARROW_MAC:
      return { ...state, arrowMac: action.str };
    case FETCH_HISTORY_LIST_BY_OBJECT:
      return { ...state, isFetchingHistoryPicReport: true };
    case FETCH_HISTORY_LIST_BY_OBJECT_END:
      return { ...state, isFetchingHistoryPicReport: false };
    case FILTER_DEVICE_ARRAY:
      return { ...state, filterDevicesMonitoringArray: action.str };
    case GET_REPORT_DATE:
      return { ...state, getReportDate: action.str };

    case FETCH_ALERT_REPORT_IN_PROGRESS:
      return { ...state, isFetchingAlertReport: true };
    case FETCH_ALERT_REPORT_END:
      return { ...state, isFetchingAlertReport: false };

    case RESET_REPORTS:
      return {
        ...state,
        inventoryRepory: [],
        devicesMonitoringArray: [],
        contactReportsArray: [],
        contactZoneReportsArray: [],
        objectReportsArray: [],
        alertReportsArray: [],
        isFetching: false,
        currentObject: null,
      };
    default:
      return state;
  }
};

export default reportsReducer;
