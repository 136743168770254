import request, { endPointsEnum } from 'services/http';

import { createAlert, alertTypes } from 'actions/alert';
import { formTypeCreate } from 'constans';

export const SET_GROUPS = 'SET_GROUPS';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const FETCHED_GROUPS = 'FETCHED_GROUPS';
export const FETCHE_GROUPS_IN_PROGRESS = 'FETCHE_GROUPS_IN_PROGRESS';
export const SET_SORT_DEVICES = 'SET_SORT_DEVICES';

export const setGroups = (groupsObject) => ({
  type: SET_GROUPS,
  groupsObject,
});

export const setSelectedGroup = (group) => ({
  type: SET_SELECTED_GROUP,
  group,
});

export const fetchedGroups = () => ({
  type: FETCHED_GROUPS,
});

export const SET_GROUPS_IN_SETTINGS = 'SET_GROUPS_IN_SETTINGS';

export const setGroupsInSettings = (list) => ({
  type: SET_GROUPS_IN_SETTINGS,
  list,
});

export const fetchGroupsInProgress = () => ({
  type: FETCHE_GROUPS_IN_PROGRESS,
});

export const OPEN_SETTINGS_MODAL = 'OPEN_SETTINGS_MODAL ';

export const openSettingsModal = (formType) => ({
  type: OPEN_SETTINGS_MODAL,
  formType,
});

export const CLOSE_SETTINGS_MODAL = 'CLOSE_SETTINGS_MODAL';

export const closeSettingsModal = () => ({
  type: CLOSE_SETTINGS_MODAL,
});

export const SET_GROUPS_LIST_IN_SETTINGS = 'SET_GROUPS_LIST_IN_SETTINGS';

export const setGroupsListInSettings = (list) => ({
  type: SET_GROUPS_LIST_IN_SETTINGS,
  list,
});
export const setSortGroupsTitle = (str) => ({
  type: SET_SORT_DEVICES,
  str,
});

export const fetchGroups = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const params = {
      api_key: state.app.currentApp.api_key,
    };

    dispatch(fetchGroupsInProgress());

    const response = await request.tracking.get(endPointsEnum.trackedGroups, { params });

    const groupsArray = response.data;
    const groupsObject = {};
    groupsArray.map((group) => {
      groupsObject[group.id] = {
        id: group.id,
        title: group.attributes.title,
      };

      return group;
    });

    dispatch(setGroups(groupsObject));
  } catch (error) {
    dispatch(fetchedGroups());
    if (state.app.currentApp.api_key) {
      dispatch(createAlert('error', error.message));
    }
  }
};

export const fetchGroupsInSettings = (page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const params = {
      api_key: state.app.currentApp.api_key,
      'page[offset]': page,
      'page[limit]': limit,
    };

    dispatch(fetchGroupsInProgress());

    const response = await request.tracking.get(endPointsEnum.trackedGroups, { params });

    dispatch(setGroupsListInSettings(response.data));
    dispatch(fetchedGroups());
  } catch (error) {
    dispatch(fetchedGroups());
    dispatch(createAlert('error', error.message));
  }
};

export const sortByTitles = (page = 0, limit = 10) => async (dispatch, getState) => {
  try {
    const state = getState();
    const params = {
      api_key: state.app.currentApp.api_key,
      'page[offset]': page,
      'page[limit]': limit,
    };
    const { sortByTitle } = state.groups;
    dispatch(fetchGroupsInProgress());

    const response = await request.tracking.get(endPointsEnum.trackedGroups, { params });
    if (sortByTitle === 'asc') {
      response.data.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
      dispatch(setSortGroupsTitle('desc'));
    } else {
      response.data.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
      dispatch(setSortGroupsTitle('asc'));
    }
    dispatch(setGroupsListInSettings(response.data));
    dispatch(fetchedGroups());
  } catch (error) {
    dispatch(fetchedGroups());
    dispatch(createAlert('error', error.message));
  }
};

export const SET_SETTINGS_MODAL_TITLE = 'SET_SETTINGS_MODAL_TITLE';

export const setSettingsModalTitle = (title) => ({
  type: SET_SETTINGS_MODAL_TITLE,
  title,
});

export const GROUP_MODAL_FETCH_START = 'GROUP_MODAL_FETCH_START';

export const groupModalFetchStart = () => ({
  type: GROUP_MODAL_FETCH_START,
});

export const GROUP_MODAL_FETCH_END = 'GROUP_MODAL_FETCH_END';

export const groupModalFetchEnd = () => ({
  type: GROUP_MODAL_FETCH_END,
});

export const SET_UPDATE_GROUP_ID = 'SET_UPDATE_GROUP_ID';

export const setUpdateGroupId = (id) => ({
  type: SET_UPDATE_GROUP_ID,
  id,
});

export const settingsGroupModalAction = (translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      formType,
      modalTitle,
      updatedGroupId,
    } = state.groups;
    const { api_key: apiKey } = state.app.currentApp;

    const body = {
      attributes: {
        title: modalTitle,
      },
      api_key: apiKey,
    };

    dispatch(groupModalFetchStart());
    if (formType === formTypeCreate) {
      const result = await request.tracking.post(endPointsEnum.trackedGroups, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(createAlert(alertTypes.info, translate('groupCreatedAlert')));
    } else {
      const path = `${endPointsEnum.trackedGroups}/${updatedGroupId}`;
      const result = await request.tracking.patch(path, { body });
      if (!result.data) {
        throw new Error('Error in request');
      }
      dispatch(setUpdateGroupId(null));
      dispatch(createAlert(alertTypes.info, translate('groupEditedAlert')));
    }
    await dispatch(fetchGroupsInSettings());
    await dispatch(fetchGroups());
    dispatch(closeSettingsModal());
    dispatch(setSettingsModalTitle(''));
    dispatch(groupModalFetchEnd());
  } catch (error) {
    dispatch(groupModalFetchEnd());
    console.warn(`Error in groups' modal action: ${error.message}`);
    dispatch(createAlert(alertTypes.err, translate('groupMainModalError')));
  }
};

export const OPEN_DELETE_GROUP_MODAL = 'OPEN_DELETE_GROUP_MODAL';

export const openDeleteGroupModal = () => ({
  type: OPEN_DELETE_GROUP_MODAL,
});

export const CLOSE_DELETE_GROUP_MODAL = 'CLOSE_DELETE_GROUP_MODAL';

export const closeDeleteGroupModal = () => ({
  type: CLOSE_DELETE_GROUP_MODAL,
});

export const deleteGroup = (group, translate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { api_key: apiKey } = state.app.currentApp;
    if (!group.id || !apiKey) {
      return;
    }
    const path = `${endPointsEnum.trackedGroups}/${group.id}`;
    const params = {
      api_key: apiKey,
    };
    await dispatch(groupModalFetchStart());
    await request.tracking.delete(path, { params });
    dispatch(createAlert('info', translate('groupDeleteAlert')));
    await dispatch(fetchGroups());
    dispatch(groupModalFetchEnd());
    dispatch(closeDeleteGroupModal());
  } catch (error) {
    dispatch(groupModalFetchEnd());
    console.warn(`Error in deleting group: ${error.message}`);
    dispatch(createAlert('error', translate('groupDeleteError')));
  }
};
