import {
  FETCHED_APPS_LIST,
  FETCH_APP_LIST_IN_PROGRESS,
  SET_APP_LIST,
  SET_CURRENT_APP,
  SET_OPEN_MENU,
} from 'actions/app';

const initialState = {
  appsObject: {},
  currentApp: {},
  isFetching: false,
  openMenu: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_LIST:
      return {
        ...state,
        appsObject: action.appsObject,
        isFetching: false,
      };
    case FETCH_APP_LIST_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCHED_APPS_LIST:
      return { ...state, isFetching: false };
    case SET_OPEN_MENU:
      return { ...state, openMenu: action.action };
    case SET_CURRENT_APP:
      return {
        ...state, currentApp: { ...action.app },
        // copying object without links to old object
      };
    default:
      return state;
  }
};

export default appReducer;
