import {
  FETCHED_FLOORS,
  FETCH_FLOORS_IN_PROGRESS,
  SET_FLOOR_LIST,
  SET_CURRENT_FLOOR,
} from 'actions/floors';

const initialState = {
  floorsObject: {},
  currentFloor: {},
  isFetching: false,
};

const floorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOOR_LIST:
      return {
        ...state,
        floorsObject: action.floorsObject,
        isFetching: false,
      };
    case FETCH_FLOORS_IN_PROGRESS:
      return { ...state, isFetching: true };
    case FETCHED_FLOORS:
      return { ...state, isFetching: false };
    case SET_CURRENT_FLOOR:
      return {
        ...state, currentFloor: { ...action.floor },
        // copying object without links to old object
      };
    default:
      return state;
  }
};

export default floorReducer;
