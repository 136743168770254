/*eslint-disable*/

import React from "react";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// custom outlined selector
import OutlinedSelector from "components/shared/OutlinedSelector/OutlinedSelector";
import { noValuesConstant, selectNoOneConstant } from "constans";

export default function ZonesSelector(props) {
  const {
    className,
    zones,
    zoneTypes,
    currentFloorId,
    value,
  } = props;
  const { t } = useTranslation(["monitoring"]);

  const zonesArray = Object.keys(zones).filter((zoneId) => {
    return zones[zoneId].floor === parseInt(currentFloorId);
    
  });
  const zoneTypesLength = Object.keys(zoneTypes).length;

  const checkCurrentValue = () => {
    if (zonesArray.length > 0) {
      if (value) {
        return value;
      } else {
        return selectNoOneConstant;
      }
    } else {
      return noValuesConstant;
    }
  };
  const currentValue = checkCurrentValue();

  const fieldValidation = () => {
    return currentValue !== noValuesConstant;
  };

  const createNoValuesLabel = () => {
    if (fieldValidation()) {
      return null;
    }
    if (zoneTypesLength > 0) {
      return t("noZones");
    } else {
      return t("noZoneTypes");
    }
  };
  return (
    <FormControl>
      <OutlinedSelector
        id="select-zone"
        novalueslabel={createNoValuesLabel()}
        allowNoValues={!fieldValidation()}
        label={t("zonesSelectorLabel")}
        allowAllValues={fieldValidation() && true}
        allowSelectNoValues={fieldValidation() && true}
        className={className}
        select
        value={currentValue}
        onChange={props.onChange}
      >
        {zonesArray.map((zoneId) => {
          return (
            <MenuItem key={zoneId} value={zoneId}>
              {zones[zoneId].title}
            </MenuItem>
          );
        })}
      </OutlinedSelector>
    </FormControl>
  );
}
