/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { Sky } from 'three/examples/jsm/objects/Sky';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

// import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls';
// import modelTrackUrl from './models/auto.glb';
// import modelFactory from './models/factory.glb';

const modelTrackUrl = 'https://static.navigine.com/user-files/auto.glb';
const modelFactory = 'https://static.navigine.com/user-files/factory.glb';

// const angToRad = (ang) => (ang / 180) * Math.PI;

// todo culc model and moove it to navigines 2 sector  - computeBoundingBox();
// todo navigine kx * computeBoundingBox.x
// todo camera moove to computeBoundingBox / 2

const container = (sceneElement) => {
  let camera;
  let scene;
  let renderer;

  let model;

  const width = sceneElement.current.clientWidth;
  const height = sceneElement.current.clientHeight;

  // let sky; let sun;

  // let way = 1;

  let controls;

  let track;

  let sceneBounds;

  function updateModelCoordinates(kx, ky) {
    if (!sceneBounds) {
      return;
    }
    const diffX = sceneBounds.max.x - sceneBounds.min.x;
    const diffZ = sceneBounds.max.z - sceneBounds.min.z;
    const culcX = (sceneBounds.min.x + diffX * kx);
    const culcZ = (sceneBounds.min.z + diffZ * (1 - ky));

    // const fi = 180 * (Math.PI / 180);

    // const culcRX = culcX * Math.cos(fi) - culcZ * Math.sin(fi);
    // const culcRZ = culcX * Math.sin(fi) - culcZ * Math.cos(fi);

    track.position.set(culcX, 0, culcZ);
    render();
  }

  async function addfactoryModel() {
    return new Promise((res) => {
      new GLTFLoader().load(modelFactory, (gltf) => {
        model = gltf.scene;
        scene.add(model);
        sceneBounds = new THREE.Box3().setFromObject(model);
        camera.position.set(sceneBounds.max.x / 2, 5, sceneBounds.max.z / 2);
        camera.lookAt(sceneBounds.max.x / 2, 5, sceneBounds.max.z / 2);

        res();
      }, (xhr) => {
        console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
      });
    });
  }

  function addTrackModel() {
    return new Promise((res) => {
      new GLTFLoader().load(modelTrackUrl, (gltf) => {
        model = gltf.scene;
        model.position.set(0, 0, 0);
        // model.rotateY(angToRad(90));
        scene.add(model);
        track = model;
        res();
      }, (xhr) => {
        console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
      });
    });
  }

  async function addModels() {
    await addfactoryModel();
    await addTrackModel();
    animate();
  }

  // function moveTrack() {
  //   const pos = track.position.z;
  //   const delta = 0.03;
  //   let newPos = 0;
  //   if (pos >= 20) {
  //     way = 0;
  //     model.rotation.y = angToRad(-90);
  //   } else if (pos <= -30) {
  //     way = 1;
  //     model.rotation.y = angToRad(90);
  //   }

  //   if (way) {
  //     newPos = pos + delta;
  //   } else {
  //     newPos = pos - delta;
  //   }

  //   track.position.set(0, 0, newPos);
  // }

  function animate() {
    // Render loop
    // moveTrack();
    // renderer.render(scene, camera);
    window.animationId = requestAnimationFrame(animate);
  }

  function initSky() {
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 1e3, 0);
    scene.add(hemiLight);

    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(0, 1e3, 10);
    scene.add(dirLight);
  }

  function addGround() {
    const mesh = new THREE.Mesh(new THREE.PlaneGeometry(1e2, 1e2),
      new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
    mesh.rotation.x = -Math.PI / 2;
    scene.add(mesh);

    const grid = new THREE.GridHelper(500, 40, 0x000000, 0x000000);
    grid.material.opacity = 0.2;
    grid.material.transparent = true;
    scene.add(grid);
  }

  function initFn() {
    camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1e10);

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xe0e0e0);

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.9;

    sceneElement.current.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', render);

    controls.maxPolarAngle = 1.4;
    controls.minDistance = 7;
    controls.maxDistance = 350;

    addGround();
    initSky();
    addModels();

    sceneElement.current.addEventListener('resize', onWindowResize);
    window.addEventListener('resize', onWindowResize);
  }

  function onWindowResize() {
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }

  function render() {
    renderer.render(scene, camera);
  }

  initFn();
  return { onWindowResize, updateModelCoordinates };
};

export default container;
