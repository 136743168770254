/*eslint-disable*/

import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui/core components
import MenuItem from '@material-ui/core/MenuItem';

// custom outlined selector
import OutlinedSelector from 'components/shared/OutlinedSelector/OutlinedSelector';
import { noValuesConstant, allValuesConstant  } from 'constans';

export default function FloorSelector(props) {
  const {
    className, allowAllValues,
    allowSelectNoValues, floors, currentLocationId,
    loading, value, label, ...rest
  } = props;
  const { t } = useTranslation(['monitoring']);
  const floorsArray = Object.keys(floors)

  const currentValue = floorsArray.length > 0
        && value ? value : allValuesConstant;

  return (
    <div>
      <div style={{ background: '#f8f8f8' }}>{t('sublocation')}</div>
      <OutlinedSelector
        id="select-floor"
        className={className}
        novalueslabel={currentValue === noValuesConstant ? t('noFloors') : null}
        // allowNoValues={currentValue === noValuesConstant}
        allowAllValues={allValuesConstant}
        // allowSelectNoValues={allowSelectNoValues}
        value={currentValue}
        onChange={props.onChange}
        {...rest}
      >
        {floorsArray.map((locationId) => (
          <MenuItem key={locationId} value={locationId}>
            {floors[locationId].title}
          </MenuItem>
        ))}
      </OutlinedSelector>
    </div>
  );
}
