import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '246px',
    minWidth: '140px',
    background: theme.CustomStyles.MainBackgroundColor,
  },
  drawerPaperFullSize: {
    width: '246px',
    minWidth: '140px',
    top: 80,
    background: theme.CustomStyles.MainBackgroundColor,
  },
  openCloseMenu: {
    position: 'fixed',
    left: '246px',
    zIndex: 1200,
    top: 'calc(100vh / 2)',
    backgroundColor: theme.CustomStyles.MainBackgroundColor,
    borderRadius: '0 40% 40% 0',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  btnBigScreen: {
    zIndex: '1201',
    position: 'absolute',
    bottom: '10px',
  },
}));
export default useStyles;
