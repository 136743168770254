import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import {
  setDuration,
} from 'actions/reports';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'white',
      padding: '8px',
      width: '140px',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
      border: '1px solid #edeff3',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px #41afd7 solid',
    },
  },
});

function DurationComponent({ dispatch, duration }) {
  const { t } = useTranslation(['report']);
  const classes = useStyles();
  const selectDuration = (event) => {
    dispatch(setDuration(event.target.value));
  };

  return (

    <TextField
      className={classes.root}
      value={duration}
      variant="outlined"
      placeholder={t('duration')}
      onChange={selectDuration}
    />

  );
}

export default DurationComponent;
