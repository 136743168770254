import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { setLocation } from 'actions/locations';
import { setFloor } from 'actions/floors';
import { setSelectedZone } from 'actions/zones';
import { setSelectedGroup } from 'actions/groups';

import FormControl from '@material-ui/core/FormControl';

import { useTranslation } from 'react-i18next';

import { fetchDevicesList } from 'actions/devices';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  formTypeCreate, formTypeUpdate, notificationTypes,
  allValuesConstant, noValuesConstant,
  selectNoOneConstant, timeTypes, timeConstants,
} from 'constans';

import CustomButtonWithTitle from 'components/settings/ButtonTitle.component';
import CustomButton from 'components/settings/Button.component';

import {
  getConditions, openMainModal, closeMainModal,
  closeDeleteConditionModal, openDeleteConditionModal,
  createCondition, setConditionType, setConditionTime,
  setConditionTimeType, deleteCondition, setEditingConditionID,
  sortByTitle,
  sortByLocations,
  sortBySublocations,
} from 'actions/notifications';
import CustomSelector from 'components/monitoring/selector';

import { createAlert, alertTypes } from 'actions/alert';

import LocationSelector from 'components/shared/LocationSelector/LocationSelector';
import FloorSelector from 'components/shared/FloorSelector/FloorSelector';
import arrowStyles from 'components/shared/settings/arrow';
import containerStyles from '../../shared/Container/styles';
import useStyles from './notifications.component.style';

const NotificationsComponent = (props) => {
  const {
    dispatch,
    conditionsArray,
    openModal,
    formType,
    groupsObject,
    devicesList,
    floorsObject,
    locationsObject,
    currentLocation,
    zonesObject,
    currentFloor,
    currentApp,
    openDeleteModal,
    includedObject,
    isCreating,
    isConditionsLoading,
    selectedZone,
    selectedGroup,
    conditionType,
    conditionTime,
    conditionTimeType,
    openMenu,
  } = props;

  const { t } = useTranslation(['settings', 'monitoring', 'notifications', 'report']);

  const groupsArray = Object.keys(groupsObject);

  const zonesArray = Object.keys(zonesObject)
    // eslint-disable-next-line radix
    .filter((zoneId) => parseInt(zonesObject[zoneId].floor) === parseInt(currentFloor.id));

  const objectDevices = {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [conditionFilter, setConditionFilter] = useState('');
  const [conditionToDelete, setConditionToDelete] = useState({});
  const [isSrotByTitle, setSortByTitile] = useState('3');
  const [isSrotByLocations, setSortByLocations] = useState('3');
  const [isSrotBySublocations, setSortBySublocations] = useState('3');
  // const [isSrotByZone, setSortByZone] = useState('3');
  // const [isSrotByGroup, setSortByGroup] = useState('3');
  const arrowsClasses = arrowStyles();
  const classesContainer = containerStyles();
  const classes = useStyles();

  useEffect(() => {
    devicesList.map((device) => {
      objectDevices[device.id] = device;
      return device;
    });
  }, [devicesList, objectDevices]);

  useEffect(() => {
    const getNotifications = async () => {
      // something wrong with pagination in
      await dispatch(getConditions(conditionFilter, page * rowsPerPage, rowsPerPage));
    };

    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionFilter, page, rowsPerPage, currentApp]);

  useEffect(() => {
    const getDevices = async () => {
      await dispatch(fetchDevicesList());
    };

    getDevices();
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateNewCondition = () => {
    dispatch(openMainModal(formTypeCreate));
  };

  const chechFormValidation = () => isCreating || !conditionType
    || !currentLocation || !currentFloor
    || !selectedGroup || !selectedZone;

  const checkExistField = (object) => !!object && !!object.data;

  const handleEditCondition = (condition) => {
    try {
      const { relationships, attributes, id } = condition;
      dispatch(setConditionType(attributes.condition));

      const time = parseInt(attributes.time, 10);
      let conditionTypeSeconds = timeTypes.seconds;
      let timeToSet = time;
      Object.keys(timeTypes).map((constantTypeId) => {
        const timeValue = timeConstants[timeTypes[constantTypeId]];
        const timeTypeChecker = Math.trunc(time / timeValue);
        if (timeTypeChecker >= 1) {
          conditionTypeSeconds = timeTypes[constantTypeId];
          timeToSet = time / timeValue;
        }
        return constantTypeId;
      });
      dispatch(setConditionTime(timeToSet));
      dispatch(setConditionTimeType(conditionTypeSeconds));
      if (checkExistField(relationships.location)) {
        dispatch(setLocation(locationsObject[relationships.location.data.id]));
      }
      if (checkExistField(relationships.sublocation)) {
        dispatch(setFloor(floorsObject[relationships.sublocation.data.id]));
      }
      if (checkExistField(relationships.zone)) {
        dispatch(setSelectedZone(zonesObject[relationships.zone.data.id]));
      }
      if (checkExistField(relationships['tracked-group'])) {
        dispatch(setSelectedGroup(groupsObject[relationships['tracked-group'].data.id]));
      }

      dispatch(setEditingConditionID(id));
      dispatch(openMainModal(formTypeUpdate));
    } catch (error) {
      dispatch(createAlert(alertTypes.warn, 'Error in reading condition properties'));
    }
  };

  const handleClose = () => {
    dispatch(closeMainModal());
  };

  const handleChangeLocation = (event) => {
    const locationId = event.target.value;
    dispatch(setLocation(locationsObject[locationId]));
    const floorId = locationsObject[locationId].floors[0].id;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleChangeZone = (event) => {
    event.preventDefault();
    const zoneId = event.target.value;
    if (zoneId === allValuesConstant) {
      dispatch(setSelectedZone({ id: allValuesConstant }));

      return;
    }

    if (zoneId === selectNoOneConstant) {
      dispatch(setSelectedZone({ id: selectNoOneConstant }));
    }
    if (zoneId !== selectNoOneConstant && zoneId !== allValuesConstant) {
      dispatch(setSelectedZone(zonesObject[zoneId]));
    }
  };

  const handleChangeGroup = (event) => {
    event.preventDefault();
    const groupId = event.target.value;
    if (groupId === noValuesConstant) {
      dispatch(setSelectedGroup({ id: noValuesConstant }));
    }
    const newSelectedGroup = groupsObject[groupId];
    dispatch(setSelectedGroup(newSelectedGroup));
  };

  const handleChangeFloor = (event) => {
    const floorId = event.target.value;
    dispatch(setFloor(floorsObject[floorId]));
    dispatch(setSelectedZone({ id: selectNoOneConstant }));
    dispatch(setSelectedGroup({ id: allValuesConstant }));
  };

  const handleCreateCondition = async () => {
    await dispatch(createCondition(t));
    setConditionFilter('');
    setPage(0);
    await dispatch(getConditions(conditionFilter, 0, rowsPerPage));
    await dispatch(fetchDevicesList());
  };

  const handleCloseDelete = () => {
    dispatch(closeDeleteConditionModal());
  };

  const handleDelete = async (object) => {
    setConditionToDelete(object);
    dispatch(openDeleteConditionModal());
  };

  const handleDeteleConfirm = async () => {
    setConditionFilter('');
    setPage(0);
    await dispatch(deleteCondition(conditionToDelete, t));
    await dispatch(getConditions('', 0, rowsPerPage));
    handleCloseDelete();
  };

  const handleChangeConditionType = (event) => {
    dispatch(setConditionType(event.target.value));
  };

  const handleChangeConditionTime = (event) => {
    dispatch(setConditionTime(event.target.value));
  };

  const handleChangeConditionTimeType = (event) => {
    dispatch(setConditionTimeType(event.target.value));
  };

  const formatConditionTitle = (condition) => {
    let result = '';

    switch (condition) {
      case notificationTypes.entry:
        result = t('notifications:ENTRY_FULL');
        break;
      case notificationTypes.exit:
        result = t('notifications:EXIT_FULL');

        break;
      case notificationTypes.standing:
        result = t('notifications:STANDING');

        break;
      case notificationTypes.standingOut:
        result = t('notifications:STANDING_OUT');

        break;
      default:
        result = condition;
        break;
    }
    return result;
  };
  const sortTitle = () => {
    if (conditionsArray.length !== 0) {
      dispatch(sortByTitle(conditionFilter, page * rowsPerPage, rowsPerPage));
      setSortByLocations('3');
      setSortBySublocations('3');
    }
    if (isSrotByTitle === '3') {
      setSortByTitile('2');
    } else if (isSrotByTitle === '2') {
      setSortByTitile('1');
    } else if (isSrotByTitle === '1') {
      setSortByTitile('2');
    }
  };
  const sortLocations = () => {
    if (conditionsArray.length !== 0) {
      dispatch(sortByLocations(conditionFilter, page * rowsPerPage, rowsPerPage));
      setSortBySublocations('3');
      setSortByTitile('3');
    }
    if (isSrotByLocations === '3') {
      setSortByLocations('2');
    } else if (isSrotByLocations === '2') {
      setSortByLocations('1');
    } else if (isSrotByLocations === '1') {
      setSortByLocations('2');
    }
  };
  const sortSublocations = () => {
    if (conditionsArray.length !== 0) {
      dispatch(sortBySublocations(conditionFilter, page * rowsPerPage, rowsPerPage));
      setSortByLocations('3');
      setSortByTitile('3');
    }
    if (isSrotBySublocations === '3') {
      setSortBySublocations('2');
    } else if (isSrotBySublocations === '2') {
      setSortBySublocations('1');
    } else if (isSrotBySublocations === '1') {
      setSortBySublocations('2');
    }
  };
  return (
    <div className={!openMenu ? classes.content : classesContainer.noneContent}>
      <div className={classes.topControls}>
        <CustomButtonWithTitle
          variant="contained"
          color="primary"
          onClick={handleCreateNewCondition}
        >
          {t('createConditionButton')}
        </CustomButtonWithTitle>
      </div>
      {isConditionsLoading
        ? (
          <div className={classes.spinerWrapper}>
            <CircularProgress size={26} />
          </div>
        )
        : (
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={sortTitle} className={arrowsClasses.titleRow}>
                      {' '}
                      {t('condition')}
                      {' '}
                      {
                        (isSrotByTitle === '1')
                        && <ArrowDropUpIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotByTitle === '2')
                        && <ArrowDropDownIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotByTitle === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell onClick={sortLocations} className={arrowsClasses.titleRow}>
                      {' '}
                      {t('location')}
                      {' '}
                      {
                        (isSrotByLocations === '1')
                        && <ArrowDropUpIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotByLocations === '2')
                        && <ArrowDropDownIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotByLocations === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell onClick={sortSublocations} className={arrowsClasses.titleRow}>
                      {' '}
                      {t('floor')}
                      {' '}
                      {
                        (isSrotBySublocations === '1')
                        && <ArrowDropUpIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotBySublocations === '2')
                        && <ArrowDropDownIcon className={arrowsClasses.arrow} />
                      }
                      {
                        (isSrotBySublocations === '3')
                        && null
                      }
                    </TableCell>
                    <TableCell>
                      {' '}
                      {t('zone')}
                      {' '}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {t('group')}
                      {' '}
                    </TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conditionsArray.map((condition) => (
                    <TableRow key={condition.id}>
                      <TableCell component="th" scope="row">
                        {formatConditionTitle(condition.attributes.condition)}
                      </TableCell>
                      <TableCell>
                        {condition.relationships.location
                          ? includedObject.locations[condition.relationships.location.data.id].attributes.title : '-'}
                      </TableCell>
                      <TableCell>
                        {condition.relationships.sublocation
                          ? includedObject.floors[condition.relationships.sublocation.data.id].attributes.title : '-'}
                      </TableCell>
                      <TableCell>
                        {condition.relationships.zone
                          ? includedObject.zones[condition.relationships.zone.data.id].attributes.name : '-'}
                      </TableCell>
                      <TableCell>
                        {condition.relationships['tracked-group']
                          ? includedObject.groups[condition.relationships['tracked-group'].data.id].attributes.title : '-'}
                      </TableCell>
                      <TableCell>
                        <CustomButton onClick={() => { handleEditCondition(condition); }}>
                          {' '}
                          {t('editBtn')}
                          {' '}
                        </CustomButton>
                        <CustomButton onClick={() => { handleDelete(condition); }}>
                          {' '}
                          {t('deleteBtn')}
                          {' '}
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={-1}
              labelDisplayedRows={() => page + 1}
              rowsPerPage={rowsPerPage}
              page={page}
              nextIconButtonProps={{
                disabled: conditionsArray.length < rowsPerPage,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t('report:labelRowsPerPage')}
            />
            <div>
              <Dialog
                open={openDeleteModal}
                onClose={handleCloseDelete}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  {t('deleteConditionTitle')}
                </DialogTitle>
                <DialogContent dividers>
                  <p>
                    {' '}
                    {t('deleteConditionText')}
                  </p>
                </DialogContent>
                <DialogActions>
                  <CustomButton disabled={isCreating} onClick={handleCloseDelete} color="primary">
                    {t('cancelBtn')}
                  </CustomButton>
                  <CustomButton disabled={isCreating} onClick={handleDeteleConfirm} color="primary">
                    {isCreating ? <CircularProgress size={26} /> : null}
                    {t('deleteBtn')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
              <Dialog
                open={openModal}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <form onSubmit={(e) => e.preventDefault()}>
                  <DialogTitle id="scroll-dialog-title">
                    {formType === formTypeCreate
                      ? t('createConditionTitle')
                      : t('editConditionTitle')}
                  </DialogTitle>
                  <DialogContent>
                    <div>
                      <div className={classes.controlWrapper}>
                        <LocationSelector
                          locations={locationsObject}
                          value={currentLocation.id}
                          onChange={handleChangeLocation}
                        />
                      </div>
                      <div className={classes.controlWrapper}>
                        <FloorSelector
                          floors={floorsObject}
                          className={classes.selector}
                          onChange={handleChangeFloor}
                          value={currentFloor.id}
                          currentLocationId={currentLocation.id}
                        />
                      </div>
                      <div className={classes.controlWrapper}>
                        <FormControl>
                          <CustomSelector
                            id="select-zone"
                            label={t('monitoring:zonesSelectorLabel')}
                            className="select-location"
                            select
                            value={selectedZone.id}
                            onChange={handleChangeZone}
                          >
                            {zonesArray.map((zoneId) => (
                              <MenuItem key={zoneId} value={zoneId}>
                                {zonesObject[zoneId].title}
                              </MenuItem>
                            ))}
                          </CustomSelector>
                        </FormControl>
                      </div>
                      <div className={classes.controlWrapper}>
                        <FormControl>
                          <CustomSelector
                            id="select-group"
                            className="select-location"
                            label={t('monitoring:groupsSelectorLabel')}
                            select
                            value={selectedGroup.id || allValuesConstant}
                            onChange={handleChangeGroup}
                          >
                            <MenuItem key={allValuesConstant} value={allValuesConstant}>
                              {t('monitoring:all')}
                            </MenuItem>
                            {groupsArray.map((groupId) => (
                              <MenuItem key={groupId} value={groupId}>
                                {groupsObject[groupId].title}
                              </MenuItem>
                            ))}
                          </CustomSelector>
                        </FormControl>
                      </div>
                      <div className={classes.controlWrapper}>
                        <FormControl>
                          <CustomSelector
                            id="select-condition"
                            className="select-location"
                            label={t('selectCondition')}
                            select
                            value={conditionType || ''}
                            onChange={handleChangeConditionType}
                          >
                            <MenuItem key={notificationTypes.entry} value={notificationTypes.entry}>
                              {t('notifications:ENTRY_FULL')}
                            </MenuItem>
                            <MenuItem key={notificationTypes.exit} value={notificationTypes.exit}>
                              {t('notifications:EXIT_FULL')}
                            </MenuItem>
                            <MenuItem
                              key={notificationTypes.standing}
                              value={notificationTypes.standing}
                            >
                              {t('notifications:STANDING')}
                            </MenuItem>
                            <MenuItem
                              key={notificationTypes.standingOut}
                              value={notificationTypes.standingOut}
                            >
                              {t('notifications:STANDING_OUT')}
                            </MenuItem>
                            <MenuItem key={notificationTypes.sos} value={notificationTypes.sos}>
                              SOS
                            </MenuItem>
                            <MenuItem key={notificationTypes.fall} value={notificationTypes.fall}>
                              FALL
                            </MenuItem>
                            {/* <MenuItem
                              key={notificationTypes.custom}
                              value={notificationTypes.custom}
                            >
                              {notificationTypes.custom}
                            </MenuItem> */}
                          </CustomSelector>
                        </FormControl>
                      </div>
                      {(conditionType === notificationTypes.standing
                      || conditionType === notificationTypes.standingOut)
                      && (
                        <div className={classes.controlWrapper}>
                          <FormControl>
                            <CustomSelector
                              id="object-title"
                              label={t('selectConditionTime')}
                              type="text"
                              value={conditionTime}
                              onChange={handleChangeConditionTime}
                              InputLabelProps={{ shrink: true }}
                            />
                            <CustomSelector
                              className="select-сondition-time"
                              id="select-time-сondition"
                              select
                              value={conditionTimeType}
                              onChange={handleChangeConditionTimeType}
                            >
                              <MenuItem
                                key={timeTypes.seconds}
                                value={timeTypes.seconds}
                              >
                                {t('seconds')}
                              </MenuItem>
                              <MenuItem
                                key={timeTypes.minutes}
                                value={timeTypes.minutes}
                              >
                                {t('minutes')}
                              </MenuItem>
                              <MenuItem
                                key={timeTypes.hours}
                                value={timeTypes.hours}
                              >
                                {t('hours')}
                              </MenuItem>
                              <MenuItem
                                key={timeTypes.days}
                                value={timeTypes.days}
                              >
                                {t('days')}
                              </MenuItem>
                              <MenuItem
                                key={timeTypes.weeks}
                                value={timeTypes.weeks}
                              >
                                {t('weeks')}
                              </MenuItem>
                            </CustomSelector>
                          </FormControl>
                        </div>
                      )}
                      {(conditionType === notificationTypes.custom) && (
                        <FormControl>
                          <TextField
                            id="select-condition-txt"
                            className={classes.txtControl}
                            label={t('selectCondition')}
                            value={conditionType || ''}
                            onChange={handleChangeConditionType}
                          />
                        </FormControl>
                      )}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <CustomButton disabled={isCreating} onClick={handleClose} color="secondary">
                      {t('cancelBtn')}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      disabled={chechFormValidation()}
                      onClick={handleCreateCondition}
                      color="primary"
                    >
                      {isCreating ? <CircularProgress size={26} /> : null}
                      {formType === formTypeCreate ? t('createConditionButton') : t('saveBtn')}
                    </CustomButton>
                  </DialogActions>
                </form>
              </Dialog>
            </div>
          </div>
        )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    openModal,
    formType,
    objectToDelete,
    openDeleteModal,
    conditionsArray,
    includedObject,
    conditionType,
    conditionTime,
    isConditionsLoading,
    conditionTimeType,
    isConditionCreating: isCreating,
  } = state.notificationsSettings;

  const { groupsObject, selectedGroup } = state.groups;
  const { zonesObject, selectedZone } = state.zones;
  const { devicesList } = state.devices;
  const {
    floorsObject,
    currentFloor,
  } = state.floor;
  const { currentApp, openMenu } = state.app;

  const {
    locationsObject,
    currentLocation,
  } = state.location;

  return {
    locationsObject,
    currentLocation,
    zonesObject,
    selectedZone,
    conditionsArray,
    includedObject,
    openModal,
    formType,
    conditionType,
    isConditionsLoading,
    groupsObject,
    selectedGroup,
    isCreating,
    conditionTime,
    conditionTimeType,
    devicesList,
    floorsObject,
    currentFloor,
    currentApp,
    objectToDelete,
    openDeleteModal,
    openMenu,
  };
}

export default connect(mapStateToProps)(NotificationsComponent);
